import { Component } from "react";

import {
  CoreIcon,
  CoreH6,
  FORM_IDS,
  CoreContainedButton,
  CoreIconButton,
  CoreBox,
  CoreGrid,
  CoreClasses,
  onEditForm,
  cancelFormEdit,
  CoreOutlinedButton
} from "@wrappid/core";
import { connect } from "react-redux";

import MedicineSelector from "./MedicineSelector";

class PrescriptionMedicine extends Component {
  state = { mode: false };

  render() {
    // eslint-disable-next-line no-console
    console.log(
      "MEDICINE PRESCRIPTION MEDICINE",
      this.props.advMedicines,
      this.props.formRef
    );
    return (
      <CoreGrid>
        <CoreH6 gridProps={{ gridSize: 11 }}>Treatment</CoreH6>

        {this.props.allowEdit && !this.state.mode && !this.props.editMode && (
          <CoreBox
            gridProps={{ gridSize: 1 }}
            styleClasses={[CoreClasses.LAYOUT.RIGHT_ALIGN]}
          >
            <CoreIconButton onClick={()=>{this.props.OnEditForm(this.props.formId, this.props.formId);}}>
              <CoreIcon>edit_note</CoreIcon>
            </CoreIconButton>
          </CoreBox>
        )}

        <MedicineSelector
          OnAddMed={this.props.AddMedicine}
          advMedicines={this.props.advMedicines}
          notEditable={!this.props.allowEdit || !this.props.editMode}
          onEditMedicineNote={this.props.onEditMedicineNote}
          onChangeMedGeneric={this.props.onChangeMedGeneric}
          theme={this.props.theme}
          OnMedClear={this.props.OnMedClear}
        />

        {this.props.allowEdit && this.props.editMode && (
          <CoreBox
            styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}
          >
            <CoreOutlinedButton
              label="Cancel"
              disabled={this.props.submitLoading}
              onClick={() => {
                this.props.OnCancelForm(this.props.formId);
              }}
            />

            <CoreContainedButton
              label="Submit"
              disabled={this.props.submitLoading}
              onClick={this.props.SubmitMeds}
            />
          </CoreBox>
        )}
      </CoreGrid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    basic                : state.profile.basic,
    editMode             : state.forms?.editForm[FORM_IDS.__PRESCRIPTION_MEDICINE]?.editing,
    mainMasterData       : state.mdm.mainMasterData,
    prescription         : state.prescription.prescription,
    prescriptionTemplates: state.prescription.prescriptionTemplates,
    state                : state,
    submitLoading        : state?.forms?.formSubmitLoading?.prescriptionMedicine,
    templateDesigns      : state.prescription.templateDesigns,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    OnCancelForm: (formId) => {
      dispatch(cancelFormEdit(formId));
    },
    OnEditForm: (formId, formArrayId) => {
      dispatch(onEditForm(formId, formArrayId));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrescriptionMedicine);
