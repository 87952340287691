import {
  CoreAvatar,
  CoreBox,
  CoreButton,
  CoreClasses,
  CoreIcon,
  CoreStack,
  CoreTypographyBody1,
  CoreTypographyCaption,
  HTTP,
  __IconTypes,
  apiRequestAction
} from "@wrappid/core";
import { useDispatch } from "react-redux";

import {
  ACCEPT_INVITATION_ERROR,
  ACCEPT_INVITATION_SUCCESS
} from "../types/manageAssistantTypes";

const PendingAssistantCard = ({ data }) => {
  const {
    "Person.photoUrl": personPhotoUrl,
    "Person.firstName": personFirstName,
    "Person.middleName": personMiddleName,
    "Person.lastName": personLastName,
    "RelatedPerson.photoUrl": relatedPersonPhotoUrl,
  } = data || {};
  const dispatch = useDispatch();
  const handleAccept = (data) => {
    dispatch(
      apiRequestAction(
        HTTP.PUT,
        "/invitation/doctor/accept/" + data.id,
        true,
        {},
        ACCEPT_INVITATION_SUCCESS,
        ACCEPT_INVITATION_ERROR
      )
    );
  };

  // const handleDecline = (data) => {

  // };

  return (
    <CoreStack styleClasses={[CoreClasses.PADDING.P0]} direction="column">
      <CoreStack direction="row" spacing={1}>
        <CoreTypographyBody1
          styleClasses={[CoreClasses.TEXT.TEXT_BOLD, CoreClasses.TEXT.PRIMARY]}
        >
          {`${personFirstName} ${personMiddleName} ${personLastName} `}
        </CoreTypographyBody1>

        <CoreTypographyBody1 styleClasses={[CoreClasses.TEXT.TEXT_BOLD]}>
                    invited to join his team
        </CoreTypographyBody1>
      </CoreStack>

      <CoreTypographyCaption>
                Please Confirm your attendance by clicking the accept below
      </CoreTypographyCaption>

      <CoreStack
        direction="row"
        spacing={2}
        styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}
      >
        <CoreBox>
          <CoreAvatar
            styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_LARGE]}
            src={personPhotoUrl || "photo.jpg"}
          />
        </CoreBox>

        <CoreBox styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER]}>
          <CoreIcon type={__IconTypes.MATERIAL_OUTLINED_ICON} style={{ fontSize: 12 }}>{"add"}</CoreIcon>
        </CoreBox>

        <CoreBox>
          <CoreAvatar
            styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_LARGE]}
            src={relatedPersonPhotoUrl || "photo.jpg"}
          />
        </CoreBox>
      </CoreStack>

      {/* buttons */}
      <CoreStack direction="row" spacing={2} styleClasses={[CoreClasses.PADDING.P1, CoreClasses.PADDING.PL0]}>
        <CoreBox>
          <CoreButton
            variant="contained"
            color="primary"
            label="Accept"
            onClick={() => handleAccept(data)}
          />

          <CoreButton
            variant="contained"
            color="secondary"
            label="Decline"
            // onClick={() => handleDecline(data)}
          />
        </CoreBox>
      </CoreStack>

      <CoreBox>
        <CoreTypographyBody1 styleClasses={[CoreClasses.TEXT.TEXT_BOLD]}>
                    lorem ipsum dolor sit amet, consectetur adip incididunt
        </CoreTypographyBody1>

        <CoreTypographyCaption>
                    lorem Ips tempor ullamcorper et justo consequ
                    logorem ipsum dolor sit amet, consectetur adip
        </CoreTypographyCaption>

        <CoreTypographyCaption>
                    lorem Ips tempor ullamcorper et justo consequ
                    logorem ipsum dolor sit amet, consectetur adip
        </CoreTypographyCaption>

        <CoreTypographyCaption>
                    lorem Ips tempor ullamcorper et justo consequ
                    logorem ipsum dolor sit amet, consectetur adip
        </CoreTypographyCaption>

      </CoreBox>
    </CoreStack>
  );
};

export default PendingAssistantCard;
