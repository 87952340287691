import { CoreAsyncSelect, CoreBox } from "@wrappid/core";

/**
 *
 * @todo
 * This Component must pass role name as props
 * based on role name this component has an CoreAsyncSelect component
 * quer changes
 *
 * CoreAsyncSelect
 *      "label": "Search Assistant",
 *      "query": {
 *          "role": "assistant",
 *          "input": "a"
 *      },
 *      "itemKey": "existingUsers",
 *      "endpoint": "/business/all/RxefyUsers",
 *      "gridSize": 12,
 *      "creatable": true,
 *      "optionComp": "searchAssistantCard",
 *      "navigateUrl": "/create_assistant",
 *      "getOptionLabel": "__SEARCHPATIENT_GET_OPTION_LABEL",
 *      "getOptionValue": "__SEARCHPATIENT_GET_OPTION_VALUE",
 *      "onChangeDispatch": "__SEARCHPATIENT_ON_CHANGE_DISPATCH",
 *      "isOptionsEqualToValue": "__SEARCHPATIENT_IS_OPTIONS_EQUAL_TO_VALUE"
 *
 *
 * @param {*} props
 * @returns
 */
export default function SearchUser(props) {
  /*
    {
      "label": props.label || "Search User",
      "query": props.query || {}
        "role": props.label || "patient",
        "input": "a"
      },
      "itemKey": "existingUsers",
      "endpoint": "/userSearchPaginated",
      "gridSize": 12,
      "creatable": true,
      "optionComp": "searchUserCard",
      "navigateUrl": "/create_patient",
      "getOptionLabel": "__SEARCHPATIENT_GET_OPTION_LABEL",
      "getOptionValue": "__SEARCHPATIENT_GET_OPTION_VALUE",
      "optionCompProps": {
        "role": "patient"
      },
      "onChangeDispatch": "__SEARCHPATIENT_ON_CHANGE_DISPATCH",
      "isOptionsEqualToValue": "__SEARCHPATIENT_IS_OPTIONS_EQUAL_TO_VALUE"
    }
    */

  return (
    <CoreBox>
      <CoreAsyncSelect
        label={props?.label || "Search User"}
        query={props?.query || {}}
        itemKey={props?.itemKey}
        endpoint="/userSearchPaginated"
        gridSize={props?.gridSize || 12}
        creatable={true}
        optionComp="searchUserCard"
        optionCompProps={{ role: props?.role || "patient" }}
        navigateUrl={props?.navigateUrl || "/create_patient"}
        getOptionLabel={
          props?.getOptionLabel 
        }
        getOptionValue={
          props?.getOptionValue 
        }
        onChangeDispatch={
          props?.onChangeDispatch 
        }
        isOptionEqualToValue={
          props?.isOptionsEqualToValue 
        }
      />
    </CoreBox>
  );
}
