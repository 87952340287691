/* eslint-disable id-length */
/* eslint-disable no-console */

import { FORM_IDS } from "../formIds.registry";

const formToFieldMap = {
  prescriptionComplaints: { field: "Complaints" },
  prescriptionDiagnosis : { field: "Diagnoses" },
  prescriptionFollowups : { field: "Followups" },
  prescriptionGuidelines: { field: "Guidelines" },
  prescriptionHistory   : { field: "Histories" },
  prescriptionProcedures: { field: "Procedures" },
  prescriptionRefferedTo: { field: "Reffers" },
};

export const SanPrescription = (formData, apiMeta, state, others) => {
  console.log("SANITING", state.prescription);
  let prescriptionInStore = state.prescription?.prescription;
  let data = {};
  const formId = others?.formId;

  if (prescriptionInStore?.id) {
    data["id"] = prescriptionInStore.id;
  }

  data["doctorId"] = state?.profile?.basic?.id;
  data["patientId"] = state?.prescription?.navData?.Person?.id;
  data["clinicId"] = state?.prescription?.clinic?.Clinic?.id;
  data["templateId"] = state?.prescription?.template?.id;

  data["appointmentId"] = state.prescription?.navData?.appointment?.id;
  data["languageId"] = state.prescription?.language?.id;

  if (formToFieldMap[formId]) {
    data = {
      ...data,
      [formToFieldMap[formId]?.field]:
      prescriptionInStore[formToFieldMap[formId]?.field] &&
      prescriptionInStore[formToFieldMap[formId]?.field][0] &&
      prescriptionInStore[formToFieldMap[formId]?.field][0].id
        ? {
          id: prescriptionInStore[formToFieldMap[formId]?.field][0].id,
          ...formData,
        }
        : formData,
    };
  } else if (formId === FORM_IDS.__PRESCRIPTION_VITALS) {
    let vitalIds = Object.keys(formData);
    let finalData = [];

    for (let i = 0; i < vitalIds.length; i++) {
      if (formData[vitalIds[i]])
        finalData.push({
          masterDataId: vitalIds[i],
          value       : formData[vitalIds[i]],
        });
    }
    data = {
      ...data,
      PrescriptionVitals: finalData,
    };
  } else if (formId === FORM_IDS.__PRESCRIPTION_MEDICINE) {
    let finalData = [];

    for (let i = 0; i < formData.length; i++) {
      let med = formData[i];
      let ob = {};

      if (
        prescriptionInStore?.AdvicedMedicines &&
      prescriptionInStore.AdvicedMedicines[i]
      ) {
        ob["id"] = prescriptionInStore.AdvicedMedicines[i].id;
      }
      ob["medicineId"] = med?.name?.value;
      ob["formulation"] = med?.formulation?.value;
      ob["quantity"] = med?.quantity?.value;
      ob["frequency"] = med?.frequency?.value;
      ob["meal"] = med?.meal?.value;
      ob["durationCount"] = med?.durationCount?.value;
      ob["durationType"] = med?.durationType?.value;
      ob["notes"] = med?.notes;
      finalData.push(ob);
    }
    data = {
      ...data,
      AdvicedMedicines: finalData,
    };
  } else if (formId === FORM_IDS.__PRESCRIPTION_GENERATE) {
    data = {
      ...data,
      isCompleted: true,
    };
  } else {
    console.error("NO SANTIZATION WRITTER");
  }

  return {
    endpoint: apiMeta.endpoint,
    values  : data,
  };
};