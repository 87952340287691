import CircularPicture from "./components/CircularPicture";
import CreatePatientRelative from "./components/CreatePatientRelative";
import DoctorPatientTable from "./components/DoctorPatientTable";
import InputAddress from "./components/InputAddress";
import ManagePatient from "./components/ManagePatient";
import MedicalBackground from "./components/MedicalBackground";
import Medication from "./components/Medication";
import PastAppointmentRow from "./components/PastAppointmentRow";
import PastAppointments from "./components/PastAppointments";
import PatientBasicInfo from "./components/PatientBasicInfo";
import PatientContactInfo from "./components/PatientContactInfo";
import PatientDetailsCard from "./components/PatientDetailsCard";
import PatientHistory from "./components/PatientHistory";
import PatientSelection from "./components/PatientSelection";
import PatientSummaryComponent from "./components/PatientSummaryComponent";
import PatientUpcomingAppointment from "./components/PatientUpcomingAppointment";
import PrivateNotes from "./components/PrivateNotes";
import UpdatePatientForm from "./components/UpdatePatientForm";
import Vitals from "./components/Vitals";

export const ComponentsRegistry = {
  CircularPicture           : { comp: CircularPicture },
  CreatePatientRelative     : { comp: CreatePatientRelative },
  DoctorPatientTable        : { comp: DoctorPatientTable },
  InputAddress              : { comp: InputAddress },
  ManagePatient             : { comp: ManagePatient },
  MedicalBackground         : { comp: MedicalBackground },
  Medication                : { comp: Medication },
  PastAppointmentRow        : { comp: PastAppointmentRow },
  PastAppointments          : { comp: PastAppointments },
  PatientBasicInfo          : { comp: PatientBasicInfo },
  PatientContactInfo        : { comp: PatientContactInfo },
  PatientDetailsCard        : { comp: PatientDetailsCard },
  PatientHistory            : { comp: PatientHistory },
  PatientSelection          : { comp: PatientSelection },
  PatientSummaryComponent   : { comp: PatientSummaryComponent },
  PatientUpcomingAppointment: { comp: PatientUpcomingAppointment },
  PrivateNotes              : { comp: PrivateNotes },
  UpdatePatientForm         : { comp: UpdatePatientForm },
  Vitals                    : { comp: Vitals },
};
