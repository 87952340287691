import DefaultappBuilderStyles from "../../modules/app-builder/styles/DefaultModuleStyles";
import DefaultappointmentStyles from "../../modules/appointment/styles/DefaultModuleStyles";
import DefaultassistantStyles from "../../modules/assistant/styles/DefaultModuleStyles";
import DefaultauthStyles from "../../modules/auth/styles/DefaultModuleStyles";
import DefaultclinicStyles from "../../modules/clinic/styles/DefaultModuleStyles";
import DefaultdashboardStyles from "../../modules/dashboard/styles/DefaultModuleStyles";
import DefaultdataManagementStyles from "../../modules/data-management/styles/DefaultModuleStyles";
import DefaultguideStyles from "../../modules/guide/styles/DefaultModuleStyles";
import DefaultpatientStyles from "../../modules/patient/styles/DefaultModuleStyles";
import DefaultpaymentStyles from "../../modules/payment/styles/DefaultModuleStyles";
import DefaultprescriptionStyles from "../../modules/prescription/styles/DefaultModuleStyles";
import DefaultprofileStyles from "../../modules/profile/styles/DefaultModuleStyles";
import DefaultsettingsStyles from "../../modules/settings/styles/DefaultModuleStyles";
import DefaultuserManagementStyles from "../../modules/user-management/styles/DefaultModuleStyles";

const DefaultModulesStyles = {
...new DefaultappBuilderStyles().style,
...new DefaultappointmentStyles().style,
...new DefaultassistantStyles().style,
...new DefaultauthStyles().style,
...new DefaultclinicStyles().style,
...new DefaultdashboardStyles().style,
...new DefaultdataManagementStyles().style,
...new DefaultguideStyles().style,
...new DefaultpatientStyles().style,
...new DefaultpaymentStyles().style,
...new DefaultprescriptionStyles().style,
...new DefaultprofileStyles().style,
...new DefaultsettingsStyles().style,
...new DefaultuserManagementStyles().style,
};
 export default DefaultModulesStyles