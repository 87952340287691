import { BlankLayout, CoreClasses, CoreLayoutItem, CoreTypographyBody1 } from "@wrappid/core";

export default function Help() {
  return (
    <>
      <CoreLayoutItem id={BlankLayout.PLACEHOLDER.CONTENT} styleClasses={[CoreClasses.PADDING.P5]}>
        <CoreTypographyBody1>
                  comming soon
        </CoreTypographyBody1>

      </CoreLayoutItem>
    </>
  );
}
