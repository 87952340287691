import { useEffect, useContext } from "react";

import {
  CoreDataTable,
  CoreDialogContext,
  CoreClasses,
  CoreStack,
  CoreCardHeader,
  CoreAvatar,
  CoreTypographyCaption,
  CoreBox,
  CoreDivider,
  getAge,
  __IconTypes,
  CoreIconText
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import DoctorCard from "./DoctorCard";
import PendingAssistantCard from "./PendingAssistantCard";
import { setmanageAssistantReducer } from "../actions/manageAssistantAction";
//-- import {
//   ACCEPT_INVITATION_ERROR,
//   ACCEPT_INVITATION_SUCCESS
// } from "../types/manageAssistantTypes";
// import AssistantCard from "./AssistantCard";

export default function PendingAssistantInvitation() {
  const dispatch = useDispatch();
  const { setDialog } = useContext(CoreDialogContext);

  const personID = useSelector((state) => state?.profile?.basic?.id);
  const manageAssistant = useSelector((state) => state.manageAssistant);

  //-- const tableRowActions = [
  //   {
  //     action: (data) => {
  //       dispatch(
  //         apiRequestAction(
  //           HTTP.PUT,
  //           "/invitation/doctor/accept/" + data.id,
  //           true,
  //           {},
  //           ACCEPT_INVITATION_SUCCESS,
  //           ACCEPT_INVITATION_ERROR
  //         )
  //       );
  //     },
  //     hide: (data) => {
  //       if (data._status === __EntityStatus.ACTIVE) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     },
  //     icon: "groupAdd",
  //     label: "Accept Invitation",
  //     type: "action",
  //   },
  // ];

  useEffect(() => {
    if (manageAssistant.acceptInvitationSuccess) {
      setDialog({
        cancelButton: () => {
          dispatch(setmanageAssistantReducer({ assistantStatusSuccess: false }));
          setDialog(null);
        },
        doneButton: () => {
          dispatch(setmanageAssistantReducer({ assistantStatusSuccess: false }));
          setDialog(null);
        },
        //-- showDialog: true,
        subtitle: "Invitation accepted",
        title   : "Success",
      });
    }

    if (manageAssistant.acceptInvitationError) {
      setDialog({
        cancelButton: () => {
          dispatch(setmanageAssistantReducer({ acceptInvitationError: false }));
          setDialog(null);
        },
        doneButton: () => {
          dispatch(setmanageAssistantReducer({ acceptInvitationError: false }));
          setDialog(null);
        },
        //-- showDialog: true,
        subtitle: "Error to accept invitation",
        title   : "error",
      });
    }
  }, [manageAssistant]);

  const StatusCheck = ({ data }) => {
    const { _status } = data;

    return _status === "active" ? (
      <DoctorCard data={data} />
    ) : (
      <PendingAssistantCard data={data} />
    );
  };

  const summaryComponent = ({ rowData }) => {
    return (
      <CoreStack direction="column">
        <CoreCardHeader
          styleClasses={[CoreClasses.PADDING.P0]}
          avatar={
            <CoreAvatar
              styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_MEDIUM]}
              src={rowData["Person.photoUrl"] || "photo.jpg"}
            />
          }
          title={[rowData["Person.firstName"], rowData["Person.middleName"], rowData["Person.lastName"]].join(" ")}
          subheader={
            <>
              <CoreBox>
                <CoreTypographyCaption>{`MRN: ${rowData["RelatedPerson.profileId"] || "N/A"
                }`}</CoreTypographyCaption>
              </CoreBox>

              {/* {getLabel(rowData["RelatedPerson.gender"] || "N/A") +
                ", " + */}
              <CoreStack
                direction="row"
                spacing={1}
              >
                <CoreTypographyCaption styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
                  <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon="cake" text={getAge(rowData["Person.dob"])} />
                </CoreTypographyCaption>

                <CoreDivider orientation="vertical" variant="middle" flexItem />

                <CoreTypographyCaption styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
                  <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon={(rowData["Person.gender"])?.toLowerCase()} text={(rowData["Person.gender"])} />
                </CoreTypographyCaption>
              </CoreStack>
            </>
          }
        />

        <CoreDivider />

        <CoreStack
          direction="row"
          spacing={1}
          styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}
        >
          <CoreTypographyCaption>
            {"ID: " + rowData["id"]}
          </CoreTypographyCaption>

          <CoreDivider orientation="vertical" variant="middle" flexItem />

          <CoreTypographyCaption
            styleClasses={[
              // -- getStatusTextColorClass(rowData["_status"]),
              CoreClasses.TEXT.TEXT_WEIGHT_BOLD, CoreClasses.TEXT.TEXT_UPPERCASE,
            ]}
          >
            {/* -- {getLabel(rowData["_status"])} */}
          </CoreTypographyCaption>
        </CoreStack>
      </CoreStack>
    );
  };

  return (
    <CoreDataTable
      entity="AssistantInvitations"
      filterQuery={{ filter: { "RelatedPerson.id": personID } }}
      //-- rowActions={tableRowActions}
      preRender_UpdateData_DetailsPaneComponent={
        StatusCheck
      }
      summaryRendererComponent={summaryComponent}
    />
  );
}
