export const CREATE_PATIENT_SUCCESS = "CREATE_PATIENT_SUCCESS";
export const CREATE_PATIENT_ERROR = "CREATE_PATIENT_ERROR";
export const EDIT_PATIENT_SUCCESS = "EDIT_PATIENT_SUCCESS";
export const EDIT_PATIENT_ERROR = "EDIT_PATIENT_ERROR";
export const RESET_PATIENT = "RESET_PATIENT";
export const SHOW_RELATIVE_FORM = "SHOW_RELATIVE_FORM";
export const HIDE_RELATIVE_FORM = "HIDE_RELATIVE_FORM";
export const GET_PATIENT_UPCOMING_APPOINTMENT_SUCCESS = "GET_PATIENT_UPCOMING_APPOINTMENT_SUCCESS";
export const GET_PATIENT_UPCOMING_APPOINTMENT_ERROR = "GET_PATIENT_UPCOMING_APPOINTMENT_ERROR";
export const GET_PATIENT_PAST_APPOINTMENT_SUCCESS = "GET_PATIENT_PAST_APPOINTMENT_SUCCESS";
export const GET_PATIENT_PAST_APPOINTMENT_ERROR = "GET_PATIENT_PAST_APPOINTMENT_ERROR";
export const SET_PATIENT_FOR_RELATIVE = "SET_PATIENT_FOR_RELATIVE";
export const GET_RELATIVE_USER_INFO_SUCCESS = "GET_RELATIVE_USER_INFO_SUCCESS";
export const GET_RELATIVE_USER_INFO_ERROR = "GET_RELATIVE_USER_INFO_ERROR";
export const GET_RELATIVE_SUCCESS = "GET_RELATIVE_SUCCESS";
export const GET_RELATIVE_ERROR = "GET_RELATIVE_ERROR";