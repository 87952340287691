import { AppContainerLayout, CoreLayoutItem } from "@wrappid/core";

import DoctorPatientTable from "./DoctorPatientTable";

export default function ManagePatient() {
  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <DoctorPatientTable />
      </CoreLayoutItem>
    </>
  );
}
