import { getFormikRequiredMessage } from "@wrappid/core";
import moment from "moment";
import { object, string, date, boolean } from "yup";

export const ValidationsRegistry = object ({
  createAppointment: {
    date   : date().required(getFormikRequiredMessage("date")),
    endTime: string().required(getFormikRequiredMessage("endTime")).test("end time test", "End time must be greater than Start time", (value, props) => {
      const { startTime } = props.parent;

      return moment(startTime, "HH:mm").isBefore(moment(value, "HH:mm"));
    }),
    // eslint-disable-next-line no-undef
    isForce  : boolean(),
    startTime: string().required(getFormikRequiredMessage("startTime")),
  }
});