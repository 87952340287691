import { CoreAsyncSelect, CoreBox } from "@wrappid/core";

import { ApiRegistry } from "../apis.registry";
import { getLabel } from "../utils/stringUtils";

/**
 *
 * @todo
 * This Component must pass role name as props
 * based on role name this component has an CoreAsyncSelect component
 * quer changes
 *
 * CoreAsyncSelect
 *      "label": "Search Assistant",
 *      "query": {
 *          "role": "assistant",
 *          "input": "a"
 *      },
 *      "itemKey": "existingUsers",
 *      "endpoint": "/business/all/RxefyUsers",
 *      "gridSize": 12,
 *      "creatable": true,
 *      "optionComp": "searchAssistantCard",
 *      "navigateUrl": "/create_assistant",
 *      "getOptionLabel": "__SEARCHPATIENT_GET_OPTION_LABEL",
 *      "getOptionValue": "__SEARCHPATIENT_GET_OPTION_VALUE",
 *      "onChangeDispatch": "__SEARCHPATIENT_ON_CHANGE_DISPATCH",
 *      "isOptionsEqualToValue": "__SEARCHPATIENT_IS_OPTIONS_EQUAL_TO_VALUE"
 *
 *
 * @param {*} props
 * @returns
 */
export default function SearchUser(props) {
  return (
    <CoreBox>
      <CoreAsyncSelect
        label={"Search " + getLabel(props.role)}
        query={{ input: "a", role: props.role }}
        itemKey={props.itemKey}
        endpoint={ApiRegistry.USER_SEARCH_PAGINATED_API}
        gridSize={props.gridSize || 12}
        creatable={true}
        optionComp="searchUserCard"
        optionCompProps={{ role: props.role }}
        navigateUrl={props.navigateUrl}
        handleChange={props.handleChange}
        onChangeDispatch={props.onChangeDispatch}
      />
    </CoreBox>
  );
}
