import { CoreForm, FORM_EDIT_MODE } from "@wrappid/core";
import { useDispatch } from "react-redux";

import { HIDE_RELATIVE_FORM } from "../types/patient.types";

export default function CreatePatientRelative() {
  const dispatch = useDispatch();

  return (
    <>
      <CoreForm
        apiMode="create"
        formId="createPatientRelative"
        mode={FORM_EDIT_MODE} 
        afterCancel= {()=>{
          dispatch({ type: HIDE_RELATIVE_FORM });
        }}
      />
    </>
  );
}
