import React from "react";

import {
  coreUseNavigate, CoreDivider,
  CoreIcon,
  CoreLabel,
  CoreTypographyCaption,
  CoreTextButton,
  CoreBox,
  CoreGrid,
  CoreLink, CoreClasses, apiRequestAction, CoreDialogContext, HTTP
} from "@wrappid/core";
import { theme } from "@wrappid/styles";
import { useDispatch, useSelector } from "react-redux";

import RxCashCard from "./RxCashCard";
import { setPaymentReducer, toggleRxCash } from "../actions/payment.action";
import { URLS } from "../constants/payment.constants";
import {
  GET_RX_CASH_ERROR,
  GET_RX_CASH_SUCCESS,
  RX_PAYMENT_VERIFY_ERROR,
  RX_PAYMENT_VERIFY_SUCCESS
} from "../types/payment.types";
import { getname } from "../utils/payment.utils";

export default function RxCashPackages(props) {
  const amount = props?.formik?.values ? Number(props?.formik?.values[props?.id]) : 100;

  const { setDialog } = React.useContext(CoreDialogContext);

  const [locState, setState] = React.useState({ done: true, mountFlag: true });
  const dispatch = useDispatch();
  const navigate = coreUseNavigate();

  const settingMeta = useSelector((state) => state.mdm?.settingMeta);
  const profile = useSelector((state) => state.profile);
  const rxRechargeSuccess = useSelector((state) => state.forms?.rawFormStatus?.rxRecharge?.success);
  const rxCashOpen = useSelector((state) => state.common?.rxCashOpen);
  const payment = useSelector((state) => state.payment);

  const state = useSelector((state) => state);

  // eslint-disable-next-line no-console
  console.log("state", state);
  // eslint-disable-next-line no-console
  console.log("rxRechargeSuccess", rxRechargeSuccess);
  // eslint-disable-next-line no-console
  console.log("loc state", locState);

  const amounts = [100, 500, 1000];

  const getTokenDetail = () => {
    dispatch(
      apiRequestAction(HTTP.GET, "/getRxCash", true, {}, GET_RX_CASH_SUCCESS, GET_RX_CASH_ERROR)
    );
  };

  const OpenRxRecharge = () => {
    dispatch({ payload: { rxCashOpen: rxCashOpen }, type: "SET_RX_CASH_OPEN" });
  };

  const verifyPayment = (body) => {
    // eslint-disable-next-line no-console
    console.log("body-----------------------------", body);
    const { razorpay_order_id: orderId, razorpay_payment_id: paymentId, razorpay_signature: razorpaySignature } = body;

    dispatch(
      apiRequestAction(
        HTTP.POST,
        "/payment/" + payment?.initPaymentData?.id + "/verifyPayment",
        true,
        {
          orderId,
          paymentId,
          razorpaySignature
        },
        RX_PAYMENT_VERIFY_SUCCESS,
        RX_PAYMENT_VERIFY_ERROR
      )
    );
  };

  const initiateCheckOut = () => {
    // eslint-disable-next-line no-console
    console.log("AASDASDASDASD", payment?.orderData, payment?.initPaymentData);
    if (payment?.orderData && payment?.initPaymentData) {
      const options = {
        // Enter the Key ID generated from the Dashboard
        amount     : payment?.orderData?.amount, 
        // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency   : payment?.orderData?.currency, 
        description: "Test Transaction",
        handler    : verifyPayment,
        image      : "/images/logo.png",
        key        : "rzp_test_Zc0hRXwAcUZkQA",
        name       : "Token Fill", 
        notes      : { address: "Razorpay Corporate Office" },
                
        order_id: payment?.orderData?.id,
        //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        prefill : {
          contact: profile?.contact?.phone,
          email  : profile?.contact?.email,
          name   : getname(profile?.basic),
        },
        theme: { color: theme.palette.primary.main },
      };
      const rzp1 = new window.Razorpay(options);

      /**
       * @todo
       * Options should be stored in the database
       */
      rzp1.on("payment.failed", (response) => {
        // eslint-disable-next-line no-console
        console.error(response?.error?.code);
        // eslint-disable-next-line no-console
        console.error(response?.error?.description);
        // eslint-disable-next-line no-console
        console.error(response?.error?.source);
        // eslint-disable-next-line no-console
        console.error(response?.error?.step);
        // eslint-disable-next-line no-console
        console.error(response?.error?.reason);
        // eslint-disable-next-line no-console
        console.error(response?.error?.metadata?.order_id);
        // eslint-disable-next-line no-console
        console.error(response?.error?.metadata?.payment_id);
      });
      rzp1.open();
      dispatch(toggleRxCash);
    } else {
      setDialog({        
        noCancelButton: true,
        showDialog    : true,
        subtitle      : "Payment server failure",
        title         : "Error",
        type          : "error"
      });
    }
  };

  React.useEffect(() => {
    getTokenDetail();
    setState({ ...locState, mountFlag: false });
  }, []);

  React.useEffect(() => {
    if (payment?.startPaymentSuccess) {
      dispatch(setPaymentReducer({ startPaymentSuccess: false }));
      initiateCheckOut();
    }
  }, [payment?.startPaymentSuccess]);

  React.useEffect(() => {
    if (payment?.rxPaymentVerifySuccess) {
      dispatch(
        setPaymentReducer({
          amountPaid            : amount * settingMeta?.find((tmp) => tmp.name === "rxCacheCharge")?.value?.gst + amount,
          finalPaymentData      : payment?.initPaymentData,
          initPaymentData       : null,
          orderData             : null,
          paymentStatus         : "success",
          rxCashReceived        : amount * settingMeta?.find((tmp) => tmp.name === "rxTokenRatio")?.value?.ratio,
          rxPaymentVerifySuccess: false,
          startPaymentError     : false,
          startPaymentLoading   : false,
          startPaymentSuccess   : false,
        })
      );
      OpenRxRecharge();
      getTokenDetail();
      navigate("/" + URLS.RX_RECHARGE_SUCCESS);
    }
    if (payment?.rxPaymentVerifyError) {
      dispatch(
        setPaymentReducer({
          finalPaymentData    : payment?.initPaymentData,
          initPaymentData     : null,
          orderData           : null,
          paymentStatus       : "fail",
          rxPaymentVerifyError: false,
          startPaymentError   : false,
          startPaymentLoading : false,
          startPaymentSuccess : false,
        })
      );
      navigate("/" + URLS.RX_RECHARGE_FAILED);
    }
  }, [payment?.rxPaymentVerifySuccess, payment?.rxPaymentVerifyError, payment?.rxPaymentVerifyData]);

  const countAmount = (value) => {
    if (value) {
      props?.formik?.setFieldValue(props.id, Number(props?.formik?.values[props.id]) + value);
    } else {
      props?.formik?.setFieldValue(props.id, "");
    }
  };

  return (
    <>
      {/* Available Balance */}
      <CoreBox
        styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN, CoreClasses.ALIGNMENT.ALIGN_ITEMS_END]}
      >
        <CoreTypographyCaption>Available Balance:</CoreTypographyCaption>

        <CoreTypographyCaption
          styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_BOLD]}
        >{`${payment?.rxCash} RxCash`}</CoreTypographyCaption>
      </CoreBox>

      <CoreDivider />

      <CoreTypographyCaption>
        Please click on the below blocks to add RxCash to your account
      </CoreTypographyCaption>

      <CoreGrid styleClasses={[CoreClasses.MARGIN.MY1]}>
        {amounts?.map((amount, index) => {
          return <RxCashCard
            key={`rx-cash-card-${index}`}
            gridProps={{ gridSize: 4 }}
            value={amount}
            onClick={() => countAmount(amount)} />;
        })}
      </CoreGrid>

      <CoreBox styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}>
        <CoreTextButton
          startIcon={<CoreIcon>restart_alt</CoreIcon>}
          label="Reset"
          onClick={() => countAmount()}
        />
      </CoreBox>

      <CoreLabel styleClasses={[CoreClasses.COLOR.TEXT_ERROR]}>
        {props.formik?.errors?.rxPackages}
      </CoreLabel>

      <CoreDivider />

      <CoreBox
        styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN, CoreClasses.ALIGNMENT.ALIGN_ITEMS_END]}
      >
        <CoreTypographyCaption>Selected Tokens:</CoreTypographyCaption>

        <CoreTypographyCaption styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_BOLD]}>{`${
          amount * settingMeta?.find((tmp) => tmp?.name === "rxTokenRatio")?.value?.ratio
        } RXCash`}</CoreTypographyCaption>
      </CoreBox>

      <CoreBox
        styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN, CoreClasses.ALIGNMENT.ALIGN_ITEMS_END]}
      >
        <CoreTypographyCaption>Amount:</CoreTypographyCaption>

        <CoreTypographyCaption styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_BOLD]}>
          {amount}
        </CoreTypographyCaption>
      </CoreBox>

      <CoreBox
        styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN, CoreClasses.ALIGNMENT.ALIGN_ITEMS_END]}
      >
        <CoreTypographyCaption>
                Tax & Charges: ({settingMeta?.find((tmp) => tmp.name === "rxCacheCharge")?.value?.gst * 100}%)
        </CoreTypographyCaption>

        <CoreTypographyCaption styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_BOLD]}>
          {amount * settingMeta?.find((tmp) => tmp.name === "rxCacheCharge")?.value?.gst}
        </CoreTypographyCaption>
      </CoreBox>

      <CoreBox
        styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN, CoreClasses.ALIGNMENT.ALIGN_ITEMS_END]}
      >
        <CoreTypographyCaption>Payable Amount:</CoreTypographyCaption>

        <CoreTypographyCaption styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_BOLD]}>
          {amount * settingMeta?.find((tmp) => tmp.name === "rxCacheCharge")?.value?.gst + amount}
        </CoreTypographyCaption>
      </CoreBox>

      <CoreDivider />

      <CoreTypographyCaption>
        By clicking the Recharge you agree to the&nbsp;
        <CoreLink styleClasses={[CoreClasses.RX.RX_RECHARGE_DISCLAIMER_TERMS_AND_CONDITIONS]}>
          <CoreTypographyCaption>Terms & Conditions</CoreTypographyCaption>
        </CoreLink>
      </CoreTypographyCaption>
    </>
  );
}
