export const AUTH_LOADING = "AUTH_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR ";
export const CHECK_LOGIN_LOADING = "CHECK_LOGIN_LOADING";
export const CHECK_LOGIN_SUCCESS_REGISTERED = "CHECK_LOGIN_SUCCESS_REGISTERED";
export const CHECK_LOGIN_SUCCESS_UNREGISTERED =
  "CHECK_LOGIN_SUCCESS_UNREGISTERED";
export const CHECK_LOGIN_ERROR = "CHECK_LOGIN_ERROR";
export const NAVIGATE_TO_OTP_LOGIN_SUCCESS = "NAVIGATE_TO_OTP_LOGIN_SUCCESS";
export const NAVIGATE_TO_OTP_LOGIN_ERROR = "NAVIGATE_TO_OTP_LOGIN_ERROR";
export const NAVIGATE_TO_OTP_LOGIN_LOADING = "NAVIGATE_TO_OTP_LOGIN_LOADING";
export const NAVIGATE_TO_RESET_PASSWORD_SUCCESS =
  "NAVIGATE_TO_RESET_PASSWORD_SUCCESS";
export const NAVIGATE_TO_RESET_PASSWORD_ERROR =
  "NAVIGATE_TO_RESET_PASSWORD_ERROR";
export const NAVIGATE_TO_RESET_PASSWORD_LOADING =
  "NAVIGATE_TO_RESET_PASSWORD_LOADING";
export const SAVE_NAV_DATA = "SAVE_NAV_DATA";

export const GET_PROFILE_BASIC_LOADING = "GET_PROFILE_BASIC_LOADING";
export const GET_PROFILE_BASIC_SUCCESS = "GET_PROFILE_BASIC_SUCCESS";
export const GET_PROFILE_BASIC_ERROR = "GET_PROFILE_BASIC_ERROR";

export const UPDATE_PROFILE_BASIC_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_BASIC_ERROR = "UPDATE_PROFILE_ERROR";

export const GET_PROFILE_REGISTRATION_LOADING =
  "GET_PROFILE_REGISTRATION_LOADING";
export const GET_PROFILE_REGISTRATION_SUCCESS =
  "GET_PROFILE_REGISTRATION_SUCCESS";
export const GET_PROFILE_REGISTRATION_ERROR = "GET_PROFILE_REGISTRATION_ERROR";
export const UPDATE_PROFILE_REGISTRATION_SUCCESS =
  "UPDATE_PROFILE_REGISTRATION_SUCCESS";
export const UPDATE_PROFILE_REGISTRATION_ERROR =
  "UPDATE_PROFILE_REGISTRATION_ERROR";

export const GET_PROFILE_EDUCATION_LOADING = "GET_PROFILE_EDUCATION_LOADING";
export const GET_PROFILE_EDUCATION_SUCCESS = "GET_PROFILE_EDUCATION_SUCCESS";
export const GET_PROFILE_EDUCATION_ERROR = "GET_PROFILE_EDUCATION_ERROR";
export const CREATE_PROFILE_EDUCATION_SUCCESS =
  "CREATE_PROFILE_EDUCATION_SUCCESS";
export const CREATE_PROFILE_EDUCATION_ERROR = "CREATE_PROFILE_EDUCATION_ERROR";

export const UPDATE_PROFILE_EDUCATION_SUCCESS =
  "UPDATE_PROFILE_EDUCATION_SUCCESS";
export const UPDATE_PROFILE_EDUCATION_ERROR = "UPDATE_PROFILE_EDUCATION_ERROR";

export const CREATE_PROFILE_ADDRESS_SUCCESS = "CREATE_PROFILE_ADDRESS_SUCCESS";
export const CREATE_PROFILE_ADDRESS_ERROR = "CREATE_PROFILE_ADDRESS_ERROR";
export const UPDATE_PROFILE_ADDRESS_SUCCESS = "UPDATE_PROFILE_ADDRESS_SUCCESS";
export const UPDATE_PROFILE_ADDRESS_ERROR = "UPDATE_PROFILE_ADDRESS_ERROR";
export const GET_PROFILE_ADDRESS_LOADING = "GET_PROFILE_ADDRESS_LOADING";
export const GET_PROFILE_ADDRESS_SUCCESS = "GET_PROFILE_ADDRESS_SUCCESS";
export const GET_PROFILE_ADDRESS_ERROR = "GET_PROFILE_ADDRESS_ERROR";
export const DELETE_PROFILE_ADDRESS_LOADING = "DELETE_PROFILE_ADDRESS_LOADING";
export const DELETE_PROFILE_ADDRESS_SUCCESS = "DELETE_PROFILE_ADDRESS_SUCCESS";
export const DELETE_PROFILE_ADDRESS_ERROR = "DELETE_PROFILE_ADDRESS_ERROR";
export const DELETE_PROFILE_EDUCATION_LOADING =
  "DELETE_PROFILE_EDUCATION_LOADING";
export const DELETE_PROFILE_EDUCATION_SUCCESS =
  "DELETE_PROFILE_EDUCATION_SUCCESS";
export const DELETE_PROFILE_EDUCATION_ERROR = "DELETE_PROFILE_EDUCATION_ERROR";
export const DELETE_PROFILE_EXPERIENCE_LOADING =
  "DELETE_PROFILE_EXPERIENCE_LOADING";
export const DELETE_PROFILE_EXPERIENCE_SUCCESS =
  "DELETE_PROFILE_EXPERIENCE_SUCCESS";
export const DELETE_PROFILE_EXPERIENCE_ERROR =
  "DELETE_PROFILE_EXPERIENCE_ERROR";

export const GET_PROFILE_EXPERIENCE_LOADING = "GET_PROFILE_EXPERIENCE_LOADING";
export const GET_PROFILE_EXPERIENCE_SUCCESS = "GET_PROFILE_EXPERIENCE_SUCCESS";
export const GET_PROFILE_EXPERIENCE_ERROR = "GET_PROFILE_EXPERIENCE_ERROR";
export const CREATE_PROFILE_EXPERIENCE_SUCCESS =
  "CREATE_PROFILE_EXPERIENCE_SUCCESS";
export const CREATE_PROFILE_EXPERIENCE_ERROR =
  "CREATE_PROFILE_EXPERIENCE_ERROR";

export const UPDATE_PROFILE_EXPERIENCE_SUCCESS =
  "UPDATE_PROFILE_EXPERIENCE_SUCCESS";
export const UPDATE_PROFILE_EXPERIENCE_ERROR =
  "UPDATE_PROFILE_EXPERIENCE_ERROR";

export const GET_PROFILE_CONTACT_LOADING = "GET_PROFILE_CONTACT_LOADING";
export const GET_PROFILE_CONTACT_SUCCESS = "GET_PROFILE_CONTACT_SUCCESS";
export const GET_PROFILE_CONTACT_ERROR = "GET_PROFILE_CONTACT_ERROR";

export const GET_ROLE_PERMISSION_LOADING = "GET_ROLE_PERMISSION_LOADING";
export const GET_ROLE_PERMISSION_SUCCESS = "GET_ROLE_PERMISSION_SUCCESS";
export const GET_ROLE_PERMISSION_ERROR = "GET_ROLE_PERMISSION_ERROR";

export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";
export const SESSION_EXPIRED = "SESSION_EXPIRED";
export const SESSION_RECALLED = "SESSION_RECALLED";
export const SAVE_EXPIRED_SESSION = "SAVE_EXPIRED_SESSION";
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";
export const CLIENT_INFORMATION_FETCH_SUCCESS = "CLIENT_INFORMATION_FETCH_SUCCESS";
export const CLIENT_INFORMATION_FETCH_ERROR = "CLIENT_INFORMATION_FETCH_ERROR";

export const NAVIGATE_TO_OTP_LOGIN_API = "/checkLoginOrRegister?loginWithOtp=1";
export const NAVIGATE_TO_RESET_PASSWORD_API = "/checkLoginOrRegister?loginWithOtp=1";

export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const RESET_CHANGE_PASSWORD_STATUS = "RESET_CHANGE_PASSWORD_STATUS";
export const ADD_USER_LOADING = "ADD_USER_LOADING";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";
export const SAVE_DEFAULT = "SAVE_DEFAULT";
export const AUTH_DATA_SAVED = "AUTH_DATA_SAVED";