export function SanRxRexharge(formData, apiMeta, state) {
  let gstVal = state.mdm.settingMeta.find((tmp) => tmp.name === "rxCacheCharge")?.value?.gst || 0.18;
  let amountWithTax = formData.rxCashPackages + formData.rxCashPackages * gstVal;

  return {
    endpoint : apiMeta.endpoint.replace(":id", state?.auth?.uid),
    reduxData: apiMeta.reduxData,
    values   : {
      amount: amountWithTax * 100,
      token: 
      formData.rxCashPackages *
      state.mdm.settingMeta.find((tmp) => tmp.name === "rxTokenRatio")?.value?.ratio,
    },
  };
}