import {
  CoreTypographyBody1,
  CoreTypographyCaption,
  CoreBox,
  CoreClasses,
  CoreReactSelectInput
} from "@wrappid/core";

export const CustomInputContainer = (props) => {
  // eslint-disable-next-line no-console
  console.log("PROPS", props);
  if (props.isHidden) {
    return <CoreReactSelectInput {...props} />;
  } else {
    return (
      <span>
        <span>
          <CoreReactSelectInput {...props} />
        </span>

        <span
          style={
            props.selectProps.value.length === 0 &&
                        props.value.length === 0
              ? { color: "gray", marginLeft: "-80%" }
              : { color: "gray" }
          }
        >
          {props.options &&
                    props.options.length > 0 &&
                    props.selectProps.inputValue &&
                    props.selectProps.inputValue.length > 0 &&
                    props.options[0].label
                      .slice(0, props.selectProps.inputValue.length)
                      .toLowerCase() ==
                        props.selectProps.inputValue.toLowerCase()
            ? props.options[0].label.slice(
              props.selectProps.inputValue.length,
              props.options[0].label.length
            )
            : props.selectProps.value.length === 0
              ? "Write Medicines"
              : ""}
        </span>
      </span>
    );
  }
};

export const CustomMedicineOption = (data) => {
  // eslint-disable-next-line no-console
  // console.log("inner props", data);
  return (
    <CoreBox
      {...data?.innerProps}
      styleClasses={
        data?.isFocused
          ? [CoreClasses.PADDING.P1, CoreClasses.BG.BG_SECONDARY]
          : [CoreClasses.PADDING.P1]
      }
    >
      <CoreBox component="div">
        <CoreTypographyBody1 styleClasses={[CoreClasses.MARGIN.MB0]}>
          {data?.data?.label}
        </CoreTypographyBody1>
      </CoreBox>

      {data?.data?.data && (
        <CoreBox component="div">
          <CoreTypographyCaption
            styleClasses={[CoreClasses.MARGIN.MB0]}
            component="div"
          >
            {data?.data?.data?.ChemicalComposition?.name}
          </CoreTypographyCaption>

          <CoreTypographyCaption component="div">
            {data?.data?.data?.MedicineCompany?.name}
          </CoreTypographyCaption>
        </CoreBox>
      )}
    </CoreBox>
  );
};
export const customStyle = (theme) => {
  return {
    control: (provided) => ({ ...provided }),
    input  : (provided) => ({
      ...provided,
      fontWeight: "bold"
    }),
    option: (provided) => ({
      ...provided,
      ":active": {
        ...provided[":active"],
        backgroundColor: theme?.color?.primaryTextColorLight,
        color          : theme?.color?.primaryTextColor
      },
      ":hover": {
        ...provided[":active"],
        backgroundColor: theme?.color?.primaryTextColorLight,
        color          : theme?.color?.primaryTextColor
      },
      backgroundColor: theme?.color?.defaultBackgroundColor
    })
  };
};
