
import {
  CoreDivider, CoreForm, CoreTextButton, CoreBox, FORM_EDIT_MODE, FORM_IDS, FORM_VIEW_MODE, CoreClasses
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

export default function PatientSelection() {
  const dispatch = useDispatch();

  const prescriptionNavData = useSelector(
    (state) => state.prescription.navData
  );

  return (
    <>
      {prescriptionNavData && prescriptionNavData?.Person ? (
        <>
          <CoreBox /* gridProps={{ gridSize: 6 }} */>
            <CoreForm
              allowDelete={false}
              formId={FORM_IDS.__PRESCRIPTION_PATIENT_PROFILE}
              // -- initData={getPrescriptionInitData(
              //   { prescriptionNavData: prescriptionNavData },
              //   FORM_IDS.__PRESCRIPTION_PATIENT_PROFILE
              // )}
              mode={FORM_VIEW_MODE}
              allowEdit={false}
              // -- formRef={formRef}
              onMountRead={false}
            />
          </CoreBox>

          <CoreBox styleClasses={[CoreClasses.TEXT.TEXT_END]}>
            <CoreTextButton
              label="Change Patient"
              onClick={() => {
                dispatch({
                  payload: {},
                  // -- type: SET_PATIENT,
                });
              }}
            />
          </CoreBox>

          <CoreDivider />
        </>
      ) : (
        <CoreForm
          formId={FORM_IDS.__SEARCH_PATIENT}
          mode={FORM_EDIT_MODE}
          allowEdit={true}
        />
      )}
    </>
  );
}
