import { useEffect } from "react";

import {
  CoreClasses,
  CoreTypographyBody2,
  HTTP,
  apiRequestAction
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import PastAppointmentRow from "./PastAppointmentRow";
import {
  GET_PATIENT_PAST_APPOINTMENT_ERROR,
  GET_PATIENT_PAST_APPOINTMENT_SUCCESS
} from "../types/patient.types";

export default function PastAppointments(props) {
  const { patientID } = props || {};
  const dispatch = useDispatch();
  const pastAppointments = useSelector(
    state => state?.patient?.pastAppointment
  );

  const pastAppointment = pastAppointments?.rows;

  useEffect(() => {
    let reqURL = "/business/all/" + "CompletedAppointments";
    let apiData = {};

    apiData = {
      _defaultFilter: encodeURIComponent(
        JSON.stringify({
          _status  : "completed",
          patientId: patientID,
        })
      ),
      limit : 3,
      offset: 0,
    };

    dispatch(
      apiRequestAction(
        HTTP.GET,
        reqURL,
        true,
        apiData,
        GET_PATIENT_PAST_APPOINTMENT_SUCCESS,
        GET_PATIENT_PAST_APPOINTMENT_ERROR,
        null,
        null,
        null,
        null,
        null,
        { patientId: patientID }
      )
    );
  }, [patientID]);

  return pastAppointments === null || pastAppointments?.rows?.length === 0 ? (
    <CoreTypographyBody2
      styleClasses={[CoreClasses?.TEXT?.TEXT_CENTER, CoreClasses?.PADDING?.P1]}>
      No Past Appointments
    </CoreTypographyBody2>
  ) : (
    pastAppointment?.map(row => {
      return <PastAppointmentRow key={row?.id} row={row} />;
    })
  );
}
