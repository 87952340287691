import SmallappBuilderStyles from "../../modules/app-builder/styles/SmallModuleStyles";
import SmallappointmentStyles from "../../modules/appointment/styles/SmallModuleStyles";
import SmallassistantStyles from "../../modules/assistant/styles/SmallModuleStyles";
import SmallauthStyles from "../../modules/auth/styles/SmallModuleStyles";
import SmallclinicStyles from "../../modules/clinic/styles/SmallModuleStyles";
import SmalldashboardStyles from "../../modules/dashboard/styles/SmallModuleStyles";
import SmalldataManagementStyles from "../../modules/data-management/styles/SmallModuleStyles";
import SmallguideStyles from "../../modules/guide/styles/SmallModuleStyles";
import SmallpatientStyles from "../../modules/patient/styles/SmallModuleStyles";
import SmallpaymentStyles from "../../modules/payment/styles/SmallModuleStyles";
import SmallprescriptionStyles from "../../modules/prescription/styles/SmallModuleStyles";
import SmallprofileStyles from "../../modules/profile/styles/SmallModuleStyles";
import SmallsettingsStyles from "../../modules/settings/styles/SmallModuleStyles";
import SmalluserManagementStyles from "../../modules/user-management/styles/SmallModuleStyles";

const SmallModulesStyles = {
...new SmallappBuilderStyles().style,
...new SmallappointmentStyles().style,
...new SmallassistantStyles().style,
...new SmallauthStyles().style,
...new SmallclinicStyles().style,
...new SmalldashboardStyles().style,
...new SmalldataManagementStyles().style,
...new SmallguideStyles().style,
...new SmallpatientStyles().style,
...new SmallpaymentStyles().style,
...new SmallprescriptionStyles().style,
...new SmallprofileStyles().style,
...new SmallsettingsStyles().style,
...new SmalluserManagementStyles().style,
};
 export default SmallModulesStyles