import { getFullName } from "@wrappid/core";

export const searchPatient = {
  getOptionLabel: (data) => {
    if (data)
      return data?.phone + "|" + data?.email + "|" + getFullName(data?.Person);
    else return "Add data";
  },
  getOptionValue: (data) => {
    return data;
  },
  isOptionsEqualToValue: (option, value) => {
    return option?.id === value?.id;
  },
  onChangeDispatch: { type: "SET_PATIENT" },
};