import {
  CoreAvatar, CoreBox, CoreCard, CoreCardContent, CoreClasses, CoreGrid, CoreTypographyBody1, CoreTypographyCaption 
} from "@wrappid/core";

export default function AppointmentCard(props) {

  const { data } = props;
  const Patient = data?.Patient;
  const Clinic = data?.Clinic;

  return (
    <>
      <CoreCard gridProps={{ gridSize: { sm: 4 } }}>
        <CoreCardContent>
          {
            <CoreGrid>
              <CoreBox
                gridProps={{ gridSize: { sm: 2 } }}
                styleClasses={[CoreClasses.LAYOUT.HORIZONTAL_CENTER]}
              >
                <CoreAvatar
                  styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_LARGE]}
                  src={Patient?.photoUrl}
                />
              </CoreBox>

              <CoreGrid gridProps={{ gridSize: { sm: 10 } }}>
                <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                  <CoreTypographyCaption>Name: </CoreTypographyCaption>
                </CoreBox>

                <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                  {Patient && (
                    <CoreTypographyCaption>
                      {[Patient.firstName, Patient.lastName, Patient.middleName].join(" ")}
                    </CoreTypographyCaption>
                  )}
                </CoreBox>

                <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                  <CoreTypographyCaption>Relation: </CoreTypographyCaption>
                </CoreBox>

                <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                  <CoreTypographyCaption>self</CoreTypographyCaption>
                </CoreBox>

                <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                  <CoreTypographyCaption>Mobile No: </CoreTypographyCaption>
                </CoreBox>

                <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                  <CoreTypographyCaption>
                    {Patient && Patient.User.phone}
                  </CoreTypographyCaption>
                </CoreBox>

                <CoreBox gridProps={{ gridSize: 6 }}>
                  <CoreTypographyCaption>Appointment ID: </CoreTypographyCaption>
                </CoreBox>

                <CoreTypographyBody1 gridProps={{ gridSize: { sm: 6 } }}>
                  {data?.id}
                </CoreTypographyBody1>

                <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                  <CoreTypographyCaption>
                  Appointment Time:{" "}
                  </CoreTypographyCaption>
                </CoreBox>

                <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                  <CoreTypographyCaption>
                    {data?.date +
                    ", " +
                    data?.startTime +
                    ", " +
                    data?.endTime}
                  </CoreTypographyCaption>
                </CoreBox>

                <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                  <CoreTypographyCaption>Clinic Name: </CoreTypographyCaption>
                </CoreBox>

                <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                  <CoreTypographyCaption>
                    {(Clinic && Clinic.name) ||
                    "No Clinic"}
                  </CoreTypographyCaption>
                </CoreBox>
              </CoreGrid>
            </CoreGrid>
          }
        </CoreCardContent>
      </CoreCard>
    </>
  );
}