import { getFullName } from "@wrappid/core";

/* eslint-disable id-length */
export const getBmi = (formik, elem) => {
  const allElements = elem?.allElements;
  const heightId = allElements?.find((el) => el.name === "height")?.id;
  const weightId = allElements?.find((el) => el.name === "weight")?.id;

  if (
    heightId &&
        weightId &&
        formik?.values &&
        formik?.values[heightId] &&
        formik?.values[weightId]
  ) {
    return {
      calculatedValue: Number(
        formik?.values[heightId] /
                (formik?.values[weightId] * formik?.values[weightId])
      ).toFixed(3)
    };
  } else {
    return { calculatedValue: 0 };
  }
};

export const prescriptionAsyncSelects = {
  getOptionLabel: (data) => {
    return data?.label || data?.name || "";
  },
  getOptionValue: (data) => {
    // eslint-disable-next-line id-length
    return data?.map((d) => {
      return typeof d === "string"
        ? { label: d }
        : { id: d?.id || "", label: d?.label || d?.name || "" };
    });
  },
  isOptionEqualToValue: (option, value) => {
    // -- console.log("COMPARE", option, value);
    if (typeof option === "string") {
      if (typeof value === "string") {
        return option === value;
      } else if (value?.id) {
        return option === value?.id;
      } else if (value?.label) {
        return option === value?.label || option === value?.name;
      }
    } else {
      return option?.id === value?.id;
    }
  },
};

export const searchPatient = {
  getOptionLabel: (data) => {
    if (data) return data.phone + "|" + data.email + "|" + getFullName(data?.Person);
    else return "Add data";
  },
  getOptionValue: (data) => {
    return data;
  },
  isOptionsEqualToValue: (option, value) => {
    return option.id === value.id;
  },
  onChangeDispatch: { type: "SET_PATIENT" },
};

