import {
  CoreDivider,
  CoreTypographyBody1,
  CoreForm,
  FORM_EDIT_MODE,
  FORM_IDS,
  FORM_VIEW_MODE,
  CoreBox,
  CoreGrid,
  CoreClasses
  // coreUseTheme
} from "@wrappid/core";
import { useSelector } from "react-redux";

import PrescriptionGenratedData from "./PrescriptionGenratedData";
import PrescriptionMedicine from "./PrescriptionMedicine";

export default function PrescriptionForms({
  formKey,
  formDesign,
  gridProps,
  prescription,
  prescriptionNavData,
  GetInitData,
  vitalsForm,
  prescriptionClinic,
  formRef,
  onFormFocus,
  medicineFunctions
}) {
  const allClinics = useSelector(
    (state) => state?.selectOptions?.options?.clinics?.data
  );
    /**
     * @todo why we need this
     * @sumanta-m
     *
     */
  // eslint-disable-next-line etc/no-commented-out-code
  // const theme = coreUseTheme();

  // eslint-disable-next-line no-console
  console.log("ALL CLINIC", allClinics);
  return (
    <CoreBox gridProps={gridProps} styleClasses={[CoreClasses.PADDING.P1]}>
      {formDesign.name === FORM_IDS.__PRESCRIPTION_MEDICINE ? (
      // prescriptionNavData &&
      // (prescriptionClinic || !allClinics || (allClinics && allClinics.length === 0)) ? (
        <CoreGrid gridProps={formDesign?.design || {}}>
          <PrescriptionMedicine
            mode={FORM_VIEW_MODE}
            allowEdit={
              (prescription && prescription.isCompleted) ||
                            !prescriptionNavData?.Person
                ? false
                : true
            }
            initData={GetInitData(FORM_IDS.__PRESCRIPTION_MEDICINE)}
            formRef={formRef}
            onMountRead={false}
            onFormFocus={onFormFocus}
            // eslint-disable-next-line etc/no-commented-out-code
            // theme={theme}
            AddMedicine={medicineFunctions?.AddMedicine}
            SubmitMeds={medicineFunctions?.SubmitMeds}
            onEditMedicineNote={
              medicineFunctions?.onEditMedicineNote
            }
            onChangeMedGeneric={
              medicineFunctions?.onChangeMedGeneric
            }
            OnMedClear={medicineFunctions?.OnMedClear}
            advMedicines={medicineFunctions?.advMedicines}
            formId={FORM_IDS.__PRESCRIPTION_MEDICINE}
          />
        </CoreGrid>
      ) : // )
        formDesign.name === FORM_IDS.__PRESCRIPTION_PATIENT_PROFILE ? (
          prescriptionNavData && prescriptionNavData?.Person ? (
            <>
              <CoreDivider
                styleClasses={[CoreClasses.MARGIN.MX0, CoreClasses.MARGIN.MB2]}
              />

              <CoreGrid>
                <CoreBox gridProps={{ gridSize: 12 }}>
                  <CoreForm
                    allowDelete={false}
                    formId={
                      FORM_IDS.__PRESCRIPTION_PATIENT_PROFILE
                    }
                    coreId={
                      FORM_IDS.__PRESCRIPTION_PATIENT_PROFILE
                    }
                    gridProps={formDesign?.design || {}}
                    initData={GetInitData(
                      FORM_IDS.__PRESCRIPTION_PATIENT_PROFILE
                    )}
                    // styleClasses={CoreClasses.LAYOUT.AUTH_FORM_CONTAINER}
                    mode={FORM_VIEW_MODE}
                    allowEdit={false}
                    formRef={formRef}
                    onMountRead={false}
                  />
                </CoreBox>

                {/* MAy need to get Via coreform from DB */}
                <CoreBox gridProps={{ gridSize: 12 }}>
                  <PrescriptionGenratedData
                    prescription={prescription}
                  />
                </CoreBox>
              </CoreGrid>

              <CoreDivider />
            </>
          ) : (
            <CoreBox
              styleClasses={[CoreClasses.SHADOW.LARGE, CoreClasses.PADDING.P2]}
            >
              {/* { && (
                              <CoreTypographyCaption>Select patient to proceed</CoreTypographyCaption>
                              )} */}
              <CoreForm
                formId={FORM_IDS.__SEARCH_PATIENT}
                coreId={FORM_IDS.__SEARCH_PATIENT}
                gridProps={formDesign?.design || {}}
                // styleClasses={CoreClasses.LAYOUT.AUTH_FORM_CONTAINER}
                mode={FORM_EDIT_MODE}
                allowEdit={
                  prescription && prescription.isCompleted
                    ? false
                    : true
                }
                formRef={formRef}
              />
            </CoreBox>
          )
        ) : formDesign.name === FORM_IDS.__PRESCRIPTION_CLINIC ? (
          prescriptionClinic ? (
            <>
              <CoreForm
                allowDelete={false}
                allowEdit={false}
                formId={FORM_IDS.__PRESCRIPTION_CLINIC}
                coreId={FORM_IDS.__PRESCRIPTION_CLINIC}
                gridProps={formDesign?.design || {}}
                initData={GetInitData(
                  FORM_IDS.__PRESCRIPTION_CLINIC
                )}
                // styleClasses={CoreClasses.LAYOUT.AUTH_FORM_CONTAINER}
                mode={FORM_VIEW_MODE}
                formRef={formRef}
                onMountRead={false}
              />
            </>
          ) : allClinics && allClinics.length > 0 ? (
            <CoreBox styleClasses={[CoreClasses.TEXT.TEXT_CENTER]}>
              <CoreTypographyBody1>
                            No Clinic selected
              </CoreTypographyBody1>
            </CoreBox>
          ) : null
        ) : formDesign.name === FORM_IDS.__PRESCRIPTION_VITALS ? (
        // All other forms like complaint guideline
        // prescriptionNavData &&
        // (prescriptionClinic || !allClinics || (allClinics && allClinics.length === 0)) && (
          <>
            <CoreForm
              key={"vital-" + formKey}
              allowDelete={false}
              coreId={FORM_IDS.__PRESCRIPTION_VITALS}
              formId={FORM_IDS.__PRESCRIPTION_VITALS}
              gridProps={formDesign?.design || {}}
              formJson={vitalsForm}
              initData={GetInitData(FORM_IDS.__PRESCRIPTION_VITALS)}
              // styleClasses={CoreClasses.LAYOUT.AUTH_FORM_CONTAINER}
              mode={FORM_VIEW_MODE}
              allowEdit={
                (prescription && prescription.isCompleted) ||
                            !prescriptionNavData?.Person
                  ? false
                  : true
              }
              formRef={formRef}
              onMountRead={false}
              onFormFocus={onFormFocus}
            />

            <CoreDivider />
          </>
        ) : (
        // )
        // All other forms like complaint guideline
        // prescriptionNavData &&
        // (prescriptionClinic || !allClinics || (allClinics && allClinics.length === 0)) && (
          <CoreForm
            allowDelete={false}
            coreId={formDesign.name}
            formId={formDesign.name}
            gridProps={formDesign?.design || {}}
            initData={GetInitData(formDesign.name)}
            // styleClasses={CoreClasses.LAYOUT.AUTH_FORM_CONTAINER}
            mode={FORM_VIEW_MODE}
            allowEdit={
              (prescription && prescription.isCompleted) ||
                        !prescriptionNavData?.Person
                ? false
                : true
            }
            formRef={formRef}
            onMountRead={false}
            onFormFocus={onFormFocus}
          />
        // )
        )}
    </CoreBox>
  );
}
