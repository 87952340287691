import {
  CoreBox,
  CoreClasses,
  CoreGrid,
  CoreStack,
  CoreTextButton,
  CoreTypographyBody2,
  CoreTypographyCaption,
  coreUseLocation,
  coreUseParams,
  getAge
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import { RoutesRegistry } from "../../routes.registry";
import { SET_PATIENT } from "../../types/prescription.types";

export default function PrescriptionPatientProfileCard() {
  const dispatch = useDispatch();
  const location = coreUseLocation();
  const prescription = useSelector(
    (state) => state?.prescription?.prescription
  );
  const { id } = coreUseParams();
  const { Person = {} } = useSelector(
    (state) => state.prescription.navData || {}
  );

  const {
    firstName = null,
    middleName = null,
    lastName = null,
    gender = null,
    dob = null,
    profileId = null
  } = Person;

  return (
    <CoreGrid>
      <CoreStack gridProps={{ gridSize: { sm: 4 } }}>
        <CoreTypographyCaption>{"Name: "}</CoreTypographyCaption>

        <CoreTypographyBody2 component="span">
          {[firstName, middleName, lastName].join(" ")}
        </CoreTypographyBody2>
      </CoreStack>

      <CoreStack gridProps={{ gridSize: { sm: 2 } }}>
        <CoreTypographyCaption>{"MRN: "}</CoreTypographyCaption>

        <CoreTypographyBody2 component="span">
          {profileId}
        </CoreTypographyBody2>
      </CoreStack>

      <CoreStack gridProps={{ gridSize: { sm: 1 } }}>
        <CoreTypographyCaption>{"Sex: "}</CoreTypographyCaption>

        <CoreTypographyBody2 component="span">
          {gender}
        </CoreTypographyBody2>
      </CoreStack>

      <CoreStack gridProps={{ gridSize: { sm: 3 } }}>
        <CoreTypographyCaption>{"Age: "}</CoreTypographyCaption>

        <CoreTypographyBody2 component="span">
          {getAge(dob)}&nbsp;
        </CoreTypographyBody2>
      </CoreStack>

      <CoreBox
        gridProps={{ gridSize: { sm: 2 } }}
        styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_END]}
      >
        <CoreTextButton
          styleClasses={[CoreClasses.PADDING.P0]}
          label="Change Patient"
          onClick={() => {
            if (
              (location.pathname ===
                                "/" + RoutesRegistry.PRESCRIPTION ||
                                (id &&
                                    location.pathname ===
                                        "/" +
                                            RoutesRegistry.PRESCRIPTION_SPECIFIC.replace(
                                              ":id",
                                              id
                                            ))) &&
                            prescription?.isCompleted
            ) {
              alert(
                "Sorry!",
                "Can not change patient...prescription already generated",
                "error"
              );
            } else {
              dispatch({ payload: {}, type: SET_PATIENT });
            }
          }}
        />
      </CoreBox>
    </CoreGrid>
  );
}
