/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unescaped-entities */
import { useEffect, useContext, useState } from "react";

import {
  CoreIcon,
  CoreDataTable,
  CoreRoutesContext,
  CoreDialogContext,
  coreUseSearchParams,
  coreUseNavigate,
  __IconTypes,
  FORM_VIEW_MODE,
  FORM_EDIT_MODE
} from "@wrappid/core"; //from wrappid
import { useDispatch, useSelector } from "react-redux";

import SummaryComponent from "./PatientSummaryComponent";
import RelativeUserInfo from "./RelativeUserInfo";
import { RESET_PATIENT, SET_PATIENT_FOR_RELATIVE, HIDE_RELATIVE_FORM } from "../types/patient.types";

const FORM_CREATE_MODE = "create";

export default function DoctorPatientTable(props) {
  let [searchParams] = coreUseSearchParams();
  const navigate = coreUseNavigate();

  const dispatch = useDispatch();
  const { setDialog } = useContext(CoreDialogContext);
  const routeRegistry = useContext(CoreRoutesContext);

  const createPatientSuccess = useSelector(
    state => state?.patient?.createPatientSuccess
  );
  const createPatientError = useSelector(
    state => state?.patient?.createPatientError
  );

  const editPatientSuccess = useSelector(
    state => state?.patient?.editPatientSuccess
  );
  const editPatientError = useSelector(
    state => state?.patient?.editPatientError
  );
  const { showToolbar = true } = props;
  const personID = useSelector(state => state?.profile?.basic?.id);
  const [createFormId, setCreateFormId] = useState("createPatient");
  const [updateFormId, setUpdateFormId] = useState("updatePatient");
  const [formMode, setFormMode] = useState(FORM_VIEW_MODE);
  const [updateFormAPIMode, setupdateFormAPIModeMode] = useState(FORM_VIEW_MODE);

  const tableRowActions = [
    {
      action: data => {
        if (data) {
          // eslint-disable-next-line etc/no-commented-out-code
          // setCreateFormId("createPatientRelative");
          dispatch({
            payload: {
              id    : data["RelatedPerson.id"],
              userId: data["RelatedPerson.userId"],
            },
            type: SET_PATIENT_FOR_RELATIVE,
          });
          // eslint-disable-next-line etc/no-commented-out-code
          // dispatch({ type: SHOW_RELATIVE_FORM });
          
          setUpdateFormId("createPatientRelative");
          setFormMode(FORM_EDIT_MODE);
          setupdateFormAPIModeMode(FORM_CREATE_MODE);
        }
      },
      hide: rowData => {
        if (rowData && rowData["RelatedPerson.User.id"]) {
          return false;
        } else {
          return true;
        }
      },
      icon : { icon: "person", type: CoreIcon.FONTAWESOME_V5_REGULAR_ICON },
      label: "Create Relative",
      type : "action",
    },
    {
      action: data => {
        dispatch({
          payload: {
            Person: {
              dob       : data["RelatedPerson.dob"],
              firstName : data["RelatedPerson.firstName"],
              gender    : data["RelatedPerson.gender"],
              id        : data["RelatedPerson.id"],
              lastName  : data["RelatedPerson.lastName"],
              middleName: data["RelatedPerson.middleName"],
              photoUrl  : data["RelatedPerson.photoUrl"],
              profileId : data["RelatedPerson.profileId"],
            },
            email: data["RelatedPerson.User.email"],
            id   : data["RelatedPerson.User.id"],
            phone: data["RelatedPerson.User.phone"],
          },
          type: "SET_PATIENT",
        });
        navigate(`/${routeRegistry?.prescription.url}`, { state: { fromPatient: true } });
      },
      icon: {
        icon: "fa-file-prescription",
        type: __IconTypes.FONTAWESOME_V5_SOLID_ICON,
      },
      label: "Create Prescription",
      type : "action",
    },
    {
      action: data => {
        // eslint-disable-next-line no-console
        console.log("DADADADAD", data);
        dispatch({
          payload: {
            Person: {
              dob       : data["RelatedPerson.dob"],
              firstName : data["RelatedPerson.firstName"],
              gender    : data["RelatedPerson.gender"],
              id        : data["RelatedPerson.id"],
              lastName  : data["RelatedPerson.lastName"],
              middleName: data["RelatedPerson.middleName"],
              profileId : data["RelatedPerson.profileId"],
            },
            email: data["RelatedPerson.User.email"],
            id   : data["RelatedPerson.User.id"],
            phone: data["RelatedPerson.User.phone"],
          },
          type: "SET_APPOINTMENT_PATIENT",
        });
      },
      icon: {
        icon: "event_available",
        type: __IconTypes.MATERIAL_OUTLINED_ICON,
      },
      label: "Create Appoinment",
      route: `/${routeRegistry?.appointment_manage.url}?create=true`,
      type : "action",
    },
  ];

  useEffect(() => {
    dispatch({ type: HIDE_RELATIVE_FORM });
  }, []);

  useEffect(() => {
    if (createPatientSuccess) {
      setDialog({
        cancelButton: () => {
          dispatch({
            payload: { entity: "MyPatient" },
            type   : "RESET_FILTER_DATA",
          });
          dispatch({ type: RESET_PATIENT });
        },
        doneButton: () => {
          dispatch({
            payload: { entity: "MyPatient" },
            type   : "RESET_FILTER_DATA",
          });
          dispatch({ type: RESET_PATIENT });
        },
        showDialog: true,
        subtitle  : "Patient created successfully.",
        title     : "Success",
      });
    }

    if (createPatientError) {
      setDialog({
        cancelButton: () => {
          dispatch({ type: RESET_PATIENT });
        },
        doneButton: () => {
          dispatch({ type: RESET_PATIENT });
        },
        showDialog: true,
        subtitle  : "Patient could not be created.",
        title     : "Error",
      });
    }
  }, [createPatientSuccess, createPatientError]);

  useEffect(() => {
    if (editPatientSuccess) {
      setDialog({
        cancelButton: () => {
          dispatch({
            payload: { entity: "MyPatient" },
            type   : "RESET_FILTER_DATA",
          });
          dispatch({ type: RESET_PATIENT });
        },
        doneButton: () => {
          dispatch({ type: RESET_PATIENT });
          
        },
        showDialog: true,
        subtitle  : "Patient details updated successfully.",
        title     : "Success",
      });
    }

    if (editPatientError) {
      setDialog({
        cancelButton: () => {
          dispatch({ type: RESET_PATIENT });
        },
        doneButton: () => {
          dispatch({ type: RESET_PATIENT });
        },
        showDialog: true,
        subtitle  : "Patient details not be updated.",
        title     : "Error",
      });
    }
  }, [editPatientSuccess, editPatientError]);
  return (
    <CoreDataTable
      entity={"MyPatient"}
      formMode={formMode}
      updateFormAPIMode={updateFormAPIMode}
      createFormID={createFormId}
      // updateFormID={"patientDualUpdateForm"}
      updateFormID={updateFormId}
      showToolbar={showToolbar}
      // hideUpdateForm={false}
      createEntityButtonText={"My Patient"}
      selectable={false}
      filterQuery={{ filter: { personId: personID } }}
      rowActions={tableRowActions}
      summaryRendererComponent={SummaryComponent}
      afterEditCancel={()=>{
        if(updateFormId === "createPatientRelative"){
          setUpdateFormId("updatePatient");
          setFormMode(FORM_VIEW_MODE);
          dispatch({ type: RESET_PATIENT });
        }
      }}
      preRender_UpdateData_DetailsPaneComponent={updateFormId === "createPatientRelative" && RelativeUserInfo}
      preOnCreate={() => {
        dispatch({
          payload: { id: null, userId: null },
          type   : SET_PATIENT_FOR_RELATIVE,
        });
        setCreateFormId("createPatient");
      }}
      openCreateOnMount={searchParams?.get("create") === "true"}
      // afterEditSuccess={useEffect(() => {
      //   dispatch({ type: RESET_PATIENT });
      // })}
    />
  );
}
