import { CoreCard, CoreCardContent, CoreLayoutItem, AppContainerLayout } from "@wrappid/core";

import PendingAssistantInvitation from "./PendingAssistantInvitation";

export default function ManageAssistantInvitation() {
  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <CoreCard gridProps={{ gridSize: { sm: 4 } }}>
          <CoreCardContent>
            <PendingAssistantInvitation />
          </CoreCardContent>
        </CoreCard>
      </CoreLayoutItem>
    </>
  );
}
