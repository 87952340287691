import RxCashCard from "./components/RxCashCard";
import RxCashPackages from "./components/RxCashPackages";
import RxPaymentFailure from "./components/RxPaymentFailure";
import RxPaymentSuccess from "./components/RxPaymentSuccess";
import RxRecharge from "./components/RxRecharge";

export const ComponentsRegistry = {
  RxCashCard      : { comp: RxCashCard },
  RxCashPackages  : { comp: RxCashPackages },
  RxPaymentFailure: { comp: RxPaymentFailure },
  RxPaymentSuccess: { comp: RxPaymentSuccess },
  RxRecharge      : { comp: RxRecharge },
};
