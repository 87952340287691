import {
  CoreTypographyBody2,
  CoreTypographySubtitle2,
  CoreBox,
  CoreStack,
  CoreTypographyBody1
} from "@wrappid/core";

export default function PrescriptionDoctorProfileCard(props) {
  const { name, regNo, phone, email } = props;

  return (
    <CoreBox>
      <CoreTypographySubtitle2>{name}</CoreTypographySubtitle2>

      <CoreTypographyBody2>
                Registration No. - {regNo}
      </CoreTypographyBody2>

      <CoreStack direction="row">
        <CoreTypographyBody2>{"Email:"}&nbsp;</CoreTypographyBody2>

        <CoreTypographyBody1>{email}</CoreTypographyBody1>
      </CoreStack>

      <CoreStack direction="row">
        <CoreTypographyBody2>{"Phone:"}&nbsp;</CoreTypographyBody2>

        <CoreTypographyBody1>{phone}</CoreTypographyBody1>
      </CoreStack>
    </CoreBox>
  );
}
