export const config = {
  prescriptionData: {
    durationType: [
      { label: "day", value: "day" },
      { label: "month", value: "month" },
      { label: "to continue", value: "to continue" },
      { label: "sos (when needed)", value: "sos (when needed)" },
      { label: "stat", value: "stat" }
    ],
    formulation: [
      { label: "Tablet", value: "Tablet" },
      { label: "Capsule", value: "Capsule" },
      { label: "Ointment", value: "Ointment" },
      { label: "Infusion", value: "Infusion" },
      { label: "Syrup", value: "Syrup" },
      { label: "Eye drop", value: "Eye drop" },
      { label: "Ear drop", value: "Ear drop" },
      { label: "Sachet", value: "Sachet" },
      { label: "Powder", value: "Powder" },
      { label: "Injection", value: "Injection" },
      { label: "Oral suspension", value: "Oral suspension" },
      { label: "Respule", value: "Respule" },
      { label: "Metered Dose Inhaler", value: "Metered Dose Inhaler" },
      { label: "Intravenous Fluid", value: "Intravenous Fluid" },
      { label: "Enema", value: "Enema" },
      { label: "Suppositary", value: "Suppositary" },
      { label: "Nasal spray", value: "Nasal spray" },
      { label: "Dermal Patch", value: "Dermal Patch" },
      { label: "Eye ointment", value: "Eye ointment" },
      { label: "Gel", value: "Gel" },
      { label: "cream", value: "cream" },
      { label: "Lotion", value: "Lotion" },
      { label: "Cartridge", value: "Cartridge" },
      { label: "Soap", value: "Soap" },
      { label: "Oral drops", value: "Oral drops" },
      { label: "Solution", value: "Solution" },
      { label: "Liquid", value: "Liquid" },
      { label: "Surgical", value: "Surgical" },
      { label: "Dry syrup", value: "Dry syrup" }
    ],
    frequency: [
      { label: "Once a day", value: "once a day" },
      { label: "Twice a day", value: "twice a day" },
      { label: "Thrice a day", value: "thrice a day" },
      { label: "Four times a day", value: "four times a day" },
      { label: "Five times a day", value: "five times a day" },
      { label: "Before Bed", value: "before bed" },

      { label: "OD", value: "OD" },
      { label: "BD", value: "BD" },
      { label: "TDS", value: "TDS" },
      { label: "QID", value: "QID" },
      { label: "ODHS", value: "ODHS" },
      { label: "q4h", value: "q4h" },
      { label: "q6h", value: "q6h" },
      { label: "q2h", value: "q2h" },
      { label: "q8h", value: "q8h" }
    ],
    meal: [
      { label: "before breakfast", value: "before breakfast" },
      { label: "after breakfast", value: "after breakfast" },
      { label: "before lunch", value: "before lunch" },
      { label: "after lunch", value: "after lunch" },
      { label: "before dinner", value: "before dinner" },
      { label: "after dinner", value: "after dinner" },
      { label: "before food", value: "before food" },
      { label: "after food", value: "after food" }
    ]
  }
};
