import {
  CoreBox,
  CoreClasses,
  CoreGrid,
  CoreIcon,
  CoreIconButton
} from "@wrappid/core";

export default function TemplateButtonBlock({
  name,
  label,
  operation,
  disabled
}) {
  return (
    <CoreGrid>
      <CoreBox gridProps={{ gridSize: 9 }}>{label}</CoreBox>

      <CoreBox
        gridProps={{ gridSize: 3 }}
        styleClasses={[CoreClasses.LAYOUT.RIGHT_ALIGN]}
      >
        <CoreIconButton
          disabled={disabled}
          onClick={() => {
            operation(name, "add");
          }}
        >
          <CoreIcon>add</CoreIcon>
        </CoreIconButton>

        <CoreIconButton
          disabled={disabled}
          onClick={() => {
            operation(name, "remove");
          }}
        >
          <CoreIcon>cancel</CoreIcon>
        </CoreIconButton>
      </CoreBox>
    </CoreGrid>
  );
}
