import { CoreTypographyBody1 } from "@wrappid/core";

export default function SettingsProfile() {
  return (
    <>
      <CoreTypographyBody1>
        Comming Soon... Profile Settings
      </CoreTypographyBody1>
    </>
  );
}
