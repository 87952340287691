import {
  CoreAvatar,
  CoreTypographyBody1,
  CoreTextButton,
  CoreBox,
  CoreGrid,
  CoreClasses,
  getFullName
} from "@wrappid/core";

export default function SearchAssistantCard(props) {
  const { data, optionProps } = props;

  return (
    <CoreBox
      {...optionProps}
      styleClasses={[CoreClasses.FLEX.DIRECTION_COLUMN, CoreClasses.ALIGNMENT.ALIGN_ITEMS_START]}
    >
      <CoreGrid>
        <CoreAvatar
          styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_MEDIUM]}
          gridProps={{ gridSize: 1 }}
          src={data?.photoUrl}
        />

        {data.Person && (
          <CoreTypographyBody1>{getFullName(data.Person)}</CoreTypographyBody1>
        )}

        {data.email && (
          <CoreBox>
            <CoreTypographyBody1>{data.email}</CoreTypographyBody1>
          </CoreBox>
        )}

        {data.phone && (
          <CoreBox>
            <CoreTypographyBody1>{data.phone}</CoreTypographyBody1>
          </CoreBox>
        )}

        <CoreBox styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_END]}>
          <CoreTextButton label="Send Invitation" />
        </CoreBox>
      </CoreGrid>
    </CoreBox>
  );
}
