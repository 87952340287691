/* eslint-disable id-length */
/* eslint-disable no-unused-vars */
// asyncSelect and formSubmitSanitization

import { searchClinic } from "./functions/asyncSelect.functions";
import { SanClinicAddUrlChange, SanClinicDeleteUrlChange, SanClinicEditUrlChange, SanClinicReadUrlChange } from "./functions/sanity.functions";

export const FunctionsRegistry = {
  SanClinicAddUrlChange   : SanClinicAddUrlChange,
  SanClinicDeleteUrlChange: SanClinicDeleteUrlChange,
  
  SanClinicEditUrlChange: SanClinicEditUrlChange,
  
  SanClinicReadUrlChange: SanClinicReadUrlChange,
    
  searchClinic: searchClinic,
};
