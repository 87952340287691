import {
  CoreAvatar,
  CoreTypographyBody2,
  CoreTypographySubtitle2,
  CoreBox,
  CoreGrid,
  CoreStack,
  CoreClasses,
  CoreTypographyBody1
} from "@wrappid/core";

export default function PrescriptionClinicCard(props) {
  const {
    fullName,
    phone,
    clinicLogo,
    addLine1,
    addLine2,
    country,
    state,
    district,
    city,
    pin
  } = props;

  return !fullName || fullName === "" ? (
    <CoreBox>
      <CoreTypographySubtitle2>
                You dont have any clinics
      </CoreTypographySubtitle2>
    </CoreBox>
  ) : (
    <CoreGrid
      styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}
    >
      <CoreBox
        styleClasses={[CoreClasses.TEXT.TEXT_END]}
        gridProps={{ gridSize: 8 }}
      >
        <CoreTypographySubtitle2>{fullName}</CoreTypographySubtitle2>

        <CoreTypographyBody2>
          {addLine1 + ", " + addLine2 + ", " + city + ": " + pin}
        </CoreTypographyBody2>

        <CoreTypographyBody2>
          {district + ", " + state + ", " + country}
        </CoreTypographyBody2>

        <CoreStack
          direction="row"
          styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}
        >
          <CoreTypographyBody2
            styleClasses={[CoreClasses.TEXT.TEXT_END]}
          >
            {"Phone:"}&nbsp;
          </CoreTypographyBody2>

          <CoreTypographyBody1>{phone}</CoreTypographyBody1>
        </CoreStack>
      </CoreBox>

      <CoreAvatar
        variant="square"
        styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_XLARGE]}
        gridProps={{ gridSize: 4 }}
        src={clinicLogo}
      />
    </CoreGrid>
  );
}
