import {
  CoreAvatar,
  CoreClasses,
  __IconTypes,
  CoreGrid,
  CoreBox,
  CoreTypographyBody1,
  CoreIcon,
  CoreLink,
  CoreStack
} from "@wrappid/core";

/**
   * CustomIconText component.
   * @param {Object} props - The component props.
   * @param {*} props.textFontColor - The font color for the text.
   * @param {string} props.icon - The icon name.
   * @param {*} props.type - The icon type.
   * @param {string} props.href - The link URL.
   * @param {string} props.textContent - The text content.
   * @returns {JSX.Element} The CustomIconText component.
   */

function CustomIconText(props) {
  const { textFontColor, icon, type, href, textContent } = props;

  return(
    <CoreStack direction="row" spacing={1} styleClasses={[...textFontColor, CoreClasses.MARGIN.MB1]}>
      <CoreIcon icon={icon} color="inherit" type={type} />

      <CoreLink href={href} styleClasses={[...textFontColor]}>
        {textContent}
      </CoreLink>
    </CoreStack>
  );
}

export default function ClinicInfo(props) {
  const {
    Clinic,
    fullName,
    phone,
    addLine1,
    addLine2,
    country,
    state,
    district,
    city,
    pin,
    landmark,
  } = props;

  const clinicLogo = Clinic?.photoUrl;
  
  const addressComponents = [];
  
  if (addLine1) addressComponents.push(addLine1);
  if (addLine2) addressComponents.push(addLine2);
  if (city) addressComponents.push(city);
  if (district) addressComponents.push(district);
  if (state) addressComponents.push(state);
  if (pin) addressComponents.push(`Pin-${pin}`);
  if (country) addressComponents.push(country);
  if (landmark) addressComponents.push(`Landmark: ${landmark}`);

  if (addressComponents.length === 0 || addressComponents.some(component => component.trim() === "")) {
    addressComponents.push("Not Available");
  }

  const formattedAddress = addressComponents.join(", ");
  
  const googleMapsUrl = `https://www.google.com/maps/place/${encodeURIComponent(
    formattedAddress
  )}`;
  
  return (
    <>
      <CoreGrid styleClasses={[CoreClasses.PADDING.PL0]}>
        <CoreAvatar
          gridProps={{ gridSize: { md: 2, sm: 4, xs: 3 } }}  
          src={clinicLogo}
          styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_LARGE]}
        />
  
        <CoreBox gridProps={{ gridSize: { md: 10, sm: 8, xs: 9 } }} styleClasses={[CoreClasses.MARGIN.MR3, CoreClasses.MARGIN.ML3, CoreClasses.PADDING.PB1]}>
          <CoreTypographyBody1 styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_BOLD]}>{fullName}</CoreTypographyBody1>
  
          <CustomIconText
            icon="phone"
            type={__IconTypes.MATERIAL_OUTLINED_ICON}
            href={`tel:${phone}`}
            textContent={phone}
            textFontColor={[CoreClasses.COLOR.TEXT_BLACK]}
          />
  
          <CustomIconText
            icon="location_on"
            type={__IconTypes.MATERIAL_OUTLINED_ICON}
            href={googleMapsUrl}
            textContent={formattedAddress}
            textFontColor={[CoreClasses.COLOR.TEXT_BLACK]}
          />
  
        </CoreBox>
      </CoreGrid>
    </>
  );
}
  