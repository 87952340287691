export const GET_TOKEN_DETAIL_LOADING = "GET_TOKEN_DETAIL_LOADING";
export const GET_TOKEN_DETAIL_SUCCESS = "GET_TOKEN_DETAIL_SUCCESS";
export const GET_TOKEN_DETAIL_ERROR = "GET_TOKEN_DETAIL_ERROR";
export const GET_RX_CASH_SUCCESS = "GET_RX_CASH_SUCCESS";
export const GET_RX_CASH_ERROR = "GET_RX_CASH_ERROR";
export const RX_PAYMENT_VERIFY_SUCCESS = "RX_PAYMENT_VERIFY_SUCCESS";
export const RX_PAYMENT_VERIFY_ERROR = "RX_PAYMENT_VERIFY_ERROR";
export const START_PAYMENT_LOADING = "START_PAYMENT_LOADING";
export const START_PAYMENT_SUCCESS = "START_PAYMENT_SUCCESS";
export const START_PAYMENT_ERROR = "START_PAYMENT_ERROR";
export const GET_PAYMENTS_LOADING = "GET_PAYMENTS_LOADING";
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";
export const GET_PAYMENTS_ERROR = "GET_PAYMENTS_ERROR";
export const SET_PAYMENT_REDUCER = "SET_PAYMENT_REDUCER";
export const TOGGLE_RX_CASH = "TOGGLE_RX_CASH";
