import {
  CoreH6,
  CoreForm,
  FORM_EDIT_MODE,
  CoreBox,
  CoreGrid,
  CoreCard,
  CoreCardContent
} from "@wrappid/core";

export default function RxRecharge(props) {
  return (
    <CoreGrid>
      <CoreBox gridProps={{ gridSize: 2 }}></CoreBox>

      <CoreCard gridProps={{ gridSize: 8 }}>
        <CoreCardContent>
          <CoreH6>RX Recharge</CoreH6>

          <CoreForm
            formId={props.userFormId}
            mode={FORM_EDIT_MODE}
            allowDelete={false}
            preview={true}
          />
        </CoreCardContent>
      </CoreCard>
    </CoreGrid>
  );
}
