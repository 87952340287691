import {
  CoreBox,
  CoreCard,
  CoreCardContent,
  CoreCheckbox,
  CoreClasses,
  CoreGrid,
  CoreIcon,
  CoreIconButton,
  CoreInput,
  CoreLabel,
  CoreMedicineSelector,
  CoreTypographyBody1,
  CoreTypographyBody2,
  CoreTypographyCaption
} from "@wrappid/core";

export default function AddedMedicine({
  HandleChange,
  onClear,
  data,
  state,
  onEdit,
  selectprops,
  notEditable,
  onEditMedicineNote,
  getMedicineMask
}) {
  return data && data.length > 0 ? (
    data.map((medicine, i) =>
      state.editMed === medicine.id ? (
        <CoreMedicineSelector
          key={"med-sel-" + i}
          components={selectprops.components}
          styles={selectprops.customStyle}
          placeholder={selectprops.placeholder}
          onChange={selectprops.onChange}
          isMulti={selectprops.isMulti}
          loadOptions={selectprops.loadOptions}
          onKeyDown={selectprops.onKeyDown}
          defaultOptions={selectprops.defaultOptions}
          value={selectprops.value}
          inputValue={selectprops.inputValue}
          onInputChange={selectprops.onInputChange}
          onFinalAddMed={selectprops.onFinalAddMed}
          getMedicineMask={getMedicineMask}
        />
      ) : (
        <CoreCard
          key={"med-" + i}
          styleClasses={[CoreClasses.MARGIN.MB1]}
        >
          <CoreCardContent>
            <CoreGrid>
              <CoreBox gridProps={{ gridSize: 10 }}>
                <CoreBox
                  styleClasses={[CoreClasses.FLEX.DIRECTION_ROW]}
                >
                  <CoreCheckbox
                    disabled={notEditable}
                    onChange={() => {
                      HandleChange(medicine.id);
                    }}
                    key={"isGeneric-" + medicine.id}
                    id={"isGeneric-" + medicine.id}
                    checked={medicine.isGeneric}
                  />

                  <CoreLabel
                    styleClasses={[CoreClasses.VERTICAL_ALIGN.MIDDLE]}
                  >
                                        Allow Generic
                  </CoreLabel>
                </CoreBox>

                <CoreTypographyBody1>
                  {medicine.formulation.value +
                                        " " +
                                        medicine?.name?.label}
                </CoreTypographyBody1>

                <CoreTypographyCaption>
                  {
                    medicine?.name?.data
                      ?.ChemicalComposition?.name
                  }
                </CoreTypographyCaption>

                <CoreTypographyCaption>
                  {medicine?.quantity?.value +
                                        " - " +
                                        medicine.frequency?.value +
                                        " - " +
                                        medicine.meal?.value +
                                        " - " +
                                        medicine.durationCount?.value +
                                        medicine.durationType?.value}
                </CoreTypographyCaption>

                <CoreInput
                  id={"notes-" + i}
                  label={"Notes"}
                  onChange={onEditMedicineNote}
                  value={medicine?.notes}
                  readOnly={notEditable}
                />
              </CoreBox>

              {!notEditable && (
                <CoreBox
                  styleClasses={[
                    CoreClasses.FLEX.DIRECTION_ROW,
                    CoreClasses.ALIGNMENT
                      .JUSTIFY_CONTENT_FLEX_END
                  ]}
                  gridProps={{ gridSize: 2 }}
                >
                  <CoreIconButton
                    size="small"
                    onClick={() => {
                      onEdit(medicine.id);
                    }}
                  >
                    <CoreIcon>edit_note</CoreIcon>
                  </CoreIconButton>

                  <CoreIconButton
                    size="small"
                    onClick={() => {
                      onClear(medicine.id);
                    }}
                  >
                    <CoreIcon>delete_outline</CoreIcon>
                  </CoreIconButton>
                </CoreBox>
              )}
            </CoreGrid>
          </CoreCardContent>
        </CoreCard>
      )
    )
  ) : (
    <CoreTypographyBody2
      styleClasses={[CoreClasses.TEXT.TEXT_CENTER, CoreClasses.MARGIN.M1]}
    >
            No medicines Added
    </CoreTypographyBody2>
  );
}
