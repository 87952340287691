import { SET_MANAGE_ASSISTANT_REDUCER_SUCCESS } from "../types/manageAssistantTypes";

export const setmanageAssistantReducer = (data) => {
  return (dispatch) => {
    dispatch({
      data: data,
      type: SET_MANAGE_ASSISTANT_REDUCER_SUCCESS,
    });
  };
};

export const getname = (data) => {
  let name = "";

  if (data) {
    if (data.firstName) name = data.firstName;
    if (data.middleName) name = name + " " + data.middleName;
    if (data.lastName) name = name + " " + data.lastName;
  }
  return name;
};
