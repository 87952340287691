export const GET_SETTING_META_LOADING = "GET_SETTING_META_LOADING";
export const GET_SETTING_META_SUCCESS = "GET_SETTING_META_SUCCESS";
export const GET_SETTING_META_ERROR = "GET_SETTING_META_ERROR";

export const SETTINGS_MENU_LOADING = "SETTINGS_MENU_LOADING";
export const SETTINGS_MENU_SUCCESS = "SETTINGS_MENU_SUCCESS";
export const SETTINGS_MENU_ERROR = "SETTINGS_MENU_ERROR";

export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const CREATE_EMAILS_LOADING = "CREATE_EMAILS_LOADING";
export const CREATE_EMAILS_SUCCESS = "CREATE_EMAILS_SUCCESS";
export const CREATE_EMAILS_ERROR = "CREATE_EMAILS_ERROR";
export const GET_EMAILS_LOADING = "GET_EMAILS_LOADING";
export const GET_EMAILS_SUCCESS = "GET_EMAILS_SUCCESS";
export const GET_EMAILS_ERROR = "GET_EMAILS_ERROR";

export const DELETE_EMAILS_LOADING = "DELETE_EMAILS_LOADING";
export const DELETE_EMAILS_SUCCESS = "DELETE_EMAILS_SUCCESS";
export const DELETE_EMAILS_ERROR = "DELETE_EMAILS_ERROR";

export const CREATE_PHONES_LOADING = "CREATE_PHONES_LOADING";
export const CREATE_PHONES_SUCCESS = "CREATE_PHONES_SUCCESS";
export const CREATE_PHONES_ERROR = "CREATE_PHONES_ERROR";
export const GET_PHONES_LOADING = "GET_PHONES_LOADING";
export const GET_PHONES_SUCCESS = "GET_PHONES_SUCCESS";
export const GET_PHONES_ERROR = "GET_PHONES_ERROR";

export const DELETE_CONTACTS_LOADING = "DELETE_CONTACTS_LOADING";
export const DELETE_CONTACTS_SUCCESS = "DELETE_CONTACTS_SUCCESS";
export const DELETE_CONTACTS_ERROR = "DELETE_CONTACTS_ERROR";

export const CREATE_WAP_LOADING = "CREATE_WAP_LOADING";
export const CREATE_WAP_SUCCESS = "CREATE_WAP_SUCCESS";
export const CREATE_WAP_ERROR = "CREATE_WAP_ERROR";
export const GET_WAP_LOADING = "GET_WAP_LOADING";
export const GET_WAP_SUCCESS = "GET_WAP_SUCCESS";
export const GET_WAP_ERROR = "GET_WAP_ERROR";

export const CHANGE_PRIMARY_CONTACT_SUCCESS = "CHANGE_PRIMARY_CONTACT_SUCCESS";
export const CHANGE_PRIMARY_CONTACT_ERROR = "CHANGE_PRIMARY_CONTACT_ERROR";

export const OTP_SENT_LOADING = "OTP_SENT_LOADING";
export const OTP_SENT_SUCCESS = "OTP_SENT_SUCCESS";
export const OTP_SENT_ERROR = "OTP_SENT_ERROR";

export const OTP_VERIFY_LOADING = "OTP_VERIFY_LOADING";
export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_ERROR = "OTP_VERIFY_ERROR";

export const DELETE_USER_ACCOUNT_SUCCESS = "DELETE_USER_ACCOUNT_SUCCESS";
export const DELETE_USER_ACCOUNT_ERROR = "DELETE_USER_ACCOUNT_ERROR";

export const HANDLE_MODAL = "HANDLE_MODAL";

export const USER_SETTINGS_UPDATE_SUCCESS = "USER_SETTINGS_UPDATE_SUCCESS";
export const USER_SETTINGS_UPDATE_ERROR = "USER_SETTINGS_UPDATE_SUCCESS";

export const GET_USER_SETTINGS_LOADING = "GET_USER_SETTINGS_LOADING";
export const GET_USER_SETTINGS_SUCCESS = "GET_USER_SETTINGS_SUCCESS";
export const GET_USER_SETTINGS_ERROR = "GET_USER_SETTINGS_ERROR";
