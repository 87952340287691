import {
  CoreTypographyBody2,
  CoreTypographySubtitle2,
  CoreGrid
} from "@wrappid/core";
import moment from "moment";

export default function PrescriptionGenratedData({ prescription }) {
  return (
    <CoreGrid>
      <CoreTypographySubtitle2 gridProps={{ gridSize: { sm: 6 } }}>
        {"Assessment Date: "}

        <CoreTypographyBody2 component="span">
          {prescription?.isCompleted && prescription?.generationDate
            ? moment(prescription.generationDate)
              .add(60 * 5 + 30, "minutes")
              .format("DD/MM/YYYY hh:mm a")
            : prescription?.createdAt
              ? moment(prescription.createdAt)
                .add(60 * 5 + 30, "minutes")
                .format("DD/MM/YYYY hh:mm a")
              : "dd/mm/yyyy hh:mm am/pm"}
        </CoreTypographyBody2>
      </CoreTypographySubtitle2>

      <CoreTypographySubtitle2 gridProps={{ gridSize: { sm: 6 } }}>
        {"Assessment ID: "}

        <CoreTypographyBody2 component="span">
          {prescription?.assessmentId
            ? prescription.assessmentId
            : "##########"}
        </CoreTypographyBody2>
      </CoreTypographySubtitle2>
    </CoreGrid>
  );
}
