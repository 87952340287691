import {
  CoreTypographyCaption,
  CoreAsyncSelect,
  CoreGrid,
  CoreStack,
  CoreLink,
  CoreClasses
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import { ApiRegistry } from "../apis.registry";
import { RoutesRegistry } from "../routes.registry";
import { SET_CLINIC } from "../types/prescription.types";

export default function PrescriptionTopBar({
  // -- prescription,
  template,
  HandleTemplateChange,
  basic,
  // -- prescriptionClinic,
  // -- allClinics,
  language,
  onLanguageChange,
  allTemplates
}) {
  const dispatch = useDispatch();
  const {
    clinic = {},
    prescription,
    navData
  } = useSelector((state) => state.prescription);
  const personID = useSelector((state) => state?.profile?.basic?.id);

  return (
    <CoreGrid
      styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN, CoreClasses.ALIGNMENT.ALIGN_ITEMS_START]}
    >
      <CoreStack gridProps={{ gridSize: { sm: 4 } }}>
        <CoreAsyncSelect
          id="template"
          label="Choose Template"
          type="asyncSelect"
          endpoint={"/business/all/prescriptionTemplate"}
          query={{
            _defaultFilter: encodeURIComponent(
              JSON.stringify({
                or: {
                  name    : { like: "sys_gen_%" },
                  personId: personID
                }
              })
            )
          }}
          value={template}
          itemKey="prescriptionTemplates"
          asyncLoading={false}
          getOptionValue={(data) => {
            return data.map((tmp) => {
              return typeof tmp === "string"
                ? { label: tmp }
                : { id: tmp.id, label: tmp.labl };
            });
          }}
          getOptionLabel={(data) => {
            return data.label;
          }}
          isOptionEqualToValue={(option, value) => {
            if (
              typeof option === typeof value &&
                            typeof option === "string"
            ) {
              return option === value;
            } else {
              return option.id === value.id;
            }
          }}
          handleChange={HandleTemplateChange}
          readOnly={prescription?.id}
        />

        {template && (
          <CoreLink
            href={
              allTemplates &&
                            allTemplates?.find(
                              (tem) => tem.personId === personID
                            )
                ? "/" +
                                  RoutesRegistry.PRESCRIPTION_TEMPLATE_EDIT.replace(
                                    ":id",
                                    allTemplates?.find(
                                      (tem) => tem.personId === personID
                                    )?.id
                                  )
                : "/" +
                                  RoutesRegistry.PRESCRIPTION_TEMPLATE_EDIT.replace(
                                    ":id",
                                    template?.id
                                  )
            }
          >
            <CoreTypographyCaption>
                            Edit Template
            </CoreTypographyCaption>
          </CoreLink>
        )}
      </CoreStack>

      <CoreStack gridProps={{ gridSize: { sm: 4 } }}>
        <CoreAsyncSelect
          id="prescription-clinic"
          label={"Choose clinic"}
          type="asyncSelect"
          endpoint={"/business/all/ProfileClinic"}
          query={{
            _defaultFilter: encodeURIComponent(
              JSON.stringify({ personId: basic?.id })
            )
          }}
          itemKey={"clinics"}
          freeSolo={false}
          onChangeDispatch={{ type: SET_CLINIC }}
          getOptionValue={(data) => {
            return data;
          }}
          getOptionLabel={(data) => {
            return data.fullName;
          }}
          isOptionsEqualToValue={(option, value) => {
            return option.id === value.id;
          }}
          value={clinic}
          asyncLoading={false}
          handleChange={(data) => {
            dispatch({ payload: data, type: SET_CLINIC });
          }}
          readOnly={prescription?.id || navData?.appointment?.id}
        />
      </CoreStack>

      <CoreAsyncSelect
        gridProps={{ gridSize: { sm: 4 } }}
        id="language"
        label="Change Language"
        type="asyncSelect"
        endpoint={ApiRegistry.GET_SUPPORTED_LANGUAGES}
        query={{}}
        value={language}
        itemKey="language"
        asyncLoading={false}
        handleChange={onLanguageChange}
      />
    </CoreGrid>
  );
}
