import {
  CoreTypographyBody1,
  CoreAvatar,
  CoreGrid,
  CoreBox,
  CoreClasses,
  __IconTypes,
  CoreTypographyCaption,
  getAge,
  CoreIconText
} from "@wrappid/core";

const DoctorCard = ({ data }) => {
  const {
    "Person.User.email": email,
    "Person.User.phone": phone,
    "Person.firstName": firstName,
    "Person.middleName": middleName,
    "Person.lastName": lastName,
    "Person.photoUrl": photoUrl,
    "Person.dob": dob,
  } = data || {};

  return (
    <CoreGrid styleClasses={[CoreClasses.PADDING.P0]}>
      <CoreBox
        gridProps={{ gridSize: 2 }}
        styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}
      >
        <CoreAvatar
          styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_LARGE]}
          src={photoUrl || "photo.jpg"}
        />
      </CoreBox>

      <CoreBox gridProps={{ gridSize: 10 }}>
        <CoreTypographyBody1
          styleClasses={[CoreClasses.TEXT.TEXT_BOLD]}
        >
          {`${firstName || ""} ${middleName || ""} ${lastName || ""}`}
        </CoreTypographyBody1>

        <CoreTypographyCaption styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
          <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon="cake" text={getAge(dob)} />
        </CoreTypographyCaption>

        <CoreTypographyCaption styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
          <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon="email" text={email} />
        </CoreTypographyCaption>

        <CoreTypographyCaption styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
          <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon="phone" text={phone} />
        </CoreTypographyCaption>
      </CoreBox>
    </CoreGrid>
  );
};

export default DoctorCard;
