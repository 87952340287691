import {
  CoreAvatar,
  CoreClasses,
  __IconTypes,
  CoreGrid,
  CoreBox,
  CoreTypographyBody1,
  CoreTypographyCaption,
  CoreIcon,
  CoreLink,
  CoreStack,
  StatusText
} from "@wrappid/core";

/**
 * CustomIconText component.
 * @param {Object} props - The component props.
 * @param {*} props.textFontColor - The font color for the text.
 * @param {string} props.icon - The icon name.
 * @param {*} props.type - The icon type.
 * @param {string} props.href - The link URL.
 * @param {string} props.textContent - The text content.
 * @returns {JSX.Element} The CustomIconText component.
 */

function CustomIconText(props) {
  const { textFontColor, icon, type, href, textContent } = props;

  return(
    <CoreStack direction="row" spacing={1} styleClasses={[...textFontColor, CoreClasses.MARGIN.MB1]}>
      <CoreIcon icon={icon} color="inherit" type={type} />

      <CoreLink href={href} styleClasses={[...textFontColor]}>
        {textContent}
      </CoreLink>
    </CoreStack>
  );
}

export default function ClinicSummaryComponent(props) {

  const NOT_AVAILABLE = "Not Available";
  const rowData = props?.rowData || {};
  const fullName = rowData?.fullName || NOT_AVAILABLE;
  const phone = rowData?.phone || NOT_AVAILABLE;

  const { photoUrl } = props.rowData.Clinic;

  return (
    <>
      <CoreGrid styleClasses={[CoreClasses.PADDING.PL0]}>
        <CoreAvatar
          gridProps={{ gridSize: { md: 2, sm: 4, xs: 3 } }}  
          src={photoUrl}
          styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_LARGE]}
        />

        <CoreBox gridProps={{ gridSize: { md: 10, sm: 8, xs: 9 } }} styleClasses={[CoreClasses.MARGIN.MR3, CoreClasses.MARGIN.ML3, CoreClasses.PADDING.PB1]}>
          <CoreTypographyBody1 styleClasses={[]}>{fullName}</CoreTypographyBody1>

          <CustomIconText
            icon="phone"
            type={__IconTypes.MATERIAL_OUTLINED_ICON}
            textContent={phone}
            textFontColor={[CoreClasses.COLOR.TEXT_BLACK]}
          />

        </CoreBox>
      </CoreGrid>

      <CoreStack
        direction="row"
        spacing={1}
        styleClasses={[CoreClasses.FLEX.DIRECTION_ROW, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}>
        <CoreTypographyCaption>{"ID: " + rowData["id"]}</CoreTypographyCaption>

        <CoreTypographyCaption
          styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY_DARK]}>
          {"|"}
        </CoreTypographyCaption>

        <StatusText status={rowData["_status"]} />

        <CoreTypographyCaption
          styleClasses={[
            // -- getStatusTextColorClass(rowData["_status"]),
            CoreClasses.TEXT.TEXT_WEIGHT_BOLD, CoreClasses.TEXT.TEXT_UPPERCASE,
          ]}>
          {/* -- {getLabel(rowData["_status"])} */}
        </CoreTypographyCaption>
      </CoreStack>
    </>
  );
}
