/* eslint-disable no-unused-vars */
import { CoreStack, CoreTypographyCaption, CoreClasses, CoreEmailLink, CorePhoneLink } from "@wrappid/core";

export default function PatientContactInfo(props) {
  const { rowData } = props || {};

  return (
    <>
      <CoreStack
        direction="row"
        styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}
      >
        <CoreTypographyCaption styleClasses={[CoreClasses.MARGIN.MB0]}>
          {"Email:"}&nbsp;
        </CoreTypographyCaption>

        <CoreEmailLink email={rowData["RelatedPerson.User.email"]} size="small" />
      </CoreStack>

      <CoreStack
        direction="row"
        styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}
      >
        <CoreTypographyCaption styleClasses={[CoreClasses.MARGIN.MB0]}>
          {"Phone:"}&nbsp;
        </CoreTypographyCaption>

        <CorePhoneLink phone={rowData["RelatedPerson.User.phone"]} size="small" />
      </CoreStack>
    </>
  );
}
