/* eslint-disable no-unused-vars */
import { useEffect } from "react";

import { useSelector } from "react-redux";

function BackofficeDashboard() {
  // state = {};
  //use useState() instead of state

  const auth = useSelector((state) => state.auth); // --- auth, navData
  const common = useSelector((state) => state.common); // --- rolePermissions, getRolePermissionLoading, getRolePermissionSuccess

  // ---  componentDidMount = () => {};
  useEffect(() => {}, []);

  return <div className="row center center-align"></div>;
}

export default BackofficeDashboard;
