import { COUNTER_WIDGET_GET_ERROR, COUNTER_WIDGET_GET_SUCCESS, COUNTER_WIDGET_INIT, COUNTER_WIDGET_RESET, LOGOUT_SUCCESS } from "../types/widgetTypes";
const initialState = { counter: {} };

const widgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case COUNTER_WIDGET_INIT:
      return {
        ...state,
        counter: {
          ...state.counter,
          [action.payload.widgetId]: {
            counterInitState: true,
            ...action.payload.data
          },
        },
      };

    case COUNTER_WIDGET_GET_SUCCESS:
      if (action?.payload?.primary) {
        return {
          ...state,
          counter: {
            ...state.counter,
            [action.payload.widgetId]: {
              ...state.counter[action.payload.widgetId],
              counterInitState: (!state.counter[action?.payload?.widgetId]?.primaryCounter?.count >= 0
                && !state.counter[action?.payload?.widgetId]?.otherCounters?.filter(eachCounter => {
                  return eachCounter?.count >= 0;
                }).length === state.counter[action?.payload?.widgetId]?.otherCounters.length
              ),
              primaryCounter: {
                ...state.counter[action.payload.widgetId].primaryCounter,
                apiError               : false,
                apiSuccess             : true,
                count                  : action?.payload?.data?.count,
                primaryCounterInitState: false
              }
            },
          }
        };
      } else {
        let otherCounters = state.counter[action.payload?.widgetId]?.otherCounters;

        otherCounters?.forEach(eachCounter => {
          if (eachCounter?.id === action?.payload?.widgetEntityId) {
            eachCounter.apiError = false;
            eachCounter.apiSuccess = true;
            eachCounter.count = action.payload?.data?.count;
          }
        });

        return {
          ...state,
          counter: {
            ...state.counter,
            [action.payload.widgetId]: {
              ...state.counter[action.payload.widgetId],

              otherCounters
            },
          }
        };
      }

    case COUNTER_WIDGET_GET_ERROR:
      // eslint-disable-next-line no-console
      console.log(action?.payload);
      return state;

    case COUNTER_WIDGET_RESET:
      return initialState;

    case LOGOUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default widgetReducer;
