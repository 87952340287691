
import {
  CoreClasses,
  CoreTypographyBody2
} from "@wrappid/core";
export default function MedicalBackground() {

  return ( <CoreTypographyBody2
    styleClasses={[CoreClasses?.TEXT?.TEXT_CENTER, CoreClasses?.PADDING?.P1]}>
     Subcription Needed
  </CoreTypographyBody2>
  );
}
