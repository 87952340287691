import { ValidationsRegistry as AppBuilderValidationsRegistry } from "./app-builder/validations.registry";
import { ValidationsRegistry as AppointmentValidationsRegistry } from "./appointment/validations.registry";
import { ValidationsRegistry as AssistantValidationsRegistry } from "./assistant/validations.registry";
import { ValidationsRegistry as AuthValidationsRegistry } from "./auth/validations.registry";
import { ValidationsRegistry as ClinicValidationsRegistry } from "./clinic/validations.registry";
import { ValidationsRegistry as DashboardValidationsRegistry } from "./dashboard/validations.registry";
import { ValidationsRegistry as DataManagementValidationsRegistry } from "./data-management/validations.registry";
import { ValidationsRegistry as GuideValidationsRegistry } from "./guide/validations.registry";
import { ValidationsRegistry as PatientValidationsRegistry } from "./patient/validations.registry";
import { ValidationsRegistry as PaymentValidationsRegistry } from "./payment/validations.registry";
import { ValidationsRegistry as PrescriptionValidationsRegistry } from "./prescription/validations.registry";
import { ValidationsRegistry as ProfileValidationsRegistry } from "./profile/validations.registry";
import { ValidationsRegistry as SettingsValidationsRegistry } from "./settings/validations.registry";
import { ValidationsRegistry as UserManagementValidationsRegistry } from "./user-management/validations.registry";

export default {
...AppBuilderValidationsRegistry,
...AppointmentValidationsRegistry,
...AssistantValidationsRegistry,
...AuthValidationsRegistry,
...ClinicValidationsRegistry,
...DashboardValidationsRegistry,
...DataManagementValidationsRegistry,
...GuideValidationsRegistry,
...PatientValidationsRegistry,
...PaymentValidationsRegistry,
...PrescriptionValidationsRegistry,
...ProfileValidationsRegistry,
...SettingsValidationsRegistry,
...UserManagementValidationsRegistry,
}