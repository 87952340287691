import AppointedAssistants from "./components/AppointedAssistants";
import AssistantCard from "./components/AssistantCard";
import CreateAndAppointAssistant from "./components/CreateAndAppointAssistant";
import DoctorCard from "./components/DoctorCard";
import ManageAssistant from "./components/ManageAssistant";
import ManageAssistantInvitation from "./components/ManageAssistantInvitation";
import PendingAssistantInvitation from "./components/PendingAssistantInvitation";
import SearchAssistantCard from "./components/SearchAssistantCard";
import SearchUser from "./components/SearchUser";
import UserChip from "./components/UserChip";

export const ComponentsRegistry = {
  AppointedAssistants       : { comp: AppointedAssistants },
  AssistantCard             : { comp: AssistantCard },
  CreateAndAppointAssistant : { comp: CreateAndAppointAssistant },
  DoctorCard                : { comp: DoctorCard },
  ManageAssistant           : { comp: ManageAssistant },
  ManageAssistantInvitation : { comp: ManageAssistantInvitation },
  PendingAssistantInvitation: { comp: PendingAssistantInvitation },
  SearchAssistantCard       : { comp: SearchAssistantCard },
  SearchUser                : { comp: SearchUser },
  UserChip                  : { comp: UserChip }
};