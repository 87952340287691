import { useEffect, useState } from "react";

import { CoreForm, FORM_VIEW_MODE } from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import CreatePatientRelative from "./CreatePatientRelative";
import { SanReadPatient } from "../functions/sanity.functions";
// eslint-disable-next-line etc/no-commented-out-code
// import { RESET_PATIENT } from "../types/patient.types";

export default function UpdatePatientForm(props) {
  const dispatch = useDispatch();
  let { data = {} } = props;
  const [formMode, setFormMode] = useState(FORM_VIEW_MODE);

  //commented for testing exit edit form on successful save.
  // eslint-disable-next-line etc/no-commented-out-code
  // const { setDialog } = useContext(CoreDialogContext);

  //commented for testing exit edit form on successful save.
  // eslint-disable-next-line etc/no-commented-out-code
  // const editPatientSuccess = useSelector(
  //   state => state?.patient?.editPatientSuccess
  // );
  // const editPatientError = useSelector(
  //   state => state?.patient?.editPatientError
  // );
  const { relativeFormVisible } = useSelector(state => state?.patient);

  useEffect(() => {
    if (data) {
      dispatch({
        payload: {
          data  : { data: data },
          formId: data["RelatedPerson.userId"] === "" ? "updatePatientRelative" : "updatePatient",
        },
        type: "FORM_INIT_UPDATE",
      });
    }
  }, [data]);

  //Commented for testing exit edit form on successful save.
  // useEffect(() => {
  //   if (editPatientSuccess) {
  //     setDialog({
  //       cancelButton: () => {
  //         setFormMode(FORM_VIEW_MODE);
  //         dispatch({
  //           payload: { entity: "MyPatient" },
  //           type   : "RESET_FILTER_DATA",
  //         });
  //         dispatch({ type: RESET_PATIENT });
  //       },
  //       doneButton: () => {
  //         setFormMode(FORM_VIEW_MODE);
  //         dispatch({
  //           payload: { entity: "MyPatient" },
  //           type   : "RESET_FILTER_DATA",
  //         });
  //         dispatch({ type: RESET_PATIENT });
          
  //       },
  //       showDialog: true,
  //       subtitle  : "Patient details updated successfully.",
  //       title     : "Success",
  //     });
  //   }

  //   if (editPatientError) {
  //     setDialog({
  //       cancelButton: () => {
  //         dispatch({ type: RESET_PATIENT });
  //       },
  //       doneButton: () => {
  //         dispatch({ type: RESET_PATIENT });
  //       },
  //       showDialog: true,
  //       subtitle  : "Patient details not be updated.",
  //       title     : "Error",
  //     });
  //   }
  // }, [editPatientSuccess, editPatientError]);

  return (
    relativeFormVisible ? (
      <CreatePatientRelative />
    ) : 
      <CoreForm
        apiMode={"edit"}
        onMountRead={false}
        formId={data["RelatedPerson.userId"] === "" ? "updatePatientRelative" : "updatePatient"}
        mode={formMode}
        initData={{ ...SanReadPatient(data), ...data }}
        afterCancel={() => {
          setFormMode(FORM_VIEW_MODE);
        }}
        afterEditSuccess={() => {
          setFormMode(FORM_VIEW_MODE);
          dispatch({
            payload: { entity: "MyPatient" },
            type   : "UPDATE_QUERY_SEARCH_VALUE_DATA",
          });
        }}
        afterDeleteSuccess={() => {
          setFormMode(FORM_VIEW_MODE);
          dispatch({
            payload: { entity: "MyPatient" },
            type   : "UPDATE_QUERY_SEARCH_VALUE_DATA",
          });
        }}
        afterEditError={() => {
        }}
        afterDeleteError={() => {
        }}
        deleteId={data["RelatedPerson.id"]}
      />
      
  );
}