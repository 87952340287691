export const FORM_IDS = {
  __PRESCRIPTION_CLINIC         : "prescriptionClinic",
  __PRESCRIPTION_COMPLAINTS     : "prescriptionComplaints",
  __PRESCRIPTION_DIAGNOSIS      : "prescriptionDiagnosis",
  __PRESCRIPTION_DOCTOR_PROFILE : "prescriptionDoctorProfile",
  __PRESCRIPTION_FOLLOWUPS      : "prescriptionFollowups",
  __PRESCRIPTION_GENERATE       : "prescriptionGenerate",
  __PRESCRIPTION_GUIDELINES     : "prescriptionGuidelines",
  __PRESCRIPTION_HISTORIES      : "prescriptionHistory",
  __PRESCRIPTION_INIT           : "prescriptionInit",
  __PRESCRIPTION_MEDICINE       : "prescriptionMedicine",
  __PRESCRIPTION_PATIENT_PROFILE: "prescriptionPatientProfile",
  __PRESCRIPTION_PROCEDURES     : "prescriptionProcedures",
  __PRESCRIPTION_REFFERS        : "prescriptionRefferedTo",
  __PRESCRIPTION_VITALS         : "prescriptionVitals" 
};