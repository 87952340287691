import { ResourcesRegistry as AppBuilderResourcesRegistry } from "./app-builder/resources.registry";
import { ResourcesRegistry as AppointmentResourcesRegistry } from "./appointment/resources.registry";
import { ResourcesRegistry as AssistantResourcesRegistry } from "./assistant/resources.registry";
import { ResourcesRegistry as AuthResourcesRegistry } from "./auth/resources.registry";
import { ResourcesRegistry as ClinicResourcesRegistry } from "./clinic/resources.registry";
import { ResourcesRegistry as DashboardResourcesRegistry } from "./dashboard/resources.registry";
import { ResourcesRegistry as DataManagementResourcesRegistry } from "./data-management/resources.registry";
import { ResourcesRegistry as GuideResourcesRegistry } from "./guide/resources.registry";
import { ResourcesRegistry as PatientResourcesRegistry } from "./patient/resources.registry";
import { ResourcesRegistry as PaymentResourcesRegistry } from "./payment/resources.registry";
import { ResourcesRegistry as PrescriptionResourcesRegistry } from "./prescription/resources.registry";
import { ResourcesRegistry as SettingsResourcesRegistry } from "./settings/resources.registry";
import { ResourcesRegistry as UserManagementResourcesRegistry } from "./user-management/resources.registry";

export default {
...AppBuilderResourcesRegistry,
...AppointmentResourcesRegistry,
...AssistantResourcesRegistry,
...AuthResourcesRegistry,
...ClinicResourcesRegistry,
...DashboardResourcesRegistry,
...DataManagementResourcesRegistry,
...GuideResourcesRegistry,
...PatientResourcesRegistry,
...PaymentResourcesRegistry,
...PrescriptionResourcesRegistry,
...SettingsResourcesRegistry,
...UserManagementResourcesRegistry,
}