export const MASTER_DATA_LOADING = "MASTER_DATA_LOADING";
export const MASTER_DATA_SUCCESS = "MASTER_DATA_SUCCESS";
export const MASTER_DATA_ERROR = "MASTER_DATA_ERROR";

export const MAIN_MASTER_DATA_LOADING = "MAIN_MASTER_DATA_LOADING";
export const MAIN_MASTER_DATA_SUCCESS = "MAIN_MASTER_DATA_SUCCESS";
export const MAIN_MASTER_DATA_ERROR = "MAIN_MASTER_DATA_ERROR";

export const GET_DEPT_LOADING = "GET_DEPT_LOADING";
export const GET_DEPT_SUCCESS = "GET_DEPT_SUCCESS";
export const GET_DEPT_ERROR = "GET_DEPT_ERROR";

export const SUPPORTED_LANGUAGE_LOADING = "SUPPORTED_LANGUAGE_LOADING";
export const SUPPORTED_LANGUAGE_SUCCESS = "SUPPORTED_LANGUAGE_SUCCESS";
export const SUPPORTED_LANGUAGE_ERROR = "SUPPORTED_LANGUAGE_ERROR";

export const SET_BASE_CHEMICAL = "SET_BASE_CHEMICAL";
export const __SET_ROLE = "__SET_ROLE";
