import { ReducersRegistry as AppBuilderReducersRegistry } from "./app-builder/reducers.registry";
import { ReducersRegistry as AppointmentReducersRegistry } from "./appointment/reducers.registry";
import { ReducersRegistry as AssistantReducersRegistry } from "./assistant/reducers.registry";
import { ReducersRegistry as AuthReducersRegistry } from "./auth/reducers.registry";
import { ReducersRegistry as ClinicReducersRegistry } from "./clinic/reducers.registry";
import { ReducersRegistry as DashboardReducersRegistry } from "./dashboard/reducers.registry";
import { ReducersRegistry as DataManagementReducersRegistry } from "./data-management/reducers.registry";
import { ReducersRegistry as GuideReducersRegistry } from "./guide/reducers.registry";
import { ReducersRegistry as PatientReducersRegistry } from "./patient/reducers.registry";
import { ReducersRegistry as PaymentReducersRegistry } from "./payment/reducers.registry";
import { ReducersRegistry as PrescriptionReducersRegistry } from "./prescription/reducers.registry";
import { ReducersRegistry as ProfileReducersRegistry } from "./profile/reducers.registry";
import { ReducersRegistry as SettingsReducersRegistry } from "./settings/reducers.registry";
import { ReducersRegistry as UserManagementReducersRegistry } from "./user-management/reducers.registry";

export default {
...AppBuilderReducersRegistry,
...AppointmentReducersRegistry,
...AssistantReducersRegistry,
...AuthReducersRegistry,
...ClinicReducersRegistry,
...DashboardReducersRegistry,
...DataManagementReducersRegistry,
...GuideReducersRegistry,
...PatientReducersRegistry,
...PaymentReducersRegistry,
...PrescriptionReducersRegistry,
...ProfileReducersRegistry,
...SettingsReducersRegistry,
...UserManagementReducersRegistry,
}