import modulesResourcesRegistry from "../modules/modules.resources.registry";

export const ResourcesRegistry = {
  appLoaderGif  : require("../resources/images/loader.gif"),
  appLogo       : require("../resources/images/logo.png"),
  appLogoDark   : require("../resources/images/logo_dark.png"),
  appLogoLight  : require("../resources/images/logo_light.png"),
  authBackground: require("../resources/images/background.png"),
  ...modulesResourcesRegistry 
};
