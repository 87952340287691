import React from "react";

import {
  CoreClasses,
  CoreTypographyBody1,
  CoreGrid,
  CoreStack,
  CoreForm,
  FORM_EDIT_MODE,
  CoreLayoutItem,
  AppContainerLayout,
  CoreFunctionsRegistryContext
} from "@wrappid/core";
import { useSelector } from "react-redux";

import PatientCard from "./PatientCard";
import SearchUser from "./SearchUser";

export default function CreateAppointment() {
  const functionRegistry = React.useContext(CoreFunctionsRegistryContext);

  const profile = useSelector((state) => state.profile);

  // eslint-disable-next-line etc/no-commented-out-code
  // const appointment = useSelector((state) => state.appointment);

  const patient = useSelector((state) => state?.appointment?.patient);

  // //create appointment hook
  // React.useEffect(() => {
  //   if (createAppoinmentError) {
  //     if (createAppoinmentIsWarning) {
  //       setDialog({
  //         cancelButton: () => {},
  //         doneButton  : () => {
  //           dispatch(
  //             apiRequestAction(
  //               HTTP.POST,
  //               "/appointment/schedule",
  //               true,
  //               {
  //                 ...createAppointmentOldData,
  //                 clinicId:
  //                   Number(createAppointmentOldData?.clinicId) === 0
  //                     ? null
  //                     : Number(createAppointmentOldData?.clinicId),
  //                 doctorId : profile?.basic?.id,
  //                 isForce  : true,
  //                 patientId: patient
  //                   ? patient?.Person
  //                     ? patient?.Person?.id
  //                     : patient?.Person?.id
  //                   : null,
  //               },
  //               CREATE_APPOINTMENT_SUCCESS,
  //               CREATE_APPOINTMENT_ERROR
  //             )
  //           );
  //         },
  //         doneButtonLabel: "Force Create",
  //         showDialog     : true,
  //         subtitle       : createAppoinmentMsg || "Something went wrong",
  //         title          : "Warning!",
  //         type           : "info"
  //       });
  //     } else {
  //       setDialog({
  //         cancelButton: () => {},
  //         doneButton  : () => {
  //           dispatch({
  //             data: { createAppoinmentError: false },
  //             type: SET_APPOINTMENT_REDUCER,
  //           });
  //         },
  //         showDialog: true,
  //         subtitle  : createAppoinmentMsg || "Something went wrong",
  //         title     : "Warning!",
  //         type      : "error"
  //       });
  //     }
  //     dispatch({
  //       payload: { createAppoinmentError: false },
  //       type   : SET_APPOINTMENT_REDUCER_VALUE,
  //     });
  //   }

  //   if (createAppoinmentSuccess) {
  //     setDialog({
  //       cancelButton: () => {},
  //       doneButton  : () => {
  //         dispatch({
  //           data: { createAppoinmentSuccess: false },
  //           type: SET_APPOINTMENT_REDUCER,
  //         });
  //         navigate(`/${ApiRegistry.APPOINTMENT_MANAGE}`);
  //       },
  //       showDialog: true,
  //       subtitle  : createAppoinmentMsg || "Appointment created successfully",
  //       title     : "Success!",
  //     });
  //   }
  // }, [
  //   createAppoinmentSuccess,
  //   createAppoinmentError,
  //   createAppoinmentIsWarning,
  //   createAppoinmentMsg,
  //   createAppointmentOldData,
  //   patient,
  // ]);

  // -- React.useEffect(() => {
  //   if(editAppointmentSuccess) {
  //     setDialog({
  //       cancelButton: () => {},
  //       doneButton  : () => {},
  //       showDialog  : true,
  //       subtitle    : editAppointmentMsg || "Appointment Edited Successfully",
  //       title       : "Success!",
  //     });
  //   }
  // }, [editAppointmentSuccess]);

  // -- React.useEffect(() => {
  //   if(appointmentStatusError) {
  //     setDialog({
  //       cancelButton: () => {},
  //       doneButton  : () => {},
  //       showDialog  : true,
  //       subtitle    : getAppointmentMessage || "Error to update appointment status",
  //       title       : "Oops!",
  //     });
  //   }
  // }, [appointmentStatusError]);

  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <CoreGrid
          // styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER]}
          styleClasses={[CoreClasses.LAYOUT.FULL_WIDTH]}
        >
          <CoreStack gridProps={{ gridSize: { sm: 12 } }}>
            <CoreTypographyBody1>Create Appointment</CoreTypographyBody1>

            {patient ? (
              <>
                <PatientCard patient={patient} />

                <CoreForm
                  apiMode="create"
                  formId="createAppointment"
                  mode={FORM_EDIT_MODE}
                  initProps={{ clinicId: { query: { _defaultFilter: encodeURIComponent(JSON.stringify({ personId: profile?.basic?.id })) } } }
                  }
                />
              </>
            ) : (
              <SearchUser
                label="Search User"
                query={{ input: "a", role: "patient" }}
                itemKey="existingUsers"
                endpoint="/userSearchPaginated"
                gridSize={12}
                creatable={true}
                optionComp="searchUserCard"
                navigateUrl="/create_patient"
                getOptionLabel={functionRegistry.__SEARCHPATIENT_GET_OPTION_LABEL}
                // getOptionLabel={(data) => {
                //   return JSON.stringify(data);
                // }}
                getOptionValue={functionRegistry.__SEARCHPATIENT_GET_OPTION_VALUE}
                optionCompProps={{ role: "patient" }}
                onChangeDispatch="SET_APPOINTMENT_PATIENT"
                isOptionsEqualToValue={functionRegistry.__SEARCHPATIENT_IS_OPTIONS_EQUAL_TO_VALUE}
              />
            )}

            {/* -- <CoreForm formId="searchPatient" mode={FORM_EDIT_MODE} /> */}
          </CoreStack>
        </CoreGrid>
      </CoreLayoutItem>
    </>

  );
}
