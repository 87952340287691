import {
  CoreTypographyBody1,
  CoreDataTable,
  CoreClasses,
  HTTP,
  apiRequestAction,
  CoreStack,
  CoreCardHeader,
  CoreAvatar,
  CoreTypographyCaption,
  CoreBox,
  CoreDivider,
  getAge,
  __IconTypes,
  CoreIconText,
  __EntityStatus
} from "@wrappid/core";
import { useSelector, useDispatch } from "react-redux";

import AssistantCard from "./AssistantCard";
import SearchUser from "./SearchUser";
import UserChip from "./UserChip";
import { APP_ROLES } from "../constants/constants";
import {
  SENT_ASSISTANT_INVITATION_ERROR,
  SENT_ASSISTANT_INVITATION_SUCCESS,
  ASSISTANT_TERMINATION_SUCCESS,
  ASSISTANT_TERMINATION_ERROR
} from "../types/manageAssistantTypes";

export default function AppointedAssistants() {
  const dispatch = useDispatch();
  const doctorID = useSelector((state) => state?.profile?.basic?.id);
  const sentInvitation = (assistant) => {
    // eslint-disable-next-line no-console
    console.log(assistant);
    dispatch(
      apiRequestAction(
        HTTP.POST,
        "/assistant/sendInvitation",
        true,
        {
          personId       : doctorID,
          relatedPersonId: assistant.id,
        },
        SENT_ASSISTANT_INVITATION_SUCCESS,
        SENT_ASSISTANT_INVITATION_ERROR
      )
    );
  };
  const tableColumns = [
    {
      id   : "id",
      label: "Appt. ID",
      type : "INTEGER",
    },
    {
      id    : "RelatedPerson.firstName",
      label : "Assistant",
      render: (cellData, rowData) => {
        return (
          <>
            <UserChip
              _firstName={rowData["RelatedPerson.firstName"]}
              _middleName={rowData["RelatedPerson.middleName"]}
              _lastName={rowData["RelatedPerson.lastName"]}
              _photoUrl={rowData["RelatedPerson.photoUrl"]}
            />
          </>
        );
      },
      type: "STRING",
    },
    {
      id   : "RelatedPerson.User.email",
      label: "Email",
      type : "STRING",
    },
    {
      id   : "RelatedPerson.User.phone",
      label: "Phone",
      type : "STRING",
    },
    {
      id   : "_status",
      label: "Status",
      type : "STRING",
    },
  ];

  const preRender_CreateData_DetailsPaneComponent = () => {
    return (
      <>
        <SearchUser role={APP_ROLES.ASSISTANT} handleChange={sentInvitation} />

        <CoreTypographyBody1
          styleClasses={[CoreClasses.TEXT.TEXT_CENTER, CoreClasses.MARGIN.MB4]}
        >
          Or
        </CoreTypographyBody1>
      </>
    );
  };

  const summaryComponent = ({ rowData }) => {
    return (
      <CoreStack direction="column">
        <CoreCardHeader
          styleClasses={[CoreClasses.PADDING.P0]}
          avatar={
            <CoreAvatar
              styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_MEDIUM]}
              src={rowData["RelatedPerson.photoUrl"] || "photo.jpg"}
            />
          }
          title={[rowData["RelatedPerson.firstName"], rowData["RelatedPerson.middleName"], rowData["RelatedPerson.lastName"]].join(" ")}
          subheader={
            <>
              <CoreBox>
                <CoreTypographyCaption>{`MRN: ${rowData["RelatedPerson.profileId"] || "N/A"
                }`}</CoreTypographyCaption>
              </CoreBox>

              {/* {getLabel(rowData["RelatedPerson.gender"] || "N/A") +
                ", " + */}
              <CoreStack
                direction="row"
                spacing={1}
              >
                <CoreTypographyCaption styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
                  <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon="cake" text={getAge(rowData["RelatedPerson.dob"])} />
                </CoreTypographyCaption>

                <CoreDivider orientation="vertical" variant="middle" flexItem />

                <CoreTypographyCaption styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
                  <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon={(rowData["RelatedPerson.gender"])?.toLowerCase()} text={(rowData["RelatedPerson.gender"])} />
                </CoreTypographyCaption>
              </CoreStack>
            </>
          }
        />

        <CoreDivider />

        <CoreStack
          direction="row"
          spacing={1}
          styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}
        >
          <CoreTypographyCaption>
            {"ID: " + rowData["id"]}
          </CoreTypographyCaption>

          <CoreDivider orientation="vertical" variant="middle" flexItem />

          <CoreTypographyCaption
            styleClasses={[
              // -- getStatusTextColorClass(rowData["_status"]),
              CoreClasses.TEXT.TEXT_WEIGHT_BOLD, CoreClasses.TEXT.TEXT_UPPERCASE,
            ]}
          >
            {/* -- {getLabel(rowData["_status"])} */}
          </CoreTypographyCaption>
        </CoreStack>
      </CoreStack>
    );
  };

  const tableRowActions =  [
    {
      action: (data) => {
        dispatch(
          apiRequestAction(
            HTTP.PUT,
            `/terminate/${data?.relatedPersonId}/${data?.personId}`,
            true,
            {},
            ASSISTANT_TERMINATION_SUCCESS,
            ASSISTANT_TERMINATION_ERROR
          )
        );
      },
      hide: (data) => {
        if (data._status !== __EntityStatus.ACTIVE) {
          return true;
        } else {
          return false;
        }
      },
      icon : "groupAdd",
      label: "Terminate",
      type : "action",
    },
  ];

  const preRender_UpdateData_DetailsPaneComponent = ({ data }) => {
    return (
      <CoreTypographyBody1>
        <AssistantCard data={data} />
      </CoreTypographyBody1>);
  };

  return (
    <CoreDataTable
      entity={"Assistant"}
      createFormID="createAssistant"
      filterQuery={{ filter: { personId: doctorID } }}
      columns={tableColumns}
      rowActions={tableRowActions}
      hideUpdateForm={true}
      preRender_CreateData_DetailsPaneComponent={
        preRender_CreateData_DetailsPaneComponent
      }
      preRender_UpdateData_DetailsPaneComponent={
        preRender_UpdateData_DetailsPaneComponent
      }
      summaryRendererComponent={summaryComponent}
    />
  );
}
