// asyncSelect and formSubmitSanitization

import { searchClinic } from "./functions/asyncselect.functions";
import { SanCreateAppointment, SanDeleteAppointment, SanEditAppointment, SanReadAppointment } from "./functions/sanity.functions";

export const FunctionsRegistry = {
  SanCreateAppointment: SanCreateAppointment,
      
  SanDeleteAppointment: SanDeleteAppointment,
      
  SanEditAppointment: SanEditAppointment,
  
  SanReadAppointment: SanReadAppointment,

  __SEARCH_CLINIC_GET_OPTION_LABEL         : searchClinic.getOptionLabel, 
  __SEARCH_CLINIC_GET_OPTION_VALUE         : searchClinic.getOptionValue,
  __SEARCH_CLINIC_IS_OPTIONS_EQUAL_TO_VALUE: searchClinic.isOptionsEqualToValue,
};
