/* eslint-disable array-bracket-newline */
/* eslint-disable no-unused-vars */
import React from "react";

import {
  CoreAvatar,
  CoreChip,
  CoreTypographyCaption,
  CoreClasses,
  getFullName
} from "@wrappid/core";

export default function UserChip(props) {
  const {
    userid,
    _firstName = "",
    _middleName = "",
    _lastName = "",
    _email = "",
    _photoUrl = "",
  } = props;
  const [firstName, setFirstName] = React.useState(_firstName);
  const [middleName, setMiddleName] = React.useState(_middleName);
  const [lastName, setLastName] = React.useState(_lastName);
  const [email, setEmail] = React.useState(_email);
  const [photoUrl, setPhotoUrl] = React.useState(_photoUrl);

  React.useEffect(
    () => {
      // -- async function apiCall() {
      //     await axiosInterceptor({
      //         headers: await authHeader(true),
      //         method : HTTP.GET,
      //         url:
      //   config.backendUrl +
      //   "/business/individual/UserBasicInfo?_defaultFilter=" +
      //   encodeURIComponent(JSON.stringify({ "Users.id": userid })),
      //     })
      //         .then((response) => {
      //             setFirstName(response?.data?.data?.data["Person.firstName"]);
      //             setMiddleName(response?.data?.data?.data["Person.middleName"]);
      //             setLastName(response?.data?.data?.data["Person.lastName"]);
      //             setEmail(response?.data?.data?.data["email"]);
      //             setPhotoUrl(response?.data?.data?.data["Person.photoUrl"]);
      //         })
      //         .catch((error) => {
      //             // eslint-disable-next-line no-console
      //             console.error(error);
      //         });
      // }
      // if (_firstName === "" && _middleName === "" && _lastName === "" && _photoUrl === "") {
      //     apiCall();
      // }
    },
    [
      /*userid*/
    ]
  );

  let displayName = "";

  if (firstName === "" && middleName === "" && lastName === "") {
    displayName = email;
  } else {
    displayName = getFullName({ firstName, lastName, middleName });
  }

  return (
    <CoreChip
      size="small"
      avatar={
        <CoreAvatar
          styleClasses={[CoreClasses.BORDER.BORDER_0]}
          src={photoUrl || "photo.jpg"}
        />
      }
      label={
        <CoreTypographyCaption limitChars={12} hideSeeMore={true}>
          {displayName}
        </CoreTypographyCaption>
      }
    />
  );
}
