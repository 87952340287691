import { DefaultCoreStyles } from "@wrappid/core";
import { theme } from "@wrappid/styles";

export default class DefaultModuleStyles extends DefaultCoreStyles {
  constructor(){
    super();
    this.style = {
      rxAlertWalletIcon: { height: "50px" },
    
      rxCashCard: {
        border: `1px solid ${theme.palette.secondary.main}`,
        color : theme.palette.secondary.contrastText,
        cursor: "pointer",
      },
    
      rxCashCardCash: { backgroundColor: theme.palette.background.default },
    
      rxRechargeDisclaimer: {
        fontSize  : "13px",
        fontWeight: "100",
      },

      rxRechargeDisclaimerTermsAndConditions: {
        color   : theme.palette.secondary.contrastText,
        fontSize: "13px",
      },

      rxSuccessAmount: {
        // -- marginTop: "-5%",
        fontSize  : "20px",
        fontWeight: "500",
      },

      rxSuccessAmountCard: {
        border      : "1px dashed",
        borderRadius: "5%",
        marginLeft  : "29%",
        width       : "43%",
      },
    };
  }
}
