
import {
  CoreGrid,
  CoreBox,
  CoreTypographyBody1,
  CoreAvatar,
  CoreH6,
  CoreStack,
  CoreIconText,
  CoreTypographyCaption,
  CoreClasses,
  __IconTypes,
  getAge,
  getFullName
} from "@wrappid/core";

import { CustomIconTooltip } from "./PatientDetailsCard";
import { getAddress } from "../functions/helper.functions";

export default function RelativeUserInfo(props) {
  const { data: info } = props;

  const getGender = (gender, iconFlag = false) => {
    let genderTmp = "unknown";

    if (typeof gender === "string") {
      genderTmp = gender.toLowerCase();
    } else if (typeof gender === "object") {
      genderTmp = (gender?.id || gender?.label || "unknown").toLowerCase();
    } else {
      genderTmp = "unknown";
    }

    if (!iconFlag) return genderTmp && genderTmp.trim() !== "" ? genderTmp : "unknown";

    switch (genderTmp) {
      case "male":
        return "male";

      case "female":
        return "female";

      default:
        return "question_mark";
    }
  };

  return (
    info && (
      <CoreBox>
        <CoreTypographyBody1 styleClasses={[CoreClasses.DATA_DISPLAY.BOLD_TEXT]}>
                Creating relative for
        </CoreTypographyBody1>

        <CoreGrid>
          <CoreBox
            gridProps={{ gridSize: { md: 2, xs: 12 } }}
            styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.BORDER.BORDER_PRIMARY]}
          >
            <CoreAvatar
              styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_LARGE]}
              src={info["RelatedPerson.photoUrl"]}
            />
          </CoreBox>

          <CoreBox gridProps={{ gridSize: { md: 10, xs: 12 } }} styleClasses={[]}>
            <CoreH6>{
              getFullName({ 
                firstName : info["RelatedPerson.firstName"], 
                lastName  : info["RelatedPerson.lastName"], 
                middleName: info["RelatedPerson.middleName"] 
              })
            }</CoreH6>

            <CoreStack
              direction="row"
              spacing={1}
              styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_START]}
            >
                    
              <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon="cake" text={getAge(info["RelatedPerson.dob"])} />

              <CoreTypographyCaption styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY]}>{" | "}</CoreTypographyCaption>

              <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon={getGender(info["RelatedPerson.gender"], true)} text={getGender(info["RelatedPerson.gender"], true)} />

            </CoreStack>
          </CoreBox>

          <CustomIconTooltip
            icon="location_on"
            title="Address"
            text={getAddress(info)}
          />
        </CoreGrid>
      </CoreBox>
    )
  );
}
