import { SET_APPOINTMENT_REDUCER } from "../types/appointmentTypes";

export const setAppointmentReducer = (data) => {
  return (dispatch) => {
    dispatch({ data: data, type: SET_APPOINTMENT_REDUCER });
  };
};

export const setClinic = (data) => {
  return (dispatch) => {
    dispatch({ payload: data, type: "SET_CLINIC" });
    dispatch({
      payload: {
        apiDetails: null,
        data      : {
          addLine1  : data?.addLine1,
          addLine2  : data?.addLine2,
          city      : data?.city,
          clinicLogo: data?.Clinic?.photoUrl,
          country   : data?.country,
          district  : data?.district,
          fullName  : data?.fullName,
          phone     : data?.phone,
          pin       : data?.pin,
          state     : data?.state,
        },
        id: "prescriptionClinic",
      },
      type: "FORM_DATA_SAVE",
    });
  };
};
