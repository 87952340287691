import React, { useContext } from "react";

import {
  __EntityStatus,
  HTTP,
  CoreDataTable,
  CoreDialogContext,
  apiRequestAction,
  FORM_IDS,
  __IconTypes,
  coreUseSearchParams,
  reloadDataTableAction
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

// -- import AppointmentDetailsCard from "./AppointmentDetailsCard";
import AppointmentSummaryComponent from "./AppointmentSummaryComponent";
import CreateAppointment from "./CreateAppointment";
import { setAppointmentReducer } from "../actions/appointmentAction";
import {
  CREATE_APPOINTMENT_ERROR,
  CREATE_APPOINTMENT_SUCCESS,
  EDIT_APPOINTMENT_ERROR,
  EDIT_APPOINTMENT_SUCCESS
} from "../types/appointmentTypes";

export default function UpcomingAppointment(props) {
  let [searchParams] = coreUseSearchParams();
  const personID = useSelector((state) => state?.profile?.basic?.id);
  const profile = useSelector((state) => state.profile);
  const {
    createAppoinmentSuccess,
    createAppoinmentError,
    createAppoinmentIsWarning,
    createAppoinmentMsg,
    createAppointmentOldData,
    editAppointmentSuccess,
    editAppointmentError,
    editAppointmentMsg,
    editAppointmentData,
    patient
  } = useSelector((state) => state.appointment);

  const { Person = { firstName: "", id: null, lastName: "", middleName: "" } } =
    {};

  const dispatch = useDispatch();

  const { setDialog } = useContext(CoreDialogContext);

  // create appointment hook
  React.useEffect(() => {
    if (createAppoinmentError) {
      if (createAppoinmentIsWarning) {
        setDialog({
          cancelButton: () => {
          },
          doneButton: () => {
            dispatch(
              apiRequestAction(
                HTTP.POST,
                "/appointment/schedule",
                true,
                {
                  ...createAppointmentOldData,
                  clinicId:
                    Number(createAppointmentOldData?.clinicId) === 0
                      ? null
                      : Number(createAppointmentOldData?.clinicId),
                  doctorId : profile?.basic?.id,
                  isForce  : true,
                  patientId: patient?.Person?.id
                },
                CREATE_APPOINTMENT_SUCCESS,
                CREATE_APPOINTMENT_ERROR
              )
            );
          },
          doneButtonLabel: "Force Create",
          showDialog     : true,
          subtitle       : createAppoinmentMsg || "Something went wrong",
          title          : "Warning!",
          type           : "info"
        });
      } else {
        setDialog({
          cancelButton: () => {
          },
          doneButton: () => {
          },
          showDialog: true,
          subtitle  : createAppoinmentMsg || "Something went wrong",
          title     : "Oops!",
          type      : "error"
        });
      }
      dispatch(setAppointmentReducer({ createAppoinmentError: false }));
    }
  }, [
    createAppoinmentError,
    createAppoinmentIsWarning,
    createAppoinmentMsg,
    createAppointmentOldData,
    Person,
  ]);

  React.useEffect(() => {
    if (createAppoinmentSuccess) {
      setDialog({
        cancelButton: () => {
        },
        doneButton: () => {
          dispatch(setAppointmentReducer({ createAppoinmentSuccess: false }));
          dispatch(reloadDataTableAction("UpcomingAppointments"));
        },
        showDialog: true,
        subtitle  : "Appointment created successfully",
        title     : "Success!",
      });
    }
  }, [createAppoinmentSuccess]);
  
  React.useEffect(() => {
    if (editAppointmentSuccess) {
      setDialog({
        cancelButton: () => {
        },
        doneButton: () => {
          dispatch(setAppointmentReducer({ editAppointmentSuccess: false }));
          dispatch(reloadDataTableAction("UpcomingAppointments"));
        },
        showDialog: true,
        subtitle  : "Appointment edited successfully",
        title     : "Success!",
      });
    }
  }, [editAppointmentSuccess]);

  // edit appointment hook
  React.useEffect(() => {
    if (editAppointmentError) {
      if (editAppointmentData?.isWarning) {
        setDialog({
          cancelButton: () => {
          },
          doneButton: () => {
            const data = { ...editAppointmentData?.oldData };

            delete data?.id;
            delete data?.rescheduled_appt_id;
            dispatch(
              apiRequestAction(
                HTTP.PUT,
                "/appointment/" +
                  editAppointmentData.oldData.id +
                  "/reSchedule",
                true,
                {
                  ...data,
                  clinicId:
                    Number(editAppointmentData?.oldData?.clinicId) === 0
                      ? null
                      : Number(editAppointmentData?.oldData?.clinicId),
                  doctorId : profile?.basic?.id,
                  isForce  : true,
                  patientId: editAppointmentData?.oldData?.patientId,
                },
                EDIT_APPOINTMENT_SUCCESS,
                EDIT_APPOINTMENT_ERROR
              )
            );
          },
          doneButtonLabel: "Force Create",
          showDialog     : true,
          subtitle       : editAppointmentMsg || "Something went wrong",
          title          : "Warning!",
          type           : "info"
        });
      } else {
        setDialog({
          cancelButton: () => {
          },
          doneButton: () => {
          },
          showDialog: true,
          subtitle  : editAppointmentMsg || "Something went wrong",
          title     : "Oops!",
          type      : "error"
        });
      }
      dispatch(
        setAppointmentReducer({
          editAppointmentData : null,
          editAppointmentError: false,
        })
      );
    }
  }, [editAppointmentError, editAppointmentData, editAppointmentMsg]);

  const tableRowActions = [
    {
      action: (data) => {
        props.navigatePrescription(data, null);
      },
      icon: { icon: "fa-file-prescription", type: __IconTypes.FONTAWESOME_V5_SOLID_ICON },

      label: "Create Prescription",

      type: "action",
    },
    {
      action: (data) => {
        props.makeCurrent(data);
      },
      clearRowData: true,
      icon        : { icon: "fa-angle-double-up", type: __IconTypes.FONTAWESOME_V5_SOLID_ICON },
      label       : "Make Current",
      type        : "action",
    },
  ];

  return (
    (personID && 
    <CoreDataTable
      entity="UpcomingAppointments"
      createEntityButtonText="Schedule Appointment"
      hideCreateForm={true}
      createFormID={FORM_IDS.__CREATE_APPOINTMENT}
      updateFormID={FORM_IDS.__CREATE_APPOINTMENT}
      rowActions={tableRowActions}
      filterQuery={{
        filter: {
          _status : __EntityStatus.NEW,
          doctorId: personID,
        },
      }}
      summaryRendererComponent={AppointmentSummaryComponent}
      preRender_CreateData_DetailsPaneComponent={CreateAppointment}
      openCreateOnMount={searchParams?.get("create") === "true"}
      noHeaderInApp={true}
      // -- preRenderDetailsPaneComponent={AppointmentDetailsCard}
    />
    )
  );
}
