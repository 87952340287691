import { getFormikRequiredMessage } from "@wrappid/core";
import * as yup from "yup";

export const ValidationsRegistry = {
  complaint         : { complaint: yup.array().min(1).required(getFormikRequiredMessage("Complaint")) },
  diagnoses         : { diagnosis: yup.array() },
  followup          : { followup: yup.array() },
  guideline         : { guideline: yup.array() },
  history           : { history: yup.array().min(1).required(getFormikRequiredMessage("History")) },
  prescriptionClinic: {
    address : yup.string().required(getFormikRequiredMessage("Address")),
    fullName: yup.string().required(getFormikRequiredMessage("Name")),
    phone   : yup.string().required(getFormikRequiredMessage("Phone")),
  },
  prescriptionClinicSearch  : { clinic: yup.object().required(getFormikRequiredMessage("Clinic")) },
  prescriptionPatientProfile: {
    dob   : yup.date().required(getFormikRequiredMessage("DOB")),
    gender: yup.string().required(getFormikRequiredMessage("Gender")),
    name  : yup.string().required(getFormikRequiredMessage("Name")),
  },
  prescriptionPatientSearch: { patient: yup.object().required(getFormikRequiredMessage("Patient")) },
      
  procedure: { procedure: yup.array() },
      
  reffer: { history: yup.array().min(1).required(getFormikRequiredMessage("Reffer")) },
};