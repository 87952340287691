import LargeappBuilderStyles from "../../modules/app-builder/styles/LargeModuleStyles";
import LargeappointmentStyles from "../../modules/appointment/styles/LargeModuleStyles";
import LargeassistantStyles from "../../modules/assistant/styles/LargeModuleStyles";
import LargeauthStyles from "../../modules/auth/styles/LargeModuleStyles";
import LargeclinicStyles from "../../modules/clinic/styles/LargeModuleStyles";
import LargedashboardStyles from "../../modules/dashboard/styles/LargeModuleStyles";
import LargedataManagementStyles from "../../modules/data-management/styles/LargeModuleStyles";
import LargeguideStyles from "../../modules/guide/styles/LargeModuleStyles";
import LargepatientStyles from "../../modules/patient/styles/LargeModuleStyles";
import LargepaymentStyles from "../../modules/payment/styles/LargeModuleStyles";
import LargeprescriptionStyles from "../../modules/prescription/styles/LargeModuleStyles";
import LargeprofileStyles from "../../modules/profile/styles/LargeModuleStyles";
import LargesettingsStyles from "../../modules/settings/styles/LargeModuleStyles";
import LargeuserManagementStyles from "../../modules/user-management/styles/LargeModuleStyles";

const LargeModulesStyles = {
...new LargeappBuilderStyles().style,
...new LargeappointmentStyles().style,
...new LargeassistantStyles().style,
...new LargeauthStyles().style,
...new LargeclinicStyles().style,
...new LargedashboardStyles().style,
...new LargedataManagementStyles().style,
...new LargeguideStyles().style,
...new LargepatientStyles().style,
...new LargepaymentStyles().style,
...new LargeprescriptionStyles().style,
...new LargeprofileStyles().style,
...new LargesettingsStyles().style,
...new LargeuserManagementStyles().style,
};
 export default LargeModulesStyles