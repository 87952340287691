import React, { Component } from "react";

import {
  CoreBox,
  CoreClasses,
  CoreContainedButton,
  CoreCssBaseline,
  CoreDomNavigate,
  CoreForm,
  CoreGrid,
  CoreH6,
  CoreIcon,
  CoreIconButton,
  CoreMenu,
  CoreTypographyBody1,
  FORM_EDIT_MODE,
  FORM_IDS,
  HTTP,
  MENU_ITEM,
  apiRequestAction,
  createApiMeta,
  getForm,
  onEditForm,
  toggleRightMenuState
} from "@wrappid/core";
import { WEB_PLATFORM } from "@wrappid/core/utils/themeUtil";
import { WrappidDataContext } from "@wrappid/styles";
import { connect } from "react-redux";

import PrescriptionForms from "./PrescriptionForms";
import PrescriptionTopBar from "./PrescriptionTopBar";
import PrescriptionRightDrawer from "./RightDrawer/PrescriptionRightDrawer";
// eslint-disable-next-line import/no-unresolved
import AppClasses from "../../../styles/AppClasses";
import {
  clearPrescription,
  downloadPrescription,
  setLanguage,
  setPrescriptionReducer
} from "../actions/prescription.action";
import { ApiRegistry } from "../apis.registry";
import { SanPrescription } from "../functions/sanity.functions";
import { RoutesRegistry } from "../routes.registry";
import {
  GET_PRESCRIPTION_ERROR,
  GET_PRESCRIPTION_SUCCESS
} from "../types/prescription.types";
import {
  CheckAllFormSubmitSucces,
  DesignFormation,
  VitalsFormation,
  getClearValues,
  getPrescriptionInitData
} from "../utils/helper";

let formIds = [
  FORM_IDS.__PRESCRIPTION_COMPLAINTS,
  FORM_IDS.__PRESCRIPTION_GUIDELINES,
  FORM_IDS.__PRESCRIPTION_DIAGNOSIS,
  FORM_IDS.__PRESCRIPTION_PROCEDURES,
  FORM_IDS.__PRESCRIPTION_FOLLOWUPS,
  FORM_IDS.__PRESCRIPTION_VITALS,
  FORM_IDS.__PRESCRIPTION_MEDICINE,
  FORM_IDS.__PRESCRIPTION_HISTORIES,
  FORM_IDS.__PRESCRIPTION_REFFERS
];

let generatePrescriptionForm = FORM_IDS.__PRESCRIPTION_GENERATE;

let createPrescriptionForm = FORM_IDS.__PRESCRIPTION_INIT;

/**
 * @todo
 * PROV Assessment ID - PROV-00001
 * GENERATED Assessment ID - PREFFIX-00001
 */
class PrescriptionMain extends Component {
  static contextType = WrappidDataContext;

  constructor(props) {
    super(props);

    formIds.forEach((formId) => {
      this[formId] = React.createRef();
    });

    this[generatePrescriptionForm] = React.createRef();
    this[createPrescriptionForm] = React.createRef();
  }

  state = {
    advMedicines       : [],
    createNew          : false,
    createNewSubmitted : false,
    generate           : false,
    generationLoading  : false,
    platform           : this.context?.config?.platform,
    prescriptionDone   : false,
    prescriptionUpdate : false,
    redirectToDashboard: false,
    saveStarted        : false,
    submittedForms     : [],
    template           : "Basic",
    vitalsForm         : {}
  };

  componentDidMount = () => {
    /**
         * Alert for prescription unload
         */
    if (this.state.platform === WEB_PLATFORM) {
      window.addEventListener("beforeunload", this.alertUser);
    }

    /**
         * Set basic at first
         *
         * @todo
         * should mound last selected template
         */
    if (this.props.prescriptionTemplates) {
      // eslint-disable-next-line no-console
      console.log("MOUNT--------", this.props.prescriptionTemplates);
      let temp = this.props.prescriptionTemplates.find(
        (tmp) => tmp?.name?.toLowerCase() === "sys_gen_basic"
      );

      // eslint-disable-next-line no-console
      console.log("MOUNT--------", temp);
      if (temp) {
        this.HandleTemplateChange(temp);
      }
    }

    if (this.props.prescription?.id) {
      let meds = this.GetInitData(FORM_IDS.__PRESCRIPTION_MEDICINE);

      this.setState({ advMedicines: meds });
    }
  };

  componentWillUnmount = () => {
    if (this.state.platform === WEB_PLATFORM) {
      window.removeEventListener("beforeunload", this.alertUser);
    }
  };

  componentDidUpdate = () => {
    if (
      !this.props.prescription?.id &&
            this.props.idInUrl &&
            !this.state.prescriptionUpdate
    ) {
      this.props.GetPrescription(this.props.idInUrl);
      this.setState({ prescriptionUpdate: true });
    }
    //On all subform submit submit generate
    /**
         * Generate Prescription - Final Prescription Submit
         */
    if (
      this.state.generate &&
            !this.state.createNew &&
            !this.state.createNewSubmitted &&
            !this.state.saveStarted &&
            CheckAllFormSubmitSucces(
              this.state.submittedForms,
              this.props.formSubmitSuccess
            )
    ) {
      // eslint-disable-next-line no-console
      console.log(
        "GENERATE PRESCRIPTION SUBMIT",
        this[generatePrescriptionForm]
      );
      this[generatePrescriptionForm]?.current?.submitForm();
      this.setState({
        generate         : false,
        generateSubmitted: true,
        generationLoading: true,
        submittedForms   : []
      });
    }

    /**
         * Prescription Initiate - First Time
         */
    if (this.state.createNew) {
      // eslint-disable-next-line no-console
      console.log("NEW CRAETE PRESCRIPTION");
      this.setState(
        { createNew: false, createNewSubmitted: true },
        () => {
          this[createPrescriptionForm]?.current?.submitForm();
        }
      );
    }

    /**
         * Prescription Created - Save all forms
         */
    if (
      this.props.formSubmitSuccess[createPrescriptionForm]?.success &&
            this.state.createNewSubmitted &&
            this.props.prescription?.id
    ) {
      // eslint-disable-next-line no-console
      console.log("EMPTY PRESCRIPTION CREATE SUCCESS");
      this.setState({ createNewSubmitted: false }, async () => {
        // eslint-disable-next-line no-console
        console.log("SAVING ALL FORMS");
        let forms = await this.SaveAllForms();

        if (forms === 2) {
          this.setState({ generate: false });
        } else {
          this.setState({
            saveStarted   : false,
            submittedForms: forms
          });
        }
      });
    }

    /**
         * Form reset when prescription cleared from dialog
         */

    if (this.props.clearFlag) {
      for (let i = 0; i < formIds.length; i++) {
        try {
          let form = formIds[i];

          let curFormRef = this[form]?.current;

          if (curFormRef) {
            if (form === FORM_IDS.__PRESCRIPTION_MEDICINE) {
              //have to check
              this.props.SetPrescriptionReducer({ clearMedFlag: true });
            } else {
              curFormRef.resetForm({ values: getClearValues(curFormRef?.values) });
            }
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error("ERROR in Form reset", err);
        }
      }

      this.props.SetPrescriptionReducer({ clearFlag: false });
    }
  };

  /**
     * Set template local state
     *
     * @param {*} template
     */
  HandleTemplateChange = async (template) => {
    this.props.SetPrescriptionReducer({ template });
    let design = this.props.templateDesigns?.find(
      (tmp) => tmp.templateId === template?.id
    )?.extraInfo;

    if (design) {
      let designArr = DesignFormation(
        design.body,
        this.props.mainMasterData,
        template,
        design.group
      );

      // eslint-disable-next-line no-console
      console.log("DESIGN ARR-----", designArr);

      let vitals = VitalsFormation(
        template,
        this.props.mainMasterData,
        this.props.prescriptionTemplates
      );

      let tempForm = await getForm("prescriptionVitals");

      let vitalsForm = {
        prescriptionVitals: {
          ...tempForm,
          formJson: {
            ...tempForm.formJson,
            fields: vitals
          }
        }
      };

      // eslint-disable-next-line no-console
      console.log("VITALS FORM", vitalsForm);
      await this.setState({
        formedDesign: designArr,
        template,
        vitalsForm  : { ...vitalsForm }
      });
    }
  };

  /**
     * Screen unload alert
     *
     * @param {*} e
     */
  alertUser = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  /**
     * @todo review
     * NOT USED
     *
     * @param {*} event
     */
  HandleLeavePage = (event) => {
    // eslint-disable-next-line no-console
    console.log("CALLING FUNCTION", event);
    for (let i = 0; i < formIds.length; i++) {
      try {
        let form = formIds[i];

        let curFormRef = this[form]?.current;

        if (curFormRef) {
          if (form === FORM_IDS.__PRESCRIPTION_MEDICINE) {
            //have to check
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("ERROR in unmount check", err);
      }
    }
  };

  /**
     * Create Init Data From Diff Source (Store)
     * -
     *
     * @param {*} formId
     * @returns
     */
  GetInitData = (formId) => {
    let prescriptionInitData = getPrescriptionInitData(
      {
        prescription       : this.props.prescription,
        prescriptionClinic : this.props.prescriptionClinic,
        prescriptionNavData: this.props.prescriptionNavData,
        profile            : this.props.doctorProfile
      },
      formId
    );

    // eslint-disable-next-line no-console
    console.log(
      "-------------------prescriptionInitData",
      formId,
      prescriptionInitData
    );

    return prescriptionInitData;
  };

  /**
     * Download Prescription
     */
  HandleDownload = () => {
    this.props.DownloadPrescription(
      { download: true, languageId: this.props.language?.id },
      this.props.prescription.id,
      this.props.auth.accessToken
    );
  };

  HandleMail = () => {
    this.props.DownloadPrescription(
      { languageId: this.props.language?.id, shareMail: true },
      this.props.prescription.id,
      this.props.auth.accessToken
    );
  };

  HandleWhatsapp = () => {
    this.props.DownloadPrescription(
      { languageId: this.props.language?.id, shareWhatsapp: true },
      this.props.prescription.id,
      this.props.auth.accessToken
    );
  };

  /**
     * Toggle Right Drawer
     */
  HandleDrawer = () => {
    this.props.ToggleRightMenuState();
  };

  /**
     * onClick - Generate Prescription Button
     */
  Generate = async () => {
    // eslint-disable-next-line no-console
    console.log("IN GENERATE");
    this.setState({ generationLoading: true }, async () => {
      try {
        let submittedForms = await this.SaveAllForms();

        if (submittedForms === 0) {
          this.setState({
            createNew  : true,
            generate   : true,
            saveStarted: true
          });
        } else if (submittedForms === 2) {
          this.setState({
            generate         : false,
            generationLoading: false,
            submittedForms   : []
          });
        } else {
          this.setState({
            generate      : true,
            saveStarted   : false,
            submittedForms: submittedForms
          });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("ERROR in unmount check", err);
        /**
                 * Alert - Specific Errors
                 */
      }
    });
  };

  SaveAllForms = async () => {
    let forms = [];

    if (!this.props.prescription || !this.props.prescription.id) {
      return 0;
    }

    for (let i = 0; i < formIds.length; i++) {
      let form = null;

      try {
        form = formIds[i];
        let curFormRef = this[form]?.current;

        // eslint-disable-next-line no-console
        console.log("SUBMITTING FORM", curFormRef);

        if (form === FORM_IDS.__PRESCRIPTION_MEDICINE) {
          this.SubmitMeds();
        } else {
          if (curFormRef) {
            // eslint-disable-next-line no-console
            console.log(
              "TOUCHED",
              curFormRef?.touched,
              curFormRef?.errors
            );
            let validateFormFlag = await curFormRef.validateForm();

            if (
              validateFormFlag &&
                            Object.keys(validateFormFlag).length > 0
            ) {
              await curFormRef.submitForm();
              this.FocusForm(form);
              return 2;
            }
            let touchedFields = Object.keys(curFormRef?.touched);

            if (touchedFields?.length > 0) {
              let submitFormResult =
                                await curFormRef.submitForm();

              // eslint-disable-next-line no-console
              console.log(
                "AFTER M+FORMIK SUBMIT",
                form,
                curFormRef,
                submitFormResult
              );
              forms.push(form);
            }
          } else {
            // eslint-disable-next-line no-console
            console.error("UNKNOWN REF TO FORM", form);
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("ERROR for form", form);
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
    return forms;
  };

  /**
     * Right Drawer Menu Click- Focus Specific Form
     *
     * @param {*} item
     */
  OnMenuClick = (item) => {
    this.FocusForm(item.name);
  };

  /**
     * Focus form - specific element
     *
     * @param {*} formId
     */
  FocusForm = async (formId) => {
    let form = await getForm(formId);

    // eslint-disable-next-line no-console
    console.log("FORM", form);
    this.props.OnEditForm(formId, formId);
    if (
      formId === FORM_IDS.__PRESCRIPTION_VITALS &&
            this.state.vitalsForm &&
            this.state.vitalsForm[formId] &&
            this.state.vitalsForm[formId].fields &&
            this.state.vitalsForm[formId].fields[0]
    ) {
      let elem = document.getElementById(
        this.state.vitalsForm[formId].fields[0]
      );

      // eslint-disable-next-line no-console
      console.log("ELE", elem);
      if (elem) {
        elem.focus();
      }
    } else if (form && formId === FORM_IDS.__PRESCRIPTION_MEDICINE) {
      // do nothing as of now
    } else if (form && form.fields && form.fields[0]) {
      let elem = document.getElementById(form.fields[0].id);

      // eslint-disable-next-line no-console
      console.log("ELE", elem);
      if (elem) {
        elem.focus();
      }
    }
  };

  /**
     * @todo review
     * NOT USED
     *
     * @returns
     */
  isGenerateVisible = () => {
    if (this.props.prescription?.id) {
      return true;
    }
    let complaintFormRef = this[FORM_IDS.__PRESCRIPTION_COMPLAINTS];

    // eslint-disable-next-line no-console
    console.log(
      "complaintFormRef?.current?.values?.complaint",
      complaintFormRef?.current?.values
    );
    if (complaintFormRef?.current?.values?.complaint) {
      // eslint-disable-next-line no-console
      console.log(
        "complaintFormRef?.current?.values?.complaint",
        complaintFormRef?.current?.values
      );
      if (complaintFormRef.current.values.complaint.length > 0) {
        return true;
      }
    }
  };

  /**
     * Language Change - Trigger
     *
     * @param {*}
     */
  HandleLanguageChange = (value) => {
    this.props.ChangeLanguage(value);
  };

  /**
     * clear prescription
     */
  WriteNewPrescription = () => {
    this.props.SetPrescriptionReducer({
      navData     : null,
      prescription: null
    });
    this.setState({ prescriptionDone: true });
  };

  /**
     *
     * MEDICINE component functions
     */
  AddMedicine = (data) => {
    this.setState({ advMedicines: data });
  };

  SubmitMeds = async () => {
    let formId = FORM_IDS.__PRESCRIPTION_MEDICINE;

    let rawForm = await getForm(formId);
    let formJson = rawForm?.formJson;

    // eslint-disable-next-line no-console
    console.log("FORM JSON", formJson);

    let values = this.state.advMedicines;

    let apiMeta = createApiMeta({}, formJson, values, "create");

    let sanData = { endPoint: apiMeta.endpoint, values: values };

    // eslint-disable-next-line no-console
    console.log("ADDING");
    sanData = SanPrescription(values, apiMeta, this.props.state, { formId });

    // eslint-disable-next-line no-console
    console.log("SANITIZED DATA", sanData);

    if (sanData.values) {
      apiMeta.values = sanData.values;
    }
    if (sanData.endpoint) {
      apiMeta.endpoint = sanData.endpoint;
    }

    // eslint-disable-next-line no-console
    console.log("SUBMIT", apiMeta);
    this.props.HandleFormSubmit(
      apiMeta.method,
      apiMeta.endpoint,
      apiMeta.authRequired,
      apiMeta.values,
      apiMeta.successType,
      apiMeta.errorType,
      apiMeta.localAction,
      apiMeta.includeFile,
      apiMeta.files,
      formId,
      apiMeta.reload
    );
  };

  onEditMedicineNote = (event) => {
    if (event?.target?.id && event?.target?.id?.includes("-")) {
      let index = event?.target?.id?.split("-")[1];

      let field = event?.target?.id?.split("-")[0];

      let advMeds = this.state.advMedicines;

      // eslint-disable-next-line no-console
      console.log("EVENT", Number(index), field, event?.target?.value);
      advMeds[Number(index)][field] = event.target.value;
      this.setState({ advMedicines: [...advMeds] });
    }
  };

  onChangeMedGeneric = (id) => {
    let meds = this.state.advMedicines;
    let i = meds.findIndex((med) => med.id === id);

    meds[i]["isGeneric"] = !meds[i]["isGeneric"];
    this.setState({ advMedicines: [...meds] });
  };

  OnMedClear = (id) => {
    this.setState({ advMedicines: this.state.advMedicines.filter((med) => med.id !== id) });
  };
    /**
     *
     * MEDICINE component functions END
     */

  render() {
    const {
      prescriptionNavData,
      prescriptionClinic,
      prescription,
      language,
      allClinics,
      prescriptionTemplates
    } = this.props;

    const { redirectToDashboard, prescriptionDone } = this.state;

    if (redirectToDashboard) {
      return (
        <CoreDomNavigate
          to={"/" + RoutesRegistry.DASHBOARD}
          exact={true}
        />
      );
    }
    if (prescriptionDone) {
      return (
        <CoreDomNavigate
          to={"/" + RoutesRegistry.APPOINTMENT_MANAGE}
          exact={true}
        />
      );
    }
    // eslint-disable-next-line no-console
    console.log("LC State", this.state);
    // eslint-disable-next-line no-console
    // console.log('LC PROPS', this.props);
    // eslint-disable-next-line no-console
    console.log("REFS");

    return (
      <>
        <CoreBox
          styleClasses={[CoreClasses.LAYOUT.FULL_HEIGHT, CoreClasses.LAYOUT.FLEXBOX]}
        >
          <CoreCssBaseline />

          <CoreBox
            component="main"
            id="prescriptionMain"
            // styleClasses={[AppClasses.PRESCRIPTION_CONTENT_CONTAINER]}
          >
            <CoreBox
              // styleClasses={[AppClasses.PRESCRIPTION_PAGE_CONTAINER]}
            >
              <CoreGrid
                coreId="prescriptionGrid"
                // styleClasses={[AppClasses.PRESCRIPTION_CONTAINER]}
              >
                {this.state.platform !== "mobile" && (
                  <PrescriptionTopBar
                    gridProps={{ gridSize: 12 }}
                    styleClasses={[AppClasses.PRESCRIPTION_TOP_BAR]}
                    prescription={prescription}
                    prescriptionNavData={
                      prescriptionNavData
                    }
                    HandleTemplateChange={
                      this.HandleTemplateChange
                    }
                    template={this.state.template}
                    basic={this.props.basic}
                    prescriptionClinic={prescriptionClinic}
                    allClinics={allClinics}
                    onLanguageChange={
                      this.HandleLanguageChange
                    }
                    language={language}
                    allTemplates={prescriptionTemplates}
                  />
                )}

                {/* empty form to drive init create prescription via core form */}
                <CoreForm
                  coreId={FORM_IDS.__PRESCRIPTION_INIT}
                  formId={FORM_IDS.__PRESCRIPTION_INIT}
                  mode={FORM_EDIT_MODE}
                  formRef={this[createPrescriptionForm]}
                />

                {/* all other forms */}
                {this.state.formedDesign?.map(
                  (formDesign, index) =>
                    formDesign.groupFlag ? (
                      <CoreGrid
                        key={`formDesign-${index}`}
                        gridProps={formDesign.gridProps}
                      >
                        {formDesign?.data?.map(
                          (groupedFormElement) => (
                            <PrescriptionForms
                              key={`formElem-${index}`}
                              formKey={
                                this.state
                                  .template.id
                                  ? `formElement-${this.state.template.id}-${index}`
                                  : `formElement-${index}`
                              }
                              formDesign={
                                groupedFormElement
                              }
                              gridProps={
                                groupedFormElement.design
                              }
                              prescription={
                                prescription
                              }
                              prescriptionNavData={
                                prescriptionNavData
                              }
                              GetInitData={
                                this.GetInitData
                              }
                              vitalsForm={
                                this.state
                                  .vitalsForm
                              }
                              prescriptionClinic={
                                prescriptionClinic
                              }
                              formRef={
                                this[
                                  groupedFormElement
                                    .name
                                ]
                              }
                              onFormFocus={
                                this.FocusForm
                              }
                              medicineFunctions={{
                                AddMedicine:
                                                                    this
                                                                      .AddMedicine,
                                OnMedClear:
                                                                    this
                                                                      .OnMedClear,
                                SubmitMeds:
                                                                    this
                                                                      .SubmitMeds,
                                advMedicines:
                                                                    this.state
                                                                      .advMedicines,
                                onChangeMedGeneric:
                                                                    this
                                                                      .onChangeMedGeneric,
                                onEditMedicineNote:
                                                                    this
                                                                      .onEditMedicineNote
                              }}
                            />
                          )
                        )}
                      </CoreGrid>
                    ) : (
                      <PrescriptionForms
                        key={`formElem-${index}`}
                        formKey={
                          this.state.template.id
                            ? `formElement-${this.state.template.id}-${index}`
                            : `formElement-${index}`
                        }
                        formDesign={formDesign}
                        gridProps={formDesign.design}
                        prescription={prescription}
                        prescriptionNavData={
                          prescriptionNavData
                        }
                        GetInitData={this.GetInitData}
                        vitalsForm={
                          this.state.vitalsForm
                        }
                        prescriptionClinic={
                          prescriptionClinic
                        }
                        formRef={this[formDesign.name]}
                        onFormFocus={this.FocusForm}
                        medicineFunctions={{
                          AddMedicine:
                                                        this.AddMedicine,
                          OnMedClear: this.OnMedClear,
                          SubmitMeds: this.SubmitMeds,
                          advMedicines:
                                                        this.state.advMedicines,
                          onChangeMedGeneric:
                                                        this.onChangeMedGeneric,
                          onEditMedicineNote:
                                                        this.onEditMedicineNote
                        }}
                      />
                    )
                )}

                {/* buttons section */}
                {this.state.template ? (
                  prescription?.isCompleted ? (
                    <CoreBox
                      styleClasses={[
                        CoreClasses.ALIGNMENT
                          .JUSTIFY_CONTENT_SPACE_EVENLY
                      ]}
                    >
                      <CoreContainedButton
                        disabled={
                          this.props
                            .downloadPrescriptionLoading
                        }
                        onClick={this.HandleDownload}
                        label="Download"
                      />

                      <CoreContainedButton
                        disabled={
                          this.props
                            .downloadPrescriptionLoading
                        }
                        onClick={this.HandleMail}
                        label="Share in Mail"
                      />

                      <CoreContainedButton
                        disabled={
                          this.props
                            .downloadPrescriptionLoading
                        }
                        onClick={this.HandleWhatsapp}
                        label="Share in Whatsapp"
                      />

                      <CoreContainedButton
                        disabled={
                          this.props
                            .downloadPrescriptionLoading
                        }
                        onClick={
                          this.WriteNewPrescription
                        }
                        label="New Prescription"
                      />
                    </CoreBox>
                  ) : (
                  // this.isGenerateVisible() &&
                    <>
                      <CoreBox
                        styleClasses={[
                          CoreClasses.ALIGNMENT
                            .JUSTIFY_CONTENT_FLEX_END
                        ]}
                      >
                        <CoreContainedButton
                          label={
                            "Generate Prescription"
                          }
                          onClick={this.Generate}
                          disabled={
                            this.state.generate ||
                                                        this.state
                                                          .generationLoading
                          }
                          alignment="end"
                        />
                      </CoreBox>

                      {/* empty form to drive submit via core form */}
                      <CoreForm
                        formRef={
                          this[
                            generatePrescriptionForm
                          ]
                        }
                        coreId={
                          FORM_IDS.__PRESCRIPTION_GENERATE
                        }
                        formId={
                          FORM_IDS.__PRESCRIPTION_GENERATE
                        }
                        styleClasses={
                          CoreClasses.LAYOUT
                            .AUTH_FORM_CONTAINER
                        }
                        mode={FORM_EDIT_MODE}
                      />
                    </>
                  )
                ) : null}
              </CoreGrid>
            </CoreBox>
          </CoreBox>

          <CoreIconButton
            style={{
              bottom  : 20,
              position: "absolute",
              right   : 20,
              zIndex  : 999999
            }}
            size="medium"
            onClick={this.HandleDrawer}
          >
            <CoreIcon size="medium">
              {this.props.rightMenuOpen
                ? "chevron_right"
                : "chevron_left"}
            </CoreIcon>
          </CoreIconButton>

          <PrescriptionRightDrawer open={this.props.rightMenuOpen}>
            <CoreBox
              styleClasses={[CoreClasses.DISPLAY.SM.NONE, CoreClasses.PADDING.P1]}
            >
              <PrescriptionTopBar
                prescription={prescription}
                prescriptionNavData={prescriptionNavData}
                HandleTemplateChange={this.HandleTemplateChange}
                template={this.state.template}
                basic={this.props.basic}
                prescriptionClinic={prescriptionClinic}
                allClinics={allClinics}
                onLanguageChange={this.HandleLanguageChange}
                language={language}
              />
            </CoreBox>

            <CoreBox
              accordionSummery={
                <CoreTypographyBody1>
                                    Quick Navigation
                </CoreTypographyBody1>
              }
            >
              <CoreMenu
                OnMenuClick={this.OnMenuClick}
                open={this.props.rightMenuOpen}
                menu={this.props.mainMasterData
                  ?.find((tmp) => tmp.name === "prescription")
                  ?.Children?.filter((tmp) => !tmp.isVisible)
                  ?.map((tmp) => {
                    return {
                      type: MENU_ITEM,
                      ...tmp
                    };
                  })}
              />
            </CoreBox>

            <CoreBox
              accordionSummery={
                <CoreTypographyBody1>
                                    Helper
                </CoreTypographyBody1>
              }
            >
              <CoreH6>Coming Soon</CoreH6>
            </CoreBox>
          </PrescriptionRightDrawer>
        </CoreBox>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  // eslint-disable-next-line no-console
  console.log("STATE", state);
  return {
    allClinics  : state.selectOptions?.options?.clinics?.data,
    auth        : state.auth,
    basic       : state?.profile?.basic,
    clearFlag   : state.prescription.clearFlag,
    completeness: state.profile?.completeness?.report
      ? state.profile?.completeness?.report
      : {},
    doctorProfile: state.profile,
    downloadPrescriptionError:
            state?.prescription?.downloadPrescriptionError,
    downloadPrescriptionLoading:
            state?.prescription?.downloadPrescriptionLoading,
    downloadPrescriptionMsg: state?.prescription?.downloadPrescriptionMsg,
    downloadPrescriptionSuccess:
            state?.prescription?.downloadPrescriptionSuccess,
    formSubmitSuccess     : state.forms.formSubmitSuccess,
    getPrescriptionSuccess: state.prescription.getPrescriptionSuccess,
    language              : state.prescription.language,
    mainMasterData        : state.mdm.mainMasterData,
    menu                  : [{ icon: "person", label: "Hello" }, { icon: "add", label: "BOLO" }, { icon: "delete", label: "JI" }],
    prescription          : state.prescription.prescription,
    prescriptionClinic    : state.prescription.clinic,
    prescriptionNavData   : state.prescription.navData,
    prescriptionTemplates : state.prescription.prescriptionTemplates,

    rightMenuOpen  : state.menu.rightMenuOpen,
    rxCash         : state?.payment?.rxCash ? state?.payment?.rxCash : 0,
    state          : state,
    templateDesigns: state.prescription.templateDesigns
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ChangeLanguage: (language) => {
      dispatch(setLanguage(language));
    },
    ClearPrescription: (id) => {
      dispatch(clearPrescription(id));
    },
    DownloadPrescription: (query, id, token) => {
      dispatch(downloadPrescription(query, id, token));
    },
    GetPrescription: (id) => {
      dispatch(
        apiRequestAction(
          HTTP.GET,
          ApiRegistry.GET_PRESCRIPTION.replace(":id", id),
          true,
          {},
          GET_PRESCRIPTION_SUCCESS,
          GET_PRESCRIPTION_ERROR
        )
      );
    },
    HandleFormSubmit: (
      method,
      endpoint,
      authRequired,
      data,
      successType,
      errorType,
      localAction,
      includeFile,
      files,
      formId,
      reload
    ) => {
      dispatch(
        apiRequestAction(
          method,
          endpoint,
          authRequired,
          data,
          successType,
          errorType,
          localAction,
          includeFile,
          files,
          formId,
          reload
        )
      );
    },
    OnEditForm: (formId, formArrayId) => {
      dispatch(onEditForm(formId, formArrayId));
    },
    SetPrescriptionReducer: (data) => {
      dispatch(setPrescriptionReducer(data));
    },
    ToggleRightMenuState: () => {
      dispatch(toggleRightMenuState());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionMain);
