import { CoreLayoutItem, AppContainerLayout } from "@wrappid/core";

import AppointedAssistants from "./AppointedAssistants";

export default function ManageAssistant() {
  return(
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <AppointedAssistants />
      </CoreLayoutItem>
    </>
  );
}