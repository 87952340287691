import React from "react";

import {
  CoreDivider, CoreForm,
  FORM_EDIT_MODE,
  FORM_IDS,
  FORM_VIEW_MODE
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

export default function AppointmentSearchPatientComp() {
  const dispatch = useDispatch();
  const prescriptionNavData = useSelector((state) => state.prescription.navData);

  React.useEffect(() => {
    dispatch({});
  }, []);

  return (
    <>
      {prescriptionNavData && prescriptionNavData?.Person ? (
        <>
          <CoreForm
            allowDelete={false}
            formId={FORM_IDS.__PRESCRIPTION_PATIENT_PROFILE}
            // initData={getPrescriptionInitData(
            //     { prescriptionNavData: prescriptionNavData },
            //     FORM_IDS.__PRESCRIPTION_PATIENT_PROFILE
            // )}
            mode={FORM_VIEW_MODE}
            allowEdit={false}
            onMountRead={false}
          />

          <CoreDivider />
        </>
      ) : (
        <CoreForm formId={FORM_IDS.__SEARCH_PATIENT} mode={FORM_EDIT_MODE} allowEdit={true} />
      )}
    </>
  );
}
