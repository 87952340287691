export const searchClinic = {
  getOptionLabel: (data) => {
    return data && data?.fullName ? data?.fullName : "";
  },
  // -- onChangeDispatch: setClinic,
  getOptionValue: (data) => {
    return data;
  },
  isOptionsEqualToValue: (option, value) => {
    return option?.id === value?.id;
  },
};