
import {
  CoreTypographyCaption,
  CoreClasses,
  CoreGrid,
  CoreBox,
  CoreAvatar,
  CoreTypographyBody1,
  CoreIconText,
  CoreTypographySubtitle2,
  __IconTypes,
  CoreComponent,
  CoreTypographyBody2,
  CoreStack
} from "@wrappid/core";

export default function AppointmentDetailsCard(props) {
  const {
    patientId,
    startTime,
    endTime,
    date,
    Patient = {},
    Clinic = {},
  } = props || {};

  return (
    <>
      <CoreGrid styleClasses={[CoreClasses.PADDING.PL1]}>
        <CoreBox
          gridProps={{ gridSize: 1 }}
          styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}
        >

          <CoreAvatar
            styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_LARGE]}
            src={Patient?.photoUrl}
          />
        </CoreBox>

        <CoreBox gridProps={{ gridSize: 11 }} styleClasses={[CoreClasses.PADDING.PL2]}>
          <CoreTypographyBody1
            /*  styleClasses={[CoreClasses.TEXT.TEXT_WEIGHT_BOLD]} */
          >
            {[Patient?.firstName, Patient?.middleName, Patient?.lastName].join(" ")}
          </CoreTypographyBody1>

          <CoreTypographyCaption>
            {"MRN: "}
            
            <CoreTypographyCaption
              component="span"
              styleClasses={[CoreClasses.COLOR.TEXT_PRIMARY_DARK]}
                  
            >
              {Patient?.profileId || "N/A"}
            </CoreTypographyCaption>
          </CoreTypographyCaption>

          <CoreStack styleClasses={[CoreClasses.MARGIN.MT1]} direction="row" spacing={1}>
            <CoreIconText
              type={__IconTypes.MATERIAL_OUTLINED_ICON}
              icon="event_available"
              text={date}
            />

            <CoreIconText
              type={__IconTypes.MATERIAL_OUTLINED_ICON}
              icon="query_builder"
              text={`${startTime?.substring(0, 5)} - ${endTime?.substring(0, 5)}`}
            />
          </CoreStack>

          <CoreStack direction="row" spacing={1} styleClasses={[CoreClasses.MARGIN.MT1]}>
            {Clinic?.photoUrl && (
              <CoreAvatar
                styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_SMALL]}
                src={Clinic?.photoUrl}
              />
            )}

            <CoreTypographySubtitle2 styleClasses={[CoreClasses.MARGIN.ML1, CoreClasses.COLOR.TEXT_SECONDARY_DARK]} limitChars={12} hideSeeMore={true}>
              {Clinic?.name || "No Clinic"}
            </CoreTypographySubtitle2>
          </CoreStack>
        </CoreBox>
      </CoreGrid>

      <CoreTypographyBody2 styleClasses={[CoreClasses.MARGIN.ML2, CoreClasses.MARGIN.MT3, CoreClasses.COLOR.TEXT_SECONDARY_DARK]}>{"Past Appointments"}</CoreTypographyBody2>

      <CoreBox styleClasses={[CoreClasses.MARGIN.ML2, CoreClasses.MARGIN.MT3]}>
        <CoreComponent componentName={"PastAppointments"} patientID={patientId} />
      </CoreBox>
    </>

  );
}
