import { useState, useEffect } from "react";

import {
  CoreDivider,
  CoreForm,
  CoreGrid,
  FORM_EDIT_MODE,
  FORM_IDS,
  FORM_VIEW_MODE
} from "@wrappid/core";
import { useSelector } from "react-redux";

function AppSpecificProfileSection() {

  const [formsMode, setFormsMode] = useState({
    [FORM_IDS.__PROFILE_BASIC]       : FORM_VIEW_MODE,
    [FORM_IDS.__PROFILE_CONTACT]     : FORM_VIEW_MODE,
    [FORM_IDS.__PROFILE_CLINIC]      : FORM_VIEW_MODE,
    [FORM_IDS.__PROFILE_REGISTRATION]: FORM_VIEW_MODE,
    [FORM_IDS.__PROFILE_EDUCATION]   : FORM_VIEW_MODE,
    [FORM_IDS.__PROFILE_EXPERIENCE]  : FORM_VIEW_MODE,
  });

  const profile = useSelector((state) => state.profile); // --- profile, addresses, basic, contact, educations, experiences, registration
  const auth = useSelector((state) => state.auth); // --- auth, navData
  const state = useSelector((state) => state); // --- auth, navData

  // eslint-disable-next-line no-console
  console.log("STATE", state);

  // --- componentDidMount
  useEffect(() => {}, []);

  // eslint-disable-next-line no-unused-vars
  const ToggleMode = (formId) => {
    setFormsMode({
      ...formsMode,
      [formId]:
        formsMode[formId] === FORM_VIEW_MODE ? FORM_EDIT_MODE : FORM_VIEW_MODE,
    });
  };

  return (
    <>
      <CoreGrid columnSpacing={6}>
        {auth?.role?.role &&
                  auth?.role?.role.toLowerCase() === "doctor" && (
          <CoreForm
            coreId="registrationInfo"
            formId={FORM_IDS.__PROFILE_REGISTRATION}
            allowDelete={false}
            gridProps={{
              gridSize: {
                sm: 6,
                xs: 12,
              },
            }}
            mode={formsMode[FORM_IDS.__PROFILE_REGISTRATION]}
          />
        )}

        {auth?.role?.role &&
                  auth?.role?.role.toLowerCase() === "doctor" && (
          <CoreForm
            arrayView={true}
            coreId="clinicInfo"
            formId={FORM_IDS.__PROFILE_CLINIC}
            gridProps={{
              gridSize: {
                sm: 6,
                xs: 12,
              },
            }}
            mode={formsMode[FORM_IDS.__PROFILE_CLINIC]}
            arrayDataLimit={2}
            query={{
              _defaultFilter: encodeURIComponent(
                JSON.stringify({ personId: profile?.basic?.id })
              ),
            }}
          />
        )}

        {auth?.role?.role &&
                  auth?.role?.role.toLowerCase() === "doctor" && <CoreDivider />}
      </CoreGrid>
    </>
  );
}

export default AppSpecificProfileSection;
