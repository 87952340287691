
import { LOGOUT_SUCCESS } from "@wrappid/core";

import {
  RESET_APPOINTMENT_PATIENT,
  APPOINTMENT_STATUS_UPDATE_ERROR,
  APPOINTMENT_STATUS_UPDATE_SUCCESS,
  CREATE_APPOINTMENT_ERROR,
  CREATE_APPOINTMENT_SUCCESS,
  EDIT_APPOINTMENT_ERROR,
  EDIT_APPOINTMENT_SUCCESS,
  GET_APPOINTMENT_ERROR,
  GET_APPOINTMENT_SUCCESS,
  SET_APPOINTMENT_PATIENT,
  SET_APPOINTMENT_REDUCER,
  SET_APPOINTMENT_REDUCER_VALUE,
  GET_SELECTED_APPOINTMENT_SUCCESS,
  GET_SELECTED_APPOINTMENT_ERROR
} from "../types/appointmentTypes";

const initState = {
  appointmentData         : null,
  appointmentDetails      : {},
  appointmentId           : null,
  appointmentStatusError  : false,
  appointmentStatusSuccess: false,

  createAppoinmentError    : false,
  createAppoinmentIsWarning: false,
  createAppoinmentLoading  : false,
  createAppoinmentMsg      : null,
  createAppoinmentSuccess  : false,
  editAppointmentData      : null,

  editAppointmentError  : false,
  editAppointmentMsg    : null,
  editAppointmentSuccess: false,
  getAppointmentError   : false,

  getAppointmentMessage: null,
  getAppointmentSuccess: false,

  patient: null,
};

const appointmentReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointmentData      : action.payload?.data,
        getAppointmentError  : false,
        getAppointmentMessage: action.payload?.message,
        getAppointmentSuccess: true,
      };

    case GET_APPOINTMENT_ERROR:
      return {
        ...state,
        appointmentData      : action.payload?.data,
        getAppointmentError  : true,
        getAppointmentMessage: action.payload?.message,
        getAppointmentSuccess: false,
      };

    case CREATE_APPOINTMENT_SUCCESS:
      // -- swal("Success", action.payload?.message || "Appointment Created Successfully", "success");
      return {
        ...state,
        appointmentId          : action.payload?.data?.id,
        createAppoinmentError  : false,
        createAppoinmentLoading: false,
        createAppoinmentMsg    : action.payload?.message,
        createAppoinmentSuccess: true,
      };

    case CREATE_APPOINTMENT_ERROR:
      return {
        ...state,
        appointmentId            : null,
        createAppoinmentError    : true,
        createAppoinmentIsWarning: action.payload?.data?.data?.isWarning,
        createAppoinmentLoading  : false,
        createAppoinmentMsg      : action.payload?.data?.message,
        createAppoinmentSuccess  : false,
        createAppointmentOldData : action.payload?.data?.data?.oldData,
      };

    case SET_APPOINTMENT_REDUCER_VALUE:
      return {
        ...state,
        ...action.payload
      };

    case EDIT_APPOINTMENT_SUCCESS:
      // eslint-disable-next-line no-console
      console.log("action", action);
      // -- swal("Success", action.payload?.message || "Appointment Edited Successfully", "success");
      return {
        ...state,
        editAppointmentData   : action.payload?.data,
        editAppointmentError  : false,
        editAppointmentMsg    : action.payload?.message,
        editAppointmentSuccess: true,
      };

    case EDIT_APPOINTMENT_ERROR:
      // eslint-disable-next-line no-console
      console.log("action", action);
      return {
        ...state,
        editAppointmentData   : action.payload?.data?.data,
        editAppointmentError  : true,
        editAppointmentMsg    : action.payload?.data?.message,
        editAppointmentSuccess: false,
      };

    case SET_APPOINTMENT_PATIENT:
      return {
        ...state,
        patient: action?.payload,
      };

    case RESET_APPOINTMENT_PATIENT:
      return {
        ...state,
        patient: null,
      };

    case APPOINTMENT_STATUS_UPDATE_SUCCESS:
      return { ...state, appointmentStatusError: false, appointmentStatusSuccess: true };

    case APPOINTMENT_STATUS_UPDATE_ERROR:
      // -- swal("Oops!", action.payload?.data?.message || "Error to update appointment status", "error");
      return { ...state, appointmentStatusError: true, appointmentStatusSuccess: false };

    case SET_APPOINTMENT_REDUCER:
      return { ...state, ...action.data };

    case GET_SELECTED_APPOINTMENT_SUCCESS:
      return { ...state, appointmentDetails: action?.payload };

    case GET_SELECTED_APPOINTMENT_ERROR:
      return { ...state, appointmentDetails: null };

    case LOGOUT_SUCCESS:
      return initState;

    default:
      return state;
  }
};

export default appointmentReducer;
