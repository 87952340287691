import {
  CoreDivider,
  CoreLabel,
  CoreTypographyBody1,
  CoreTypographyBody2,
  CoreBox,
  CoreGrid,
  CoreClasses
} from "@wrappid/core";
import moment from "moment";

export default function consultationTimingCard(props) {
  const { clinic } = props;
  const days = [
    { label: "Monday", value: 2 },
    { label: "Tuesday", value: 3 },
    { label: "Wednesday", value: 4 },
    { label: "Thursday", value: 5 },
    { label: "Friday", value: 6 },
    { label: "Saturday", value: 7 },
    { label: "Sunday", value: 1 },
  ];

  return (
    <>
      <CoreBox styleClasses={[CoreClasses.MARGIN.MY1, CoreClasses.PADDING.P1]}>
        <CoreTypographyBody1 styleClasses={[CoreClasses.TEXT.TEXT_TRUNCATE]}>
          Timings - {clinic?.fullName || "Personal"}
        </CoreTypographyBody1>

        <CoreGrid>
          {days.map((day, index) => {
            return (
              <CoreBox
                key={`consult-days-${index}`}
                gridProps={{ gridSize: { sm: 6 } }}
              >
                <CoreLabel>{day.label}</CoreLabel>

                {props[day.value] ? (
                  props[day.value].map((times, index) => (
                    <CoreTypographyBody2 key={`times-${index}`}>
                      {moment(times.startTime).format("LT") +
                        " - " +
                        moment(times.endTime).format("LT")}
                    </CoreTypographyBody2>
                  ))
                ) : (
                  <CoreTypographyBody2
                    styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY]}
                  >
                    Not available
                  </CoreTypographyBody2>
                )}
              </CoreBox>
            );
          })}
        </CoreGrid>
      </CoreBox>

      <CoreDivider />
    </>
  );
}
