
import {
  CoreImage
  , CoreH6
  , CoreLabel
  , CoreBox
  , CoreGrid
  , CoreCard
  , CoreCardContent
  , CoreClasses
} from "@wrappid/core";

export default function RxPaymentFailure() {
  return (
    <CoreGrid>
      <CoreBox gridProps={{ gridSize: 2 }}></CoreBox>

      <CoreCard gridProps={{ gridSize: 8 }}>
        <CoreCardContent>
          <CoreH6>Payment Failed</CoreH6>

          <CoreBox styleClasses={[CoreClasses.TEXT.TEXT_CENTER]}>
            <CoreImage
              src={"./images/rx-cash.png"}
              styleClasses={[CoreClasses.RX.RX_ALERT_WALLET_ICON]}
            />

            <CoreLabel styleClasses={[CoreClasses.MARGIN.M3]}>
              Oops! Something went wrong
            </CoreLabel>

            <CoreLabel>Please check your security code, card details</CoreLabel>

            <CoreLabel>and connection and try again.</CoreLabel>

            <CoreLabel styleClasses={[CoreClasses.MARGIN.M3]}>Error code: 12345</CoreLabel>

            <CoreLabel styleClasses={[CoreClasses.RX.RX_RECHARGE_DISCLAIMER]}>
              Raise your concern.
            </CoreLabel>

            <CoreLabel styleClasses={[CoreClasses.RX.RX_RECHARGE_DISCLAIMER_TERMS_AND_CONDITIONS]}>
              Contact Support?
            </CoreLabel>
          </CoreBox>
        </CoreCardContent>
      </CoreCard>
    </CoreGrid>
  );
}
