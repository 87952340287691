import AppointmentCard from "./components/AppointmentCard";
import AppointmentDetails from "./components/AppointmentDetails";
import AppointmentDetailsCard from "./components/AppointmentDetailsCard";
import AppointmentSearchPatientComp from "./components/AppointmentSearchPatientComp";
import AppointmentSummaryComponent from "./components/AppointmentSummaryComponent";
import CompletedAppointment from "./components/CompletedAppointment";
import CreateAppointment from "./components/CreateAppointment";
import CurrentAppointment from "./components/CurrentAppointment";
import CurrentAppointmentCard from "./components/CurrentAppointmentCard";
import ManageAppointment from "./components/ManageAppointment";
import OnHoldAppointment from "./components/OnHoldAppointment";
import PatientCard from "./components/PatientCard";
import SearchPatient from "./components/SearchPatient";
import SearchUser from "./components/SearchUser";
import SearchUserCard from "./components/SearchUserCard";
import UpcomingAppointment from "./components/UpcomingAppointment";

export const ComponentsRegistry = {
  AppointmentCard             : { comp: AppointmentCard },
  AppointmentDetails          : { comp: AppointmentDetails },
  AppointmentDetailsCard      : { comp: AppointmentDetailsCard },
  AppointmentSearchPatientComp: { comp: AppointmentSearchPatientComp },
  AppointmentSummaryComponent : { comp: AppointmentSummaryComponent },
  CompletedAppointment        : { comp: CompletedAppointment },
  CreateAppointment           : { comp: CreateAppointment },
  CurrentAppointment          : { comp: CurrentAppointment },
  CurrentAppointmentCard      : { comp: CurrentAppointmentCard },
  ManageAppointment           : { comp: ManageAppointment },
  OnHoldAppointment           : { comp: OnHoldAppointment },
  PatientCard                 : { comp: PatientCard },
  SearchPatient               : { comp: SearchPatient },
  SearchUser                  : { comp: SearchUser },
  UpcomingAppointment         : { comp: UpcomingAppointment },
  searchUserCard              : { comp: SearchUserCard }

};
