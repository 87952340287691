import { useState } from "react";

import { CoreDialogContext, CoreDialog } from "@wrappid/core";

import TestComponent from "./TestComponent";
function ParentBox() {
  const [dialog, setDialog] = useState({});
  const providerValue = { dialog, setDialog };

  return (
    <CoreDialogContext.Provider value={providerValue}>
      <TestComponent />

      <CoreDialog />
    </CoreDialogContext.Provider>
  );
}

export default ParentBox;
