import { CoreBox, CoreGrid } from "@wrappid/core";

import TemplateSection from "./TemplateSection";
import { checkStatus, searchMasterTree } from "../../utils/helper";

export default function VitalsView({
  el,
  disabled,
  onOperate,
  present,
  vitals,
  currentTemplate,
  mainMasterData,
  allTemplates
}) {
  // eslint-disable-next-line no-console
  console.log("######s##### EL", el, present);
  return (
    <CoreGrid>
      {vitals.map((value, index) => (
        <CoreBox
          key={`vitals-value-${index}`}
          gridProps={{
            gridSize: {
              sm: value.gridSize ? value.gridSize : 4,
              xs: 12
            }
          }}
        >
          {value.type === "spacer" ? (
            <CoreBox></CoreBox>
          ) : (
            <TemplateSection
              el={value}
              disabled={disabled}
              onOperate={onOperate}
              present={checkStatus(
                value.name,
                currentTemplate,
                searchMasterTree(value, mainMasterData, 0),
                allTemplates
              )}
              gridProps={{ gridSize: { sm: 4, xs: 12 } }}
            />
          )}
        </CoreBox>
      ))}
    </CoreGrid>
  );
}
