export const ApiRegistry = {
  GET_ADDRESS_TYPES_API                : "/addressType",
  GET_DEPARTMENTS_API                  : "/department",
  GET_MASTER_DATA_API                  : "/masterData",
  GET_PRESCRIPTION_TEMPLATE_DESIGNS_API: "/prescriptionTemplateDesigns",
  GET_PROFILE_BASIC_API                : "/business/individual/ProfileBasic",
  GET_PROFILE_CONTACT_API              : "/rx/contactInfo",
  GET_ROLE_PERMISSIONS_API             : "/rolePermission",
  GET_SETTINGS_META_API                : "/settingMeta",
  GET_USER_SETTINGS                    : "/userSettings",
};
