import {
  ACCEPT_INVITATION_ERROR,
  ACCEPT_INVITATION_SUCCESS,
  SENT_ASSISTANT_INVITATION_ERROR,
  SENT_ASSISTANT_INVITATION_SUCCESS,
  SET_MANAGE_ASSISTANT_REDUCER_SUCCESS,
  LOGOUT_SUCCESS,
  ASSISTANT_TERMINATION_SUCCESS,
  ASSISTANT_TERMINATION_ERROR
} from "../types/manageAssistantTypes";

const initState = {
  acceptInvitationError      : false,
  acceptInvitationMsg        : null,
  acceptInvitationSuccess    : false,
  sentAssistantInvitationData: true,

  sentAssistantInvitationError  : false,
  sentAssistantInvitationMsg    : null,
  sentAssistantInvitationSuccess: false,
};

const manageAssistantReducer = (state = initState, action) => {
  switch (action.type) {
    case SENT_ASSISTANT_INVITATION_SUCCESS:
      return {
        ...state,
        sentAssistantInvitationData   : action.payload?.data,
        sentAssistantInvitationError  : false,
        sentAssistantInvitationMsg    : action.payload?.message,
        sentAssistantInvitationSuccess: true,
      };

    case SENT_ASSISTANT_INVITATION_ERROR:
      return {
        ...state,
        sentAssistantInvitationData   : null,
        sentAssistantInvitationError  : true,
        sentAssistantInvitationMsg    : action.payload?.data?.message,
        sentAssistantInvitationSuccess: false,
      };

    case SET_MANAGE_ASSISTANT_REDUCER_SUCCESS:
      return {
        ...state,
        ...action.data,
      };

    case ACCEPT_INVITATION_SUCCESS:
      return {
        ...state,
        acceptInvitationError  : false,
        acceptInvitationMsg    : action.payload?.message,
        acceptInvitationSuccess: true,
      };

    case ACCEPT_INVITATION_ERROR:
      return {
        ...state,
        acceptInvitationError  : true,
        acceptInvitationMsg    : action.payload?.data?.message,
        acceptInvitationSuccess: false,
      };

    case ASSISTANT_TERMINATION_SUCCESS:
      return {
        ...state,
        assistantTerminationError  : false,
        assistantTerminationMsg    : action.payload?.message,
        assistantTerminationSuccess: true,
      };

    case ASSISTANT_TERMINATION_ERROR:
      return {
        ...state,
        assistantTerminationError  : true,
        assistantTerminationMsg    : action.payload?.message,
        assistantTerminationSuccess: false,
      };

    case LOGOUT_SUCCESS:
      return initState;

    default:
      return state;
  }
};

export default manageAssistantReducer;
