import {
  CoreBox,
  CoreTypographyBody1
} from "@wrappid/core";

function SystemAdminDashboard() {
  return (
    <CoreBox>
      <CoreTypographyBody1>System Admin</CoreTypographyBody1>

      {/* -- <TestComponent /> */}

      {/* -- <CoreComponent componentName={"AppLogoGif"} /> */}

      {/* <CoreBox
        styleClasses={[
          CoreClasses.BORDER.BORDER,
          CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER,
          CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER,
          CoreClasses.PADDING.P5,
          CoreClasses.MARGIN.M5,
        ]}
        // style={{ borderStyle: "dashed", color: "grey" }}
      >
        <CoreIcon fontSize={"medium"}>{"add"}</CoreIcon>
      </CoreBox>

      <CoreButton
        label="Show ProgressBar"
        onClick={() => {
          dispatch({ type: "SET_PROGRESS_BAR" });
        }}
      ></CoreButton>

      <CoreButton
        label="Hide ProgressBar"
        onClick={() => {
          dispatch({ type: "RESET_PROGRESS_BAR" });
        }}
      ></CoreButton> */}
    </CoreBox>
  );
}

export default SystemAdminDashboard;
