import { RoutesRegistry as AppBuilderRoutesRegistry } from "./app-builder/routes.registry";
import { RoutesRegistry as AppointmentRoutesRegistry } from "./appointment/routes.registry";
import { RoutesRegistry as AssistantRoutesRegistry } from "./assistant/routes.registry";
import { RoutesRegistry as AuthRoutesRegistry } from "./auth/routes.registry";
import { RoutesRegistry as ClinicRoutesRegistry } from "./clinic/routes.registry";
import { RoutesRegistry as DashboardRoutesRegistry } from "./dashboard/routes.registry";
import { RoutesRegistry as DataManagementRoutesRegistry } from "./data-management/routes.registry";
import { RoutesRegistry as GuideRoutesRegistry } from "./guide/routes.registry";
import { RoutesRegistry as PatientRoutesRegistry } from "./patient/routes.registry";
import { RoutesRegistry as PaymentRoutesRegistry } from "./payment/routes.registry";
import { RoutesRegistry as PrescriptionRoutesRegistry } from "./prescription/routes.registry";
import { RoutesRegistry as ProfileRoutesRegistry } from "./profile/routes.registry";
import { RoutesRegistry as SettingsRoutesRegistry } from "./settings/routes.registry";
import { RoutesRegistry as UserManagementRoutesRegistry } from "./user-management/routes.registry";

export default {
...AppBuilderRoutesRegistry,
...AppointmentRoutesRegistry,
...AssistantRoutesRegistry,
...AuthRoutesRegistry,
...ClinicRoutesRegistry,
...DashboardRoutesRegistry,
...DataManagementRoutesRegistry,
...GuideRoutesRegistry,
...PatientRoutesRegistry,
...PaymentRoutesRegistry,
...PrescriptionRoutesRegistry,
...ProfileRoutesRegistry,
...SettingsRoutesRegistry,
...UserManagementRoutesRegistry,
}