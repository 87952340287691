import { FunctionsRegistry as AppBuilderFunctionsRegistry } from "./app-builder/functions.registry";
import { FunctionsRegistry as AppointmentFunctionsRegistry } from "./appointment/functions.registry";
import { FunctionsRegistry as AssistantFunctionsRegistry } from "./assistant/functions.registry";
import { FunctionsRegistry as AuthFunctionsRegistry } from "./auth/functions.registry";
import { FunctionsRegistry as ClinicFunctionsRegistry } from "./clinic/functions.registry";
import { FunctionsRegistry as DashboardFunctionsRegistry } from "./dashboard/functions.registry";
import { FunctionsRegistry as DataManagementFunctionsRegistry } from "./data-management/functions.registry";
import { FunctionsRegistry as GuideFunctionsRegistry } from "./guide/functions.registry";
import { FunctionsRegistry as PatientFunctionsRegistry } from "./patient/functions.registry";
import { FunctionsRegistry as PaymentFunctionsRegistry } from "./payment/functions.registry";
import { FunctionsRegistry as PrescriptionFunctionsRegistry } from "./prescription/functions.registry";
import { FunctionsRegistry as ProfileFunctionsRegistry } from "./profile/functions.registry";
import { FunctionsRegistry as SettingsFunctionsRegistry } from "./settings/functions.registry";
import { FunctionsRegistry as UserManagementFunctionsRegistry } from "./user-management/functions.registry";

export default {
...AppBuilderFunctionsRegistry,
...AppointmentFunctionsRegistry,
...AssistantFunctionsRegistry,
...AuthFunctionsRegistry,
...ClinicFunctionsRegistry,
...DashboardFunctionsRegistry,
...DataManagementFunctionsRegistry,
...GuideFunctionsRegistry,
...PatientFunctionsRegistry,
...PaymentFunctionsRegistry,
...PrescriptionFunctionsRegistry,
...ProfileFunctionsRegistry,
...SettingsFunctionsRegistry,
...UserManagementFunctionsRegistry,
}