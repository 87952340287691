import {
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_ERROR
} from "../types/address.types";

const initState = { addressDetails: null };

const addressReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ADDRESS_SUCCESS:
      return {
        ...state,
        addressDetails: action?.payload[0]?.PostOffice[0]
      };
    
    case GET_ADDRESS_ERROR:
      return {
        ...state,
        addressReducer: null,
      };

    default:
      return state;
  }
};

export default addressReducer;
