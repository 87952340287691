import { getFormikRequiredMessage } from "@wrappid/core";
import { string, mixed } from "yup";

const indianPhoneRegex = /^\+?([ -这让\u0915-\u0939])\d{9}$/;

export const editClinic = {
  clinicLogo: mixed()
    .test("fileSize", "Logo size is too large", (value) => {
      if (!value) {
        return true; // Allow empty value (optional logo)
      }
      if(typeof value === "string"){
        return true;
      }
      return value.size <= 5 * 1024 * 1024; // Check if file size is less than 5MB
    })
    .test("fileType", "Invalid logo format", (value) => {
      if (!value) {
        return true; // Allow empty value (optional logo)
      }
      if(typeof value === "string"){
        return true;
      }
      const supportedTypes = ["image/jpeg", "image/png"];

      return supportedTypes.includes(value.type);
    }),
  email: string()
    .required("Email is required")
    .email("Invalid email format"),
  fullName: 
    string()
      .trim()
      .required(getFormikRequiredMessage("firstName"))
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed for this field "),
  phone: string()
    .required("Phone number is required")
    .matches(indianPhoneRegex, "Invalid phone number format")
    .required("Phone number is required")  
    .test("startsWithValidPrefix", "Phone number must start with a valid prefix (6-9)", (value) => {
      if (!value) {
        return false; // Allow empty string for optional fields
      }
      const firstDigit = value[0] || value[1]; // Handle cases with or without '+'
  
      return firstDigit >= 6 && firstDigit <= 9;
    }),
};