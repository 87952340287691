// asyncSelect and formSubmitSanitization

import { getBmi, prescriptionAsyncSelects, searchPatient } from "./functions/asyncSelect.functions";
import { SanPrescription } from "./functions/sanity.functions";

export const FunctionsRegistry = {
  SanPrescription: SanPrescription,

  __PRESCRIPTIONASYNCSELECTS_GET_OPTION_LABEL         : prescriptionAsyncSelects.getOptionLabel,
  __PRESCRIPTIONASYNCSELECTS_GET_OPTION_VALUE         : prescriptionAsyncSelects.getOptionValue,
  __PRESCRIPTIONASYNCSELECTS_IS_OPTIONS_EQUAL_TO_VALUE: prescriptionAsyncSelects.isOptionEqualToValue,
  __SEARCHPATIENT_GET_OPTION_LABEL                    : searchPatient.getOptionLabel,

  __SEARCHPATIENT_GET_OPTION_VALUE         : searchPatient.getOptionValue,
  __SEARCHPATIENT_IS_OPTIONS_EQUAL_TO_VALUE: searchPatient.isOptionsEqualToValue,
  __SEARCHPATIENT_ON_CHANGE_DISPATCH       : searchPatient.onChangeDispatch,
    
  getBmi: getBmi,
};
