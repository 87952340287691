import { useContext, useEffect, useState } from "react";

import {
  AppContainerLayout,
  CORE_DIALOG_TYPES,
  CoreDialogContext,
  CoreLayoutItem,
  CoreRouteRegistryContext,
  coreUseLocation,
  coreUseNavigate,
  coreUseParams
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import PrescriptionMain from "./PrescriptionMain";
import { clearPrescription, setPrescriptionReducer } from "../actions/prescription.action";

function getErrorText(missingData) {
  let mData = {
    email    : "email",
    firstName: "first name",
    gender   : "gender",
    phone    : "phone number",
    photoUrl : "profile picture",
    regDate  : "registration date",
    regNo    : "registration no."
  };

  let errorText = "Your profile is not complete, add the following details: ";

  let mDataKeys = Object.keys(missingData);

  for (let i = 0; i < mDataKeys.length; i++) {
    errorText += mData[mDataKeys[i]] + ", ";
  }

  return errorText;
}

export default function Prescription() {
  const location = coreUseLocation();
  const [idInUrl, setIdInUrl] = useState(null);
  const routeRegistry = useContext(CoreRouteRegistryContext);
  const dialogContext = useContext(CoreDialogContext);
  const dispatch = useDispatch();
  const navigate = coreUseNavigate();
  const prescription = useSelector(
    (state) => state.prescription?.prescription
  );
  const downloadPrescriptionError = useSelector(
    (state) => state.prescription?.downloadPrescriptionError
  );
  const downloadPrescriptionSuccess = useSelector(
    (state) => state.prescription?.downloadPrescriptionSuccess
  );
  const downloadPrescriptionMsg = useSelector(
    (state) => state.prescription?.downloadPrescriptionMsg
  );

  const completeness = useSelector(state=>state.profile?.completeness?.report);

  const params = coreUseParams();

  useEffect(() => {
    let { quotient, missingData } = completeness || {};

    if (quotient && quotient < 100) {
      dialogContext?.setDialog({
        doneButton: () => {
          navigate(routeRegistry?.profile);
        },
        doneButtonLabel: "Okay",
        noCancelButton : true,
        showDialog     : true,
        subtitle       : getErrorText(missingData),
        title          : "You profile is not complete!",
        type           : "info"
      });
    }

    if (prescription?.id && dialogContext?.setDialog) {
      dialogContext?.setDialog({
        cancelButton: () => {
          // eslint-disable-next-line no-console
          console.log("This is cancel button");
        },
        cancelButtonLabel: "Continue",
        doneButton       : () => {
          dispatch(clearPrescription(prescription?.id));
        },
        doneButtonLabel: "Clear",
        showDialog     : true,
        subtitle       : "Would you like you continue or start fresh",
        title          : "You already have a prescription !",
        type           : "info"
      });
    }

    if(params?.id){
      setIdInUrl(params?.id);
    }

    if(!location?.state?.fromAppointment && !location?.state?.fromPatient && !prescription?.id){
      dispatch(setPrescriptionReducer({ clinic: null, navData: null }));
    }
  }, []);

  useEffect(()=>{
    if(downloadPrescriptionSuccess){
      dialogContext?.setDialog({
        showDialog: true,
        subtitle  : downloadPrescriptionMsg || "Prescription downloaded successfully!",
        title     : "Success",
        type      : CORE_DIALOG_TYPES?.SUCCESS
      });
      dispatch(setPrescriptionReducer({
        downloadPrescriptionError  : false,
        downloadPrescriptionSuccess: false,
      }));
      dispatch(setPrescriptionReducer({
        downloadPrescriptionError  : false,
        downloadPrescriptionSuccess: false,
      }));
    }
    if(downloadPrescriptionError){
      dialogContext?.setDialog({
        showDialog: true,
        subtitle  : downloadPrescriptionMsg || "Prescription downloaded error!",
        title     : "Error",
        type      : CORE_DIALOG_TYPES?.FAILURE
      });
      dispatch(setPrescriptionReducer({
        downloadPrescriptionError  : false,
        downloadPrescriptionSuccess: false,
      }));
    }

  }, [downloadPrescriptionError, downloadPrescriptionSuccess]);

  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <PrescriptionMain idInUrl={idInUrl} />
      </CoreLayoutItem>
    </>
  );
}
