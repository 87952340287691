import { queryBuilder } from "@wrappid/core";
import { WrappidData } from "@wrappid/styles";

let { config } = WrappidData;
let { backendUrl } = config;

export const startPayment = (id, body, token) => {
  return (dispatch) => {
    dispatch({ type: "START_PAYMENT_LOADING" });
    let newUrl = "/user/" + id + "/startPayment";

    fetch(backendUrl + newUrl, {
      body   : JSON.stringify(body),
      headers: {
        Accept        : "application/json",
        Authorization : `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "post",
    })
      .then((res) =>
        res.json().then((data) => {
          if (res.status === 200) {
            dispatch({
              data   : data.data,
              message: data.message,
              type   : "START_PAYMENT_SUCCESS",
            });
          } else if (res.status === 403 || res.status === 401) {
            dispatch({ type: "AUTHENTICATION_ERROR" });
          } else {
            dispatch({
              message: data.message,
              type   : "START_PAYMENT_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);

        dispatch({
          message: "Internal Error",
          type   : "START_PAYMENT_ERROR",
        });
      });
  };
};

export const getPaymentHistory = (uid, query, token) => {
  return (dispatch) => {
    dispatch({ type: "GET_PAYMENTS_LOADING" });
    let newUrl = queryBuilder("/user/" + uid + "/getPaymentHistory", query);

    fetch(backendUrl + newUrl, {
      headers: {
        Accept        : "application/json",
        Authorization : `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) =>
        res.json().then((data) => {
          if (res.status === 200) {
            dispatch({
              data   : data.data,
              message: data.message,
              type   : "GET_PAYMENTS_SUCCESS",
            });
          } else if (res.status === 403 || res.status === 401) {
            dispatch({ type: "AUTHENTICATION_ERROR" });
          } else {
            dispatch({
              message: data.message,
              type   : "GET_PAYMENTS_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);

        dispatch({
          message: "Internal Error",
          type   : "GET_PAYMENTS_ERROR",
        });
      });
  };
};

export const getTokenDetail = (uid, token) => {
  return (dispatch) => {
    dispatch({ type: "GET_TOKEN_DETAIL_LOADING" });
    let newUrl = "/user/" + uid + "/rxCash";

    fetch(backendUrl + newUrl, {
      headers: {
        Accept        : "application/json",
        Authorization : `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) =>
        res.json().then((data) => {
          if (res.status === 200) {
            dispatch({
              data   : data.data,
              message: data.message,
              type   : "GET_TOKEN_DETAIL_SUCCESS",
            });
          } else if (res.status === 403 || res.status === 401) {
            dispatch({ type: "AUTHENTICATION_ERROR" });
          } else {
            dispatch({
              message: data.message,
              type   : "GET_TOKEN_DETAIL_ERROR",
            });
          }
        })
      )
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
                
        dispatch({
          message: "Internal Error",
          type   : "GET_TOKEN_DETAIL_ERROR",
        });
      });
  };
};

export const setPaymentReducer = (data) => {
  return (dispatch) => {
    dispatch({ data, type: "SET_PAYMENT_REDUCER" });
  };
};

export const toggleRxCash = () => {
  return (dispatch) => {
    dispatch({ type: "TOGGLE_RX_CASH" });
  };
};
