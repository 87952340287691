/* eslint-disable import/no-unresolved */

import {
  CoreBox,
  CoreCard,
  CoreCardContent,
  CoreForm,
  CoreGrid,
  CoreComponent,
  CoreStack,
  FORM_EDIT_MODE,
  FORM_IDS
} from "@wrappid/core";
import moment from "moment";
import { useSelector } from "react-redux";

import CounterWidget from "./CounterWidget";

export default function DoctorDashboard() {
  const personID = useSelector((state) => state?.profile?.basic?.id);

  return (
    <CoreGrid>
      <CoreBox gridProps={{ gridSize: { md: 9 } }}>
        {getCounterWidgets(personID)}
      </CoreBox>

      <CoreBox gridProps={{ gridSize: { md: 3 } }}>
        <CoreStack spacing={1}>
          <CoreComponent componentName={"ProfileCompletenessCard"} />

          <CoreCard>
            <CoreCardContent>
              <CoreForm
                formId={FORM_IDS.__RX_RECHARGE}
                mode={FORM_EDIT_MODE}
                allowDelete={false}
              />
            </CoreCardContent>
          </CoreCard>
        </CoreStack>
      </CoreBox>
    </CoreGrid>
  );
}

const getCounterWidgets = (personID) => {
  return (
    <CoreGrid>
      {getAppointmentCounterWidget(personID)}

      {getPrescriptionCounterWidget(personID)}

      {getPatientCounterWidget(personID)}

      {getClinicCounterWidget(personID)}
    </CoreGrid>
  );
};

const getAppointmentCounterWidget = (personID) => {
  return (
    <CounterWidget
      loading={personID ? false : true}
      gridProps={{ gridSize: { sm: 3, xs: 6 } }}
      widgetId={"appointments"}
      heading={"Appointments"}
      // -- icon={{
      //   icon: "fa-calendar-check",
      //   type: CoreIcon.FONTAWESOME_V5_SOLID_ICON,
      // }}
      options={{
        counters: [
          {
            entity     : "UpcomingAppointments",
            filterQuery: { ["Doctor.id"]: personID, _status: "new" },
            id         : "appointments-upcoming",
            label      : "Upcoming",
            primary    : true,
          },
          {
            entity     : "CompletedAppointments",
            filterQuery: { ["Doctor.id"]: personID, _status: "completed" },
            id         : "appointments-completed",
            label      : "Completed",
          },
        ],
        entity: "MyPatient",
      }}
    />
  );
};
const getPatientCounterWidget = (personID) => {
  return (
    <CounterWidget
      loading={personID ? false : true}
      gridProps={{ gridSize: { sm: 3, xs: 6 } }}
      widgetId={"patients"}
      heading={"Patients"}
      // -- icon={{ icon: "personal_injury" }}
      options={{
        counters: [
          {
            entity     : "MyPatient",
            filterQuery: { personId: personID },
            id         : "patients-active",
            label      : "Active",
            primary    : true,
          },
          {
            entity     : "MyInactivePatient",
            filterQuery: { personId: personID },
            id         : "patients-inactive",
            label      : "Inactive",
          },
        ],
        entity: "MyPatient",
      }}
    />
  );
};

const getPrescriptionCounterWidget = (personID) => {
  return (
    <CounterWidget
      loading={personID ? false : true}
      gridProps={{ gridSize: { sm: 3, xs: 6 } }}
      widgetId={"prescriptions"}
      heading={"Prescriptions"}
      // -- icon={{
      //   icon: "fa-prescription",
      //   type: CoreIcon.FONTAWESOME_V5_SOLID_ICON,
      // }}
      options={{
        counters: [
          {
            entity     : "MyPrescriptions",
            filterQuery: {
              createdAt: { gte: moment().format("yyyy/MM/DD") },
              doctorId : personID,
            },
            id     : "prescriptions-today",
            label  : "Today",
            primary: true,
          },
          {
            entity     : "MyPrescriptions",
            filterQuery: { doctorId: personID },
            id         : "prescriptions-lifetime",
            label      : "Lifetime",
          },
        ],
      }}
    />
  );
};

const getClinicCounterWidget = (personID) => {
  return (
    <CounterWidget
      loading={personID ? false : true}
      gridProps={{ gridSize: { sm: 3, xs: 6 } }}
      widgetId={"clinics"}
      heading={"Clinics"}
      // -- icon={{
      //   icon: "fa-clinic-medical",
      //   type: CoreIcon.FONTAWESOME_V5_SOLID_ICON,
      // }}
      options={{
        counters: [
          {
            entity     : "ProfileClinic",
            filterQuery: { personId: personID },
            id         : "active-clinics",
            label      : "Active",
            primary    : true,
          },
        ],
      }}
    />
  );
};
