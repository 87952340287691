/**
 * Function to get the value of the key from the data
 * @param {*} id
 * @param {*} itemKey
 * @param {*} options
 * @returns value of the key
 */
export function getFromMasterData(id, itemKey, options){
  if(isNaN(id)){
    return id;
  }
  else
    return options[itemKey]?.data?.find(master => Number(master?.id) === Number(id))?.label || "N/A";
}
/**
 * Function to return the age of the person
 * in dd month yyyy format
 * @param {*} data 
 * @returns {formatted date}
 */
export function getDOB(data){

  const dateStr = data;
  const date = new Date(dateStr);
  
  const options = { day: "2-digit", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  
  return formattedDate;
}

/**
 * Function to return the address of the person 
 * @param {*} data 
 * @returns personAddress: addressLine1, pincode and state
 */
export function getAddress(data){
  const personAddressFields = {
    addressLine: data["RelatedPerson.PersonAddresses.addLine1"],
    pincode    : data["RelatedPerson.PersonAddresses.pin"],
    state      : data["RelatedPerson.PersonAddresses.state"],
  };

  let personAddress = Object.keys(personAddressFields)
    .map(key => personAddressFields[key])
    .filter(value => value && value !== "" && value !== null && value !== "null")
    .join(", ");

  personAddress = personAddress !== "" ? personAddress : "Not Available";
  return personAddress;
}

/**
 * Function to capitalize the first letter of the string
 * @param {*} string 
 * @returns string with first letter capitalized 
 */
export function capitalizeFirstLetter(string){
  if(typeof string !== "string") 
  {
    return "";
  }else{
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

