import {
  CoreAvatar,
  CoreBox,
  CoreClasses,
  CoreIconText,
  CoreGrid,
  CoreStack,
  CoreTypographyBody1,
  CoreTypographyCaption,
  StatusText,
  __IconTypes,
  getAge
} from "@wrappid/core";
import { useSelector } from "react-redux";

import { getFromMasterData } from "../functions/helper.functions";

export default function PatientSummaryComponent(props) {
  const { rowData } = props || {};
  const options = useSelector(state => state?.selectOptions?.options);

  return (
    <>
      <CoreGrid>
        <CoreBox
          gridProps={{ gridSize: 2 }}
          styleClasses={[CoreClasses.PADDING.PT1, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
          <CoreAvatar
            styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR]}
            src={rowData["RelatedPerson.photoUrl"]}
          />
        </CoreBox>

        <CoreBox gridProps={{ gridSize: 10 }}>
          <CoreTypographyBody1 styleClasses={[CoreClasses.TEXT.TEXT_BOLD]}>
            {[rowData["RelatedPerson.firstName"], rowData["RelatedPerson.middleName"], rowData["RelatedPerson.lastName"]].join(" ")}
          </CoreTypographyBody1>

          <CoreTypographyCaption>
            MRN{": "}

            <CoreTypographyCaption
              component="span"
              styleClasses={[CoreClasses.COLOR.TEXT_PRIMARY_DARK]}>
              {rowData["RelatedPerson.profileId"] || "N/A"}
            </CoreTypographyCaption>
          </CoreTypographyCaption>

          <CoreStack direction="row" spacing={1} divider={true}>
            <CoreBox>
              <CoreIconText
                type={__IconTypes.MATERIAL_OUTLINED_ICON}
                icon="cake"
                text={getAge(rowData["RelatedPerson.dob"])}
              />
            </CoreBox>

            <CoreBox>
              <CoreIconText
                type={__IconTypes.MATERIAL_OUTLINED_ICON}
                icon={getFromMasterData(
                  rowData["RelatedPerson.gender"],
                  "gender",
                  options
                )?.toLowerCase()}
                text={getFromMasterData(
                  rowData["RelatedPerson.gender"],
                  "gender",
                  options
                )}
              />
            </CoreBox>
          </CoreStack>
        </CoreBox>
      </CoreGrid>

      <CoreStack
        direction="row"
        spacing={1}
        styleClasses={[CoreClasses.FLEX.DIRECTION_ROW, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}>
        <CoreTypographyCaption>{"ID: " + rowData["id"]}</CoreTypographyCaption>

        <CoreTypographyCaption
          styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY_DARK]}>
          {"|"}
        </CoreTypographyCaption>

        <StatusText status={rowData["_status"]} />

        <CoreTypographyCaption
          styleClasses={[
            // -- getStatusTextColorClass(rowData["_status"]),
            CoreClasses.TEXT.TEXT_WEIGHT_BOLD, CoreClasses.TEXT.TEXT_UPPERCASE,
          ]}>
          {/* -- {getLabel(rowData["_status"])} */}
        </CoreTypographyCaption>
      </CoreStack>
    </>
  );
}
