import {
  CoreAvatar, CoreBox, CoreIconText, CoreClasses, CoreStack, CoreTypographyCaption, StatusText, __IconTypes, CoreGrid, CoreTypographySubtitle2, CoreTypographySubtitle1 
} from "@wrappid/core";

export default function AppointmentSummaryComponent(props) {
  const { rowData } = props;
  const {
    Patient = { firstName: "", lastName: "", middleName: "" },
    Clinic = { name: "" },
  } = rowData;
  
  return (
    <>
      <CoreGrid styleClasses={[CoreClasses?.FLEX?.DIRECTION_ROW, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
        <CoreBox
          gridProps={{ gridSize: 2 }}
          styleClasses={[CoreClasses?.FLEX?.DIRECTION_ROW, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}
        >
          <CoreAvatar
            styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR]}
            src={Patient?.photoUrl}
          />
        </CoreBox>

        <CoreBox gridProps={{ gridSize: 10 }}>
          <CoreTypographySubtitle1 styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY_DARK]}>
            {[Patient["firstName"], Patient["middleName"], Patient["lastName"]].join(" ")}
          </CoreTypographySubtitle1>

          <CoreTypographyCaption>
            MRN{": "}

            <CoreTypographyCaption
              component="span"
              styleClasses={[CoreClasses.COLOR.TEXT_PRIMARY_DARK]}
            >
              {Patient["profileId"] || "N/A"}
            </CoreTypographyCaption>
          </CoreTypographyCaption>
        </CoreBox>
      </CoreGrid>

      <CoreBox styleClasses={[
        CoreClasses?.FLEX?.DIRECTION_ROW,
        CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_SPACE_BETWEEN,
        CoreClasses.MARGIN.MT1,
        CoreClasses.MARGIN.ML1,
        CoreClasses.MARGIN.MR2
      ]}>
        <CoreIconText
          type={__IconTypes.MATERIAL_OUTLINED_ICON}
          icon="event_available"
          text={rowData["date"]}
        />

        <CoreIconText
          type={__IconTypes.MATERIAL_OUTLINED_ICON}
          icon="query_builder"
          text={`${rowData["startTime"]?.substring(0, 5)} - ${rowData["endTime"]?.substring(0, 5)}`}
        />
      </CoreBox>

      <CoreBox styleClasses={[CoreClasses?.FLEX?.DIRECTION_ROW, CoreClasses.MARGIN.ML1, CoreClasses.ALIGNMENT.ALIGN_CONTENT_CENTER]}>
        {Clinic?.photoUrl && (
          <CoreAvatar
            styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_SMALL]}
            src={Clinic?.photoUrl}
          />
        )}

        <CoreTypographySubtitle2 styleClasses={[CoreClasses.MARGIN.ML1, CoreClasses.COLOR.TEXT_SECONDARY_DARK]} limitChars={12} hideSeeMore={true}>
          {Clinic?.name || "No Clinic"}
        </CoreTypographySubtitle2>
      </CoreBox>

      <CoreStack
        direction="row"
        spacing={1}
        styleClasses={[CoreClasses?.FLEX?.DIRECTION_ROW, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}
      >
        <CoreTypographyCaption>{"ID: " + rowData["id"]}</CoreTypographyCaption>

        <CoreTypographyCaption styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY_DARK]}>{"|"}</CoreTypographyCaption>

        <StatusText status={rowData["_status"]} />

      </CoreStack>
    </>
  );
}