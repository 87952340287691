import MediumappBuilderStyles from "../../modules/app-builder/styles/MediumModuleStyles";
import MediumappointmentStyles from "../../modules/appointment/styles/MediumModuleStyles";
import MediumassistantStyles from "../../modules/assistant/styles/MediumModuleStyles";
import MediumauthStyles from "../../modules/auth/styles/MediumModuleStyles";
import MediumclinicStyles from "../../modules/clinic/styles/MediumModuleStyles";
import MediumdashboardStyles from "../../modules/dashboard/styles/MediumModuleStyles";
import MediumdataManagementStyles from "../../modules/data-management/styles/MediumModuleStyles";
import MediumguideStyles from "../../modules/guide/styles/MediumModuleStyles";
import MediumpatientStyles from "../../modules/patient/styles/MediumModuleStyles";
import MediumpaymentStyles from "../../modules/payment/styles/MediumModuleStyles";
import MediumprescriptionStyles from "../../modules/prescription/styles/MediumModuleStyles";
import MediumprofileStyles from "../../modules/profile/styles/MediumModuleStyles";
import MediumsettingsStyles from "../../modules/settings/styles/MediumModuleStyles";
import MediumuserManagementStyles from "../../modules/user-management/styles/MediumModuleStyles";

const MediumModulesStyles = {
...new MediumappBuilderStyles().style,
...new MediumappointmentStyles().style,
...new MediumassistantStyles().style,
...new MediumauthStyles().style,
...new MediumclinicStyles().style,
...new MediumdashboardStyles().style,
...new MediumdataManagementStyles().style,
...new MediumguideStyles().style,
...new MediumpatientStyles().style,
...new MediumpaymentStyles().style,
...new MediumprescriptionStyles().style,
...new MediumprofileStyles().style,
...new MediumsettingsStyles().style,
...new MediumuserManagementStyles().style,
};
 export default MediumModulesStyles