import { useEffect, useState, useContext } from "react";

import {
  HTTP,
  CoreCustomTabs,
  apiRequestAction,
  coreUseNavigate,
  CoreDialogContext,
  reloadDataTableAction,
  CoreLayoutItem,
  AppContainerLayout
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import { setAppointmentReducer } from "../actions/appointmentAction";
import { RoutesRegistry } from "../routes.registry";
import {
  APPOINTMENT_STATUS_UPDATE_ERROR,
  APPOINTMENT_STATUS_UPDATE_SUCCESS,
  RESET_PRESCRIPTION,
  SET_CLINIC,
  SET_PATIENT
} from "../types/appointmentTypes";

export default function ManageAppointment() {
  const dispatch = useDispatch();
  const navigate = coreUseNavigate();
  const { setDialog } = useContext(CoreDialogContext);

  const state = useSelector(
    (state) => state
  );
  const appointmentStatusSuccess = useSelector(
    (state) => state?.appointment?.appointmentStatusSuccess
  );

  const prescription = useSelector((state) => state.prescription);

  const [prescriptionId, setPrescriptionId] = useState(null);
  const [navigateToPrescription, setNavigateToPrescription] = useState(false);
  const [appointmentData, setAppointmentData] = useState(null);

  useEffect(() => {
    if (appointmentStatusSuccess) {
      dispatch(setAppointmentReducer({ appointmentStatusSuccess: false }));
      if (navigateToPrescription) {
        setNavigateToPrescription(false);
        navigatePrescription(appointmentData, prescriptionId);
      } else {
        setDialog({
          cancelButton: () => {
          },
          doneButton: () => {
            dispatch(reloadDataTableAction("UpcomingAppointments"));
            dispatch(reloadDataTableAction("OnHoldAppointments"));
          },
          showDialog: true,
          subtitle  : "Appointment status updated successfully.",
          title     : "Success",
        });
      }
    }
  }, [appointmentStatusSuccess]);

  const setPatient = (data) => {
    dispatch({
      payload: {
        Person: {
          dob       : data?.Patient?.dob,
          firstName : data?.Patient?.firstName,
          gender    : data?.Patient?.gender,
          id        : data?.Patient?.id,
          lastName  : data?.Patient?.lastName,
          middleName: data?.Patient?.middleName,
          photoUrl  : data?.Patient?.photoUrl,
          profileId : data?.Patient?.profileId,
        },
        appointment: { id: data?.id },
        email      : data?.Patient?.User?.email,
        id         : data?.Patient?.User?.id,
        phone      : data?.Patient?.User?.phone,
      },
      type: SET_PATIENT,
    });
  };

  const setClinic = (data) => {
    dispatch({
      payload: { ...(data?.Clinic?.Address || {}), Clinic: data?.Clinic },
      type   : SET_CLINIC,
    });
  };

  /**
   * @todo replace sweetalert with something
   */
  const navigatePrescription = (
    data = null,
    prescriptionId = null,
    force = false
  ) => {
    if (prescription?.prescription?.id && !force) {
      setDialog({
        cancelButton: () => {
        },
        cancelButtonLabel: "Start Fresh",
        doneButton       : () => {
          if (data) {
            navigate(`/${RoutesRegistry.PRESCRIPTION}`, { state: { fromAppointment: true } });
          } else {
            dispatch({ type: RESET_PRESCRIPTION });
            if (!prescriptionId) {
              if (data) {
                setPatient(data);
                setClinic(data);
              }
              navigate(`/${RoutesRegistry.PRESCRIPTION}`, { state: { fromAppointment: true } });
            } else {
              navigate(`/${RoutesRegistry.PRESCRIPTION}/${prescriptionId}`, { state: { fromAppointment: true } });
            }
          }
        },
        doneButtonLabel: "Continue",
        showDialog     : true,
        subtitle       : "Would you like you continue or start fresh?",
        title          : "You already have a prescription!",
      });
    } else {
      dispatch({ type: RESET_PRESCRIPTION });
      if (data) {
        setPatient(data);
        setClinic(data);
      }
      if (prescriptionId) {
        navigate(`/${RoutesRegistry.PRESCRIPTION}/${prescriptionId}`, { state: { fromAppointment: true } });
      } else {
        navigate(`/${RoutesRegistry.PRESCRIPTION}`, { state: { fromAppointment: true } });
      }
    }
  };

  const makeCurrent = (data, prescriptionId = null, navigate = false) => {
    setPrescriptionId(prescriptionId);
    setNavigateToPrescription(navigate);
    setAppointmentData(data);
    dispatch(
      apiRequestAction(
        HTTP.PUT,
        "/appointment/:id/current".replace(":id", data.id),
        true,
        {},
        APPOINTMENT_STATUS_UPDATE_SUCCESS,
        APPOINTMENT_STATUS_UPDATE_ERROR
      )
    );
  };

  const tabsContent = [
    {
      comp : "UpcomingAppointment",
      id   : "upcomingAppointment",
      label: "Upcoming Appointment",
      props: {
        makeCurrent         : makeCurrent,
        navigatePrescription: navigatePrescription,
      },
    },
    {
      comp : "CurrentAppointment",
      id   : "currentAppointment",
      label: "Current Appointment",
      props: { navigatePrescription: navigatePrescription },
    },
    {
      comp : "OnHoldAppointment",
      id   : "onHoldAppointment",
      label: "Onhold Appointment",
      props: { makeCurrent: makeCurrent },
    },
    {
      comp : "CompletedAppointment",
      id   : "completedAppointment",
      label: "Completed Appointment",
    },
  ];

  // eslint-disable-next-line no-console
  console.log("STATE", state);

  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <CoreCustomTabs tabsContent={tabsContent} />
      </CoreLayoutItem>
    </>
  );
}
