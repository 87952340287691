import {
  CoreTypographyOverline,
  CoreBox,
  CoreCard,
  CoreClasses,
  CoreStack
} from "@wrappid/core";
import { useSelector } from "react-redux";

export default function RxCashCard(props) {
  const settingMeta = useSelector((state) => state.mdm.settingMeta);

  return (
    <CoreCard
      styleClasses={[CoreClasses.RX.RX_CASH_CARD, CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.WIDTH.W_100]}
      onClick={props.onClick}
    >
      <CoreStack
        direction="column" 
        styleClasses={[CoreClasses.MARGIN.MT1]}

      >

        <CoreTypographyOverline
        >
          {!props.value ? "Reset" : <> &#43; {props.value}</>}
        </CoreTypographyOverline>
      
        <CoreBox >
          <CoreTypographyOverline
            styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER]}
          >
            {!props.value ? (
              "Reset"
            ) : (
              <>
              &#9733;
                {parseInt(props.value) *
                settingMeta.find((tmp) => tmp.name === "rxTokenRatio")?.value
                  ?.ratio}
              </>
            )}
          </CoreTypographyOverline>
        </CoreBox>
      </CoreStack>
    </CoreCard>

  );
}
