import AssistantDashboard from "./components/AssistantDashboard";
import BackofficeDashboard from "./components/BackofficeDashboard";
import CounterWidget from "./components/CounterWidget";
import Dashboard from "./components/Dashboard";
import DoctorDashboard from "./components/DoctorDashboard";
import ParentBox from "./components/ParentBox";
import PatientDashboard from "./components/PatientDashboard";
import SalesForceDashboard from "./components/SalesForceDashboard";
import SystemAdminDashboard from "./components/SystemAdminDashboard";
import TestComponent from "./components/TestComponent";

export const ComponentsRegistry = {
  AssistantDashboard  : { comp: AssistantDashboard },
  BackofficeDashboard : { comp: BackofficeDashboard },
  CounterWidget       : { comp: CounterWidget },
  Dashboard           : { comp: Dashboard },
  DoctorDashboard     : { comp: DoctorDashboard },
  ParentBox           : { comp: ParentBox },
  PatientDashboard    : { comp: PatientDashboard },
  SalesForceDashboard : { comp: SalesForceDashboard },
  SystemAdminDashboard: { comp: SystemAdminDashboard },
  TestComponent       : { comp: TestComponent },
};
