import { CoreJSONEditor, CoreTypographyBody1 } from "@wrappid/core";

export default function FormBuilder(props) {
  return (
    <>
      <CoreTypographyBody1>
        TODO: Form Builder Work In Progress
      </CoreTypographyBody1>

      <CoreJSONEditor {...props} />
    </>
  );
}
