const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

import {
  GET_PAYMENTS_ERROR,
  GET_PAYMENTS_LOADING,
  GET_PAYMENTS_SUCCESS,
  GET_RX_CASH_ERROR,
  GET_RX_CASH_SUCCESS,
  GET_TOKEN_DETAIL_ERROR,
  GET_TOKEN_DETAIL_LOADING,
  GET_TOKEN_DETAIL_SUCCESS,
  RX_PAYMENT_VERIFY_ERROR,
  RX_PAYMENT_VERIFY_SUCCESS,
  SET_PAYMENT_REDUCER,
  START_PAYMENT_ERROR,
  START_PAYMENT_LOADING,
  START_PAYMENT_SUCCESS,
  TOGGLE_RX_CASH
} from "../types/payment.types";

const initState = {
  finalPaymentData : null,
  getPaymentError  : false,
  getPaymentLoading: false,
  getPaymentSuccess: false,
  getRxCashError   : false,
  getRxCashSuccess : false,
  getTokenError    : false,
  getTokenLoading  : false,
  getTokenSuccess  : false,
  initPaymentData  : null,
  orderData        : null,

  paymentData        : null,
  paymentStatus      : "",
  rxCash             : null,
  rxCashOpen         : false,
  rxPaymentVerifyData: null,

  rxPaymentVerifyError  : false,
  rxPaymentVerifySuccess: false,

  startPaymentError  : false,
  startPaymentLoading: false,
  startPaymentMessage: null,

  startPaymentSuccess: false,
  tokenCredit        : null,
  tokenDebit         : null,
};

const paymentReducer = (state = initState, action) => {
  switch (action.type) {
    case START_PAYMENT_LOADING:
      return {
        ...state,
        initPaymentData    : null,
        orderData          : null,
        startPaymentError  : false,
        startPaymentLoading: true,
        startPaymentSuccess: false,
      };

    case START_PAYMENT_SUCCESS:
      return {
        ...state,
        initPaymentData    : action.payload.data.payment,
        orderData          : action.payload.data.order,
        startPaymentError  : false,
        startPaymentLoading: false,
        startPaymentSuccess: true,
      };

    case START_PAYMENT_ERROR:
      return {
        ...state,
        startPaymentError  : true,
        startPaymentLoading: false,
        startPaymentSuccess: false,
      };

    case GET_PAYMENTS_LOADING:
      return {
        ...state,
        getPaymentError  : false,
        getPaymentLoading: true,
        getPaymentSuccess: false,
        paymentData      : null,
      };

    case GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        getPaymentError  : false,
        getPaymentLoading: false,
        getPaymentSuccess: true,
        paymentData      : action?.data,
      };

    case GET_PAYMENTS_ERROR:
      return {
        ...state,
        getPaymentError  : true,
        getPaymentLoading: false,
        getPaymentSuccess: false,
      };

    case GET_TOKEN_DETAIL_LOADING:
      return {
        ...state,
        getTokenError  : false,
        getTokenLoading: true,
        getTokenSuccess: false,
        tokenCredit    : null,
        tokenDebit     : null,
      };

    case GET_TOKEN_DETAIL_SUCCESS:
      return {
        ...state,
        getTokenError  : false,
        getTokenLoading: false,
        getTokenSuccess: true,
        tokenCredit    : action?.payload?.data?.creditData,
        tokenDebit     : action?.payload?.data?.debitData,
      };

    case GET_TOKEN_DETAIL_ERROR:
      return {
        ...state,
        getTokenError  : true,
        getTokenLoading: false,
        getTokenSuccess: false,
      };

    case SET_PAYMENT_REDUCER:
      return {
        ...state,
        ...action.data,
      };

    case TOGGLE_RX_CASH:
      return {
        ...state,
        orderData          : null,
        rxCashOpen         : !state.rxCashOpen,
        startPaymentError  : false,
        startPaymentLoading: false,
        startPaymentSuccess: false,
      };

    case GET_RX_CASH_SUCCESS:
      return {
        ...state,
        getRxCashError  : false,
        getRxCashSuccess: true,
        rxCash          : action.payload?.data,
      };

    case GET_RX_CASH_ERROR:
      return {
        ...state,
        getRxCashError  : true,
        getRxCashSuccess: false,
        rxCash          : null,
      };

    case RX_PAYMENT_VERIFY_SUCCESS:
      return {
        ...state,
        rxPaymentVerifyData   : action.payload,
        rxPaymentVerifyError  : false,
        rxPaymentVerifySuccess: true,
      };

    case RX_PAYMENT_VERIFY_ERROR:
      // eslint-disable-next-line no-console
      console.log("action", action);
      return {
        ...state,
        rxPaymentVerifyData   : action.payload,
        rxPaymentVerifyError  : true,
        rxPaymentVerifySuccess: false,
      };

    case LOGOUT_SUCCESS:
      return initState;

    default:
      return state;
  }
};

export default paymentReducer;
