import React, { Children } from "react";

import {
  CoreAccordion,
  CoreAccordionDetail,
  CoreAccordionSummary,
  CoreRightDrawer
} from "@wrappid/core";
import { getEffectiveStyle } from "@wrappid/styles";

export default function PrescriptionRightDrawer(props) {
  const [collapse, setCollapse] = React.useState({});

  const HandleCollapse = (index) => {
    setCollapse({
      ...collapse,
      [index]: collapse[index] ? false : true
    });
  };

  const { open } = props;

  // -- console.log("drawerItems", drawerItems);

  return (
    <CoreRightDrawer
      anchor={props.anchor ? props.anchor : "right"}
      variant={"persistent"}
      open={open}
      PaperProps={{ sx: { ...getEffectiveStyle(["appDrawerPaperHeight", "appbarHeight"]) } }}
    >
      {props?.item
        ? props.children
        : Children.toArray(props.children).map((child, index) => {
          return child.props.accordionSummery ? (
            <CoreAccordion
              onChange={() => {
                HandleCollapse(index);
              }}
              expanded={collapse[index] ? true : false} //acordian
            >
              <CoreAccordionSummary>
                {child.props.accordionSummery}
              </CoreAccordionSummary>

              <CoreAccordionDetail>{child}</CoreAccordionDetail>
            </CoreAccordion>
          ) : (
            child
          );
        })}
    </CoreRightDrawer>
  );
}
