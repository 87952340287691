import XXLargeappBuilderStyles from "../../modules/app-builder/styles/XXLargeModuleStyles";
import XXLargeappointmentStyles from "../../modules/appointment/styles/XXLargeModuleStyles";
import XXLargeassistantStyles from "../../modules/assistant/styles/XXLargeModuleStyles";
import XXLargeauthStyles from "../../modules/auth/styles/XXLargeModuleStyles";
import XXLargeclinicStyles from "../../modules/clinic/styles/XXLargeModuleStyles";
import XXLargedashboardStyles from "../../modules/dashboard/styles/XXLargeModuleStyles";
import XXLargedataManagementStyles from "../../modules/data-management/styles/XXLargeModuleStyles";
import XXLargeguideStyles from "../../modules/guide/styles/XXLargeModuleStyles";
import XXLargepatientStyles from "../../modules/patient/styles/XXLargeModuleStyles";
import XXLargepaymentStyles from "../../modules/payment/styles/XXLargeModuleStyles";
import XXLargeprescriptionStyles from "../../modules/prescription/styles/XXLargeModuleStyles";
import XXLargeprofileStyles from "../../modules/profile/styles/XXLargeModuleStyles";
import XXLargesettingsStyles from "../../modules/settings/styles/XXLargeModuleStyles";
import XXLargeuserManagementStyles from "../../modules/user-management/styles/XXLargeModuleStyles";

const XXLargeModulesStyles = {
...new XXLargeappBuilderStyles().style,
...new XXLargeappointmentStyles().style,
...new XXLargeassistantStyles().style,
...new XXLargeauthStyles().style,
...new XXLargeclinicStyles().style,
...new XXLargedashboardStyles().style,
...new XXLargedataManagementStyles().style,
...new XXLargeguideStyles().style,
...new XXLargepatientStyles().style,
...new XXLargepaymentStyles().style,
...new XXLargeprescriptionStyles().style,
...new XXLargeprofileStyles().style,
...new XXLargesettingsStyles().style,
...new XXLargeuserManagementStyles().style,
};
 export default XXLargeModulesStyles