import { CoreImage, CoreBox } from "@wrappid/core";

import { ResourcesRegistry } from "../registry/ResourcesRegistry";

export default function AppLogoGif() {
  return (
    <CoreBox>
      <CoreImage
        src={(ResourcesRegistry.appLoaderGif)}
        alt="Rxefy Loader"
        height={50}
        width={50}
      />
    </CoreBox>
  );
}