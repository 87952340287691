import { default as appBuilderModuleClasses } from "../../modules/app-builder/styles/ModuleClasses";
import { default as appointmentModuleClasses } from "../../modules/appointment/styles/ModuleClasses";
import { default as assistantModuleClasses } from "../../modules/assistant/styles/ModuleClasses";
import { default as authModuleClasses } from "../../modules/auth/styles/ModuleClasses";
import { default as clinicModuleClasses } from "../../modules/clinic/styles/ModuleClasses";
import { default as dashboardModuleClasses } from "../../modules/dashboard/styles/ModuleClasses";
import { default as dataManagementModuleClasses } from "../../modules/data-management/styles/ModuleClasses";
import { default as guideModuleClasses } from "../../modules/guide/styles/ModuleClasses";
import { default as patientModuleClasses } from "../../modules/patient/styles/ModuleClasses";
import { default as paymentModuleClasses } from "../../modules/payment/styles/ModuleClasses";
import { default as prescriptionModuleClasses } from "../../modules/prescription/styles/ModuleClasses";
import { default as profileModuleClasses } from "../../modules/profile/styles/ModuleClasses";
import { default as settingsModuleClasses } from "../../modules/settings/styles/ModuleClasses";
import { default as userManagementModuleClasses } from "../../modules/user-management/styles/ModuleClasses";

const ModulesClasses = {
...appBuilderModuleClasses,
...appointmentModuleClasses,
...assistantModuleClasses,
...authModuleClasses,
...clinicModuleClasses,
...dashboardModuleClasses,
...dataManagementModuleClasses,
...guideModuleClasses,
...patientModuleClasses,
...paymentModuleClasses,
...prescriptionModuleClasses,
...profileModuleClasses,
...settingsModuleClasses,
...userManagementModuleClasses,
}
export default ModulesClasses;