/* eslint-disable no-unused-vars */
import { useContext, useEffect } from "react";

import {
  AppContainerLayout,
  CoreBox,
  CoreDialogContext,
  CoreGrid,
  CoreLabel,
  CoreLayoutItem,
  CoreOutlinedButton,
  HTTP,
  apiRequestAction
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import SearchUser from "./SearchUser";
import { getname, setmanageAssistantReducer } from "../actions/manageAssistantAction";
import { RoutesRegistry } from "../routes.registry";
import {
  SENT_ASSISTANT_INVITATION_ERROR,
  SENT_ASSISTANT_INVITATION_SUCCESS
} from "../types/manageAssistantTypes";
import { SET_SEARCHED_USER } from "../types/searchType";

export default function CreateAndAppointAssistant() {
  const dispatch = useDispatch();

  const { dialog, setDialog } = useContext(CoreDialogContext);
  const value = { dialog, setDialog };

  const searchedUser = useSelector(
    (state) => state.searchReducer?.searchedUser
  );
  const personID = useSelector((state) => state?.profile?.basic?.id);
  const manageAssistant = useSelector((state) => state.manageAssistant);

  useEffect(() => {
    if (manageAssistant?.sentAssistantInvitationSuccess) {
      setDialog({
        cancelButton: () => {
        },
        doneButton: () => {
        },
        showDialog: true,
        subtitle  : manageAssistant?.sentAssistantInvitationMsg || "Assistant invitation sent",
        title     : "Success",
      });

      dispatch(
        setmanageAssistantReducer({ sentAssistantInvitationSuccess: false })
      );
    }
    if (manageAssistant?.sentAssistantInvitationError) {
      setDialog({
        cancelButton: () => {
        },
        doneButton: () => {
        },
        showDialog: true,
        subtitle  : manageAssistant?.sentAssistantInvitationMsg || "Error to send invitation",
        title     : "Oops!",
      });
      // -- swal(
      //     "Oops!",
      //     manageAssistant?.sentAssistantInvitationMsg || "Error to send invitation",
      //     "error"
      // );
      dispatch(
        setmanageAssistantReducer({ sentAssistantInvitationError: false })
      );
    }
  }, [manageAssistant]);

  const sentInvitation = () => {
    dispatch(
      apiRequestAction(
        HTTP.POST,
        "/assistant/sendInvitation",
        true,
        {
          personId       : personID,
          relatedPersonId: searchedUser["Person.id"],
        },
        SENT_ASSISTANT_INVITATION_SUCCESS,
        SENT_ASSISTANT_INVITATION_ERROR
      )
    );
  };

  return (
    <>
      <CoreLayoutItem id={AppContainerLayout.PLACEHOLDER.CONTENT}>
        <SearchUser
          role="assistant"
          label="Search Assistant"
          handleChange={() => { }}
          navigateUrl={"/" + RoutesRegistry.CREATE_ASSISTANT}
          onChangeDispatch={{ type: SET_SEARCHED_USER }}
        />

        {searchedUser && searchedUser["Person.firstName"] && (
          <CoreGrid>
            <CoreBox gridProps={{ gridSize: { sm: 4 } }}>
              <CoreLabel>
                {getname({
                  firstName : searchedUser["Person.firstName"],
                  lastName  : searchedUser["Person.lastName"],
                  middleName: searchedUser["Person.firstName"],
                })}
              </CoreLabel>

              <CoreLabel>{searchedUser.email}</CoreLabel>
            </CoreBox>

            <CoreBox gridProps={{ gridSize: { sm: 4 } }}>
              <CoreOutlinedButton label="Invite" onClick={sentInvitation} />
            </CoreBox>
          </CoreGrid>
        )}
      </CoreLayoutItem>
    </>
  );
}
