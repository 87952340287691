/* eslint-disable no-unused-vars */

import { searchPatient } from "./functions/asyncSelect.functions";
import { SanPatientCreate, SanReadPatient, SanDeletePatient, SanEditPatient, SanPatientRelativeCreate } from "./functions/sanity.functions";

// asyncSelect and formSubmitSanitization

export const FunctionsRegistry = {
  SanDeletePatient        : SanDeletePatient,
  SanEditPatient          : SanEditPatient,
  SanPatientCreate        : SanPatientCreate,
  SanPatientRelativeCreate: SanPatientRelativeCreate,
  SanReadPatient          : SanReadPatient,
  searchPatient           : searchPatient
};
