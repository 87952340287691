import { LOGOUT_SUCCESS } from "@wrappid/core";

import {
  CREATE_CLINIC_SUCCESS,
  CREATE_CLINIC_ERROR,
  EDIT_CLINIC_SUCCESS,
  EDIT_CLINIC_ERROR,
  RESET_CLINIC,
  GET_CLINIC_SUCCESS,
  GET_CLINIC_ERROR
} from "../types/clinic.types";

const initState = {
  createClinicError  : false,
  createClinicSuccess: false,
  editClinicError    : false,
  editClinicSuccess  : false,
  getClinicError     : false,
  getClinicSuccess   : false,
};

const clinicReducer = (state = initState, action) => {
  switch (action.type) {
    case CREATE_CLINIC_SUCCESS:
      return {
        ...state,
        createClinicError  : false,
        createClinicSuccess: true
      };

    case CREATE_CLINIC_ERROR:
      return {
        ...state,
        createClinicError  : true,
        createClinicSuccess: false,
      };

    case EDIT_CLINIC_SUCCESS:
      return {
        ...state,
        editClinicError  : false,
        editClinicSuccess: true,
      };

    case EDIT_CLINIC_ERROR:
      return {
        ...state,
        editClinicError  : true,
        editClinicSuccess: false,
      };

    case GET_CLINIC_SUCCESS:
      return {
        ...state,
        getClinicError  : false,
        getClinicSuccess: true,
      };

    case GET_CLINIC_ERROR:
      return {
        ...state,
        getClinicError  : true,
        getClinicSuccess: false,
      };

    case RESET_CLINIC:
      return { ...initState };

    case LOGOUT_SUCCESS:
      return { ...initState };

    default:
      return state;
  }
};

export default clinicReducer;