/* eslint-disable no-console */
import { useContext, useEffect } from "react";

// -- import { TestContext } from "../context/testContext";
import {
  CoreButton,
  CoreDialogContext
} from "@wrappid/core";

function TestComponent() {
  const { dialog, setDialog } = useContext(CoreDialogContext);

  useEffect(() => {
    console.log(dialog);
  }, [dialog]);

  return (
    <CoreButton
      label={"ClickME2"}
      OnClick={() => {
        console.log("Clicked button2");
        // -- setValue("This is the changed Value.");
        setDialog({
          cancelButton: () => {
            console.log("This is cancel button");
          },
          doneButton: () => {
            console.log("This is done button");
          },
          showDialog: true,
          subtitle  : "This is a dialog box.",
          title     : "Hello",
        });
      }}
    />
  );
}

export default TestComponent;
