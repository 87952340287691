export const GET_APPOINTMENT_SUCCESS = "GET_APPOINTMENT_SUCCESS";
export const GET_APPOINTMENT_ERROR = "GET_APPOINTMENT_ERROR";
export const CREATE_APPOINTMENT_LOADING = "CREATE_APPOINTMENT_LOADING";
export const CREATE_APPOINTMENT_SUCCESS = "CREATE_APPOINTMENT_SUCCESS";
export const CREATE_APPOINTMENT_ERROR = "CREATE_APPOINTMENT_ERROR";
export const SET_APPOINTMENT_REDUCER_VALUE = "SET_APPOINTMENT_REDUCER_VALUE";
export const EDIT_APPOINTMENT_SUCCESS = "EDIT_APPOINTMENT_SUCCESS";
export const EDIT_APPOINTMENT_ERROR = "EDIT_APPOINTMENT_ERROR";
export const SET_APPOINTMENT_PATIENT = "SET_APPOINTMENT_PATIENT";
export const RESET_APPOINTMENT_PATIENT = "RESET_APPOINTMENT_PATIENT";
export const APPOINTMENT_STATUS_UPDATE_SUCCESS = "APPOINTMENT_STATUS_UPDATE_SUCCESS";
export const APPOINTMENT_STATUS_UPDATE_ERROR = "APPOINTMENT_STATUS_UPDATE_ERROR";
export const SET_APPOINTMENT_REDUCER = "SET_APPOINTMENT_REDUCER";
export const GET_SELECTED_APPOINTMENT_SUCCESS = "GET_SELECTED_APPOINTMENT_SUCCESS";
export const GET_SELECTED_APPOINTMENT_ERROR = "GET_SELECTED_APPOINTMENT_ERROR";

// thi is from prescription types folder
export const RESET_PRESCRIPTION = "RESET_PRESCRIPTION";
export const SET_PATIENT = "SET_PATIENT";
export const SET_CLINIC = "SET_CLINIC";
