import ClinicCard from "./components/ClinicCard";
import ClinicInfo from "./components/ClinicInfo";
import ConsultationTimingCard from "./components/ConsultationTimingCard";
import ConsultationTimings from "./components/ConsultationTimings";
import ManageClinics from "./components/ManageClinics";
import ProfileClinicSkeleton from "./components/ProfileClinicSkeleton";

export const ComponentsRegistry = {
  ClinicCard            : { comp: ClinicCard },
  ClinicInfo            : { comp: ClinicInfo },
  ConsultationTimingCard: { comp: ConsultationTimingCard },
  ConsultationTimings   : { comp: ConsultationTimings },
  ManageClinics         : { comp: ManageClinics },
  ProfileClinicSkeleton : { comp: ProfileClinicSkeleton }
};