import {
  //eslint-disable-next-line etc/no-commented-out-code
/*
  CoreBox,
  CoreForm,
  CoreFormControlLabel,
  CoreFormGroup,
  CoreH5,
  CoreSection,
  CoreSwitch,
  FORM_EDIT_MODE,
  FORM_IDS */
  CoreTypographyBody1
} from "@wrappid/core";

import CustomSectionContent from "./CustomSectionContent";

export default function SettingsNotification() {
  return (
    <>
      <CustomSectionContent>
        <CoreTypographyBody1>
        Comming Soon...
        </CoreTypographyBody1>
      </CustomSectionContent>
    </>
  );
}