
import {
  CoreImage
  , CoreH6
  , CoreLabel
  , CoreBox
  , CoreGrid
  , CoreCard
  , CoreCardContent
  , CoreClasses
} from "@wrappid/core";
import { useSelector } from "react-redux";

export default function RxPaymentSuccess(props) {
  const state = useSelector((state) => state);

  // eslint-disable-next-line no-console
  console.log("props", props);
  // eslint-disable-next-line no-console
  console.log("state", state);
  return (
    <CoreGrid>
      <CoreBox gridProps={{ gridSize: 2 }}></CoreBox>

      <CoreCard gridProps={{ gridSize: 8 }}>
        <CoreCardContent>
          <CoreH6>Payment Successfull</CoreH6>

          <CoreBox styleClasses={[CoreClasses.TEXT.TEXT_CENTER]}>
            <CoreImage
              src={"./images/rx-cash.png"}
              styleClasses={[CoreClasses.RX.RX_ALERT_WALLET_ICON]}
            />

            <CoreH6 styleClasses={[CoreClasses.MARGIN.M2]}>RX Cash Received!</CoreH6>

            <CoreLabel styleClasses={[CoreClasses.MARGIN.M1]}>
              You have completed your payment.
            </CoreLabel>

            <CoreLabel>
              You have received {state.payment.rxCashReceived} RX Cash in your account.
            </CoreLabel>

            <CoreGrid
              styleClasses={[CoreClasses.MARGIN.M2, CoreClasses.RX.RX_SUCCESS_AMOUNT_CARD]}
            >
              <CoreBox gridProps={{ gridSize: 6 }}>
                <CoreLabel> Amount Paid: </CoreLabel>
              </CoreBox>

              <CoreBox
                gridProps={{ gridSize: 6 }}
                styleClasses={[CoreClasses.RX.RX_SUCCESS_AMOUNT]}
              >
                &#8377;
                {state.payment.amountPaid}
              </CoreBox>
            </CoreGrid>

            <CoreLabel>Ref: {state.payment?.finalPaymentData?.getwayData?.id}</CoreLabel>

            <CoreLabel
              styleClasses={[CoreClasses.MARGIN.M2, CoreClasses.RX.RX_RECHARGE_DISCLAIMER]}
            >
              Do you have any concern?
              <CoreLabel
                styleClasses={[CoreClasses.RX.RX_RECHARGE_DISCLAIMER_TERMS_AND_CONDITIONS]}
              >
                Contact Support?
              </CoreLabel>
            </CoreLabel>
          </CoreBox>
        </CoreCardContent>
      </CoreCard>
    </CoreGrid>
  );
}
