import { useEffect } from "react";

import {
  __EntityStatus,
  HTTP,
  CoreAvatar,
  CoreTypographyBody1,
  CoreTypographyCaption,
  CoreContainedButton,
  CoreBox,
  CoreGrid,
  CoreCard,
  CoreCardContent,
  CoreClasses,
  apiRequestAction
} from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import {
  GET_APPOINTMENT_ERROR,
  GET_APPOINTMENT_SUCCESS
} from "../types/appointmentTypes";
import { getname } from "../utils/default.util";

export default function CurrentAppointmentCard(props) {
  const dispatch = useDispatch();

  const personID = useSelector((state) => state?.profile?.basic?.id);
  const appointmentData = useSelector(
    (state) => state.appointment?.appointmentData?.data
  );
  const prescription = useSelector((state) => state.prescription);

  const _defaultFilter = encodeURIComponent(
    JSON.stringify({
      "Appointments._status": __EntityStatus.CURRENT,
      doctorId              : personID,
    })
  );

  useEffect(() => {
    dispatch(
      apiRequestAction(
        HTTP.GET,
        "/business/individual/Appointment?_defaultFilter=" + _defaultFilter,
        true,
        {},
        GET_APPOINTMENT_SUCCESS,
        GET_APPOINTMENT_ERROR
      )
    );
  }, []);

  const renderPrescriptionButton = () => {
    if (
      appointmentData &&
      appointmentData?.Prescription &&
      appointmentData?.Prescription[0] &&
      appointmentData?.Prescription[0]?.id === prescription?.prescription?.id
    ) {
      return (
        <CoreContainedButton
          label="Edit Prescription"
          onClick={() => {
            props.navigatePrescription(null, null, true);
          }}
        />
      );
    } else if (
      appointmentData &&
      appointmentData?.Prescription &&
      appointmentData?.Prescription[0] &&
      appointmentData?.Prescription[0].id !== prescription?.prescription?.id
    ) {
      return (
        <CoreContainedButton
          label="Continue Prescription"
          onClick={() => {
            props.navigatePrescription(
              appointmentData,
              appointmentData &&
                appointmentData?.Prescription &&
                appointmentData?.Prescription[0] &&
                appointmentData?.Prescription[0].id,
              false
            );
          }}
        />
      );
    } else {
      return (
        <CoreContainedButton
          label="Create Prescription"
          onClick={() => {
            props.navigatePrescription(appointmentData, null, false);
          }}
        />
      );
    }
  };

  return (
    <CoreCard gridProps={{ gridSize: { sm: 4 } }}>
      <CoreCardContent>
        {appointmentData ? (
          <CoreGrid>
            <CoreBox
              gridProps={{ gridSize: { sm: 2 } }}
              styleClasses={[CoreClasses.LAYOUT.HORIZONTAL_CENTER]}
            >
              <CoreAvatar
                styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_LARGE]}
                src={appointmentData?.Patient?.photoUrl}
              />
            </CoreBox>

            <CoreGrid gridProps={{ gridSize: { sm: 10 } }}>
              <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                <CoreTypographyCaption>Name: </CoreTypographyCaption>
              </CoreBox>

              <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                {appointmentData && (
                  <CoreTypographyCaption>
                    {getname({
                      firstName : appointmentData?.Patient?.firstName,
                      lastName  : appointmentData?.Patient?.lastName,
                      middlename: appointmentData?.Patient?.middleName,
                    })}
                  </CoreTypographyCaption>
                )}
              </CoreBox>

              <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                <CoreTypographyCaption>Relation: </CoreTypographyCaption>
              </CoreBox>

              <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                <CoreTypographyCaption>self</CoreTypographyCaption>
              </CoreBox>

              <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                <CoreTypographyCaption>Mobile No: </CoreTypographyCaption>
              </CoreBox>

              <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                <CoreTypographyCaption>
                  {appointmentData && appointmentData?.Patient?.User?.phone}
                </CoreTypographyCaption>
              </CoreBox>

              <CoreBox gridProps={{ gridSize: 6 }}>
                <CoreTypographyCaption>Appointment ID: </CoreTypographyCaption>
              </CoreBox>

              <CoreTypographyBody1 gridProps={{ gridSize: { sm: 6 } }}>
                {appointmentData?.id}
              </CoreTypographyBody1>

              <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                <CoreTypographyCaption>
                  Appointment Time:{" "}
                </CoreTypographyCaption>
              </CoreBox>

              <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                <CoreTypographyCaption>
                  {appointmentData?.date +
                    ", " +
                    appointmentData?.startTime?.substring(0, 5) +
                    " - " +
                    appointmentData?.endTime?.substring(0, 5)}
                </CoreTypographyCaption>
              </CoreBox>

              <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                <CoreTypographyCaption>Clinic Name: </CoreTypographyCaption>
              </CoreBox>

              <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                <CoreTypographyCaption>
                  {(appointmentData && appointmentData?.Clinic?.name) ||
                    "No Clinic"}
                </CoreTypographyCaption>
              </CoreBox>

              {appointmentData && appointmentData["Clinic.name"] && (
                <CoreBox gridProps={{ gridSize: { sm: 6 } }}>
                  <CoreTypographyCaption>
                    Clinic Address:{" "}
                  </CoreTypographyCaption>
                </CoreBox>
              )}

              {appointmentData && appointmentData["Clinic.name"] && (
                <CoreTypographyCaption gridProps={{ gridSize: { sm: 6 } }}>
                  {appointmentData && `${appointmentData?.Clinic?.Address?.addLine1}, ${appointmentData?.Clinic?.Address?.addLine2}, ${appointmentData?.Clinic?.Address?.city} - ${appointmentData?.Clinic?.Address?.pin}` ||
                    "No Address"}
                </CoreTypographyCaption>
              )}

              <CoreBox
                styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_END]}
              >
                {renderPrescriptionButton()}
              </CoreBox>
            </CoreGrid>
          </CoreGrid>
        ) : (
          <CoreTypographyCaption>No Current Appointment</CoreTypographyCaption>
        )}
      </CoreCardContent>
    </CoreCard>
  );
}
