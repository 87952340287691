import About from "../components/About";
import AppLogoGif from "../components/AppLogoGif";
import AppSpecificProfileSection from "../components/AppSpecificProfileSection";
import CommingSoon from "../components/CommingSoon";
import Help from "../components/Help";
import RxefyAppLayout from "../components/layouts/RxefyAppLayout";
import Privacy from "../components/Privacy";
import ProfileRegistrationSkeleton from "../components/registration/ProfileRegistrationSkeleton";
import RegistrationCard from "../components/registration/RegistrationCard";
import Emr from "../components/settings/Emr";
import Term from "../components/Term";
import modulesComponentsRegistry from "../modules/modules.components.registry";
export const ComponentsRegistry = {
  ...modulesComponentsRegistry,
  About                      : { comp: About },
  AppLogoGif                 : { comp: AppLogoGif },
  AppSpecificProfileSection  : { comp: AppSpecificProfileSection },
  CommingSoon                : { comp: CommingSoon },
  Emr                        : { comp: Emr },
  Help                       : { comp: Help },
  Privacy                    : { comp: Privacy },
  ProfileRegistrationSkeleton: { comp: ProfileRegistrationSkeleton },
  RegistrationCard           : { comp: RegistrationCard },
  RxefyAppLayout             : { comp: RxefyAppLayout, layout: true },
  Term                       : { comp: Term }
};
