/* eslint-disable no-unused-vars */
import { useEffect } from "react";

import { useSelector } from "react-redux";

function PatientDashboard() {
  // state = {};
  //use useState() instead of state

  // -- componentDidMount = () => {};
  useEffect(() => {}, []);

  const auth = useSelector((state) => state.auth); // --- auth, navData
  const common = useSelector((state) => state.common); // --- rolePermissions, getRolePermissionLoading, getRolePermissionSuccess

  return <></>;
}

export default PatientDashboard;
