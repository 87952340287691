import { ComponentsRegistry as AppBuilderComponentsRegistry } from "./app-builder/components.registry";
import { ComponentsRegistry as AppointmentComponentsRegistry } from "./appointment/components.registry";
import { ComponentsRegistry as AssistantComponentsRegistry } from "./assistant/components.registry";
import { ComponentsRegistry as AuthComponentsRegistry } from "./auth/components.registry";
import { ComponentsRegistry as ClinicComponentsRegistry } from "./clinic/components.registry";
import { ComponentsRegistry as DashboardComponentsRegistry } from "./dashboard/components.registry";
import { ComponentsRegistry as DataManagementComponentsRegistry } from "./data-management/components.registry";
import { ComponentsRegistry as GuideComponentsRegistry } from "./guide/components.registry";
import { ComponentsRegistry as PatientComponentsRegistry } from "./patient/components.registry";
import { ComponentsRegistry as PaymentComponentsRegistry } from "./payment/components.registry";
import { ComponentsRegistry as PrescriptionComponentsRegistry } from "./prescription/components.registry";
import { ComponentsRegistry as ProfileComponentsRegistry } from "./profile/components.registry";
import { ComponentsRegistry as SettingsComponentsRegistry } from "./settings/components.registry";
import { ComponentsRegistry as UserManagementComponentsRegistry } from "./user-management/components.registry";

export default {
...AppBuilderComponentsRegistry,
...AppointmentComponentsRegistry,
...AssistantComponentsRegistry,
...AuthComponentsRegistry,
...ClinicComponentsRegistry,
...DashboardComponentsRegistry,
...DataManagementComponentsRegistry,
...GuideComponentsRegistry,
...PatientComponentsRegistry,
...PaymentComponentsRegistry,
...PrescriptionComponentsRegistry,
...ProfileComponentsRegistry,
...SettingsComponentsRegistry,
...UserManagementComponentsRegistry,
}