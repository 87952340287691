import PrescriptionClinicCard from "./components/CustomRenders/PrescriptionClinicCard";
import PrescriptionDoctorProfileCard from "./components/CustomRenders/PrescriptionDoctorProfileCard";
import PrescriptionPatientProfileCard from "./components/CustomRenders/PrescriptionPatientProfileCard";
import MyPrescriptions from "./components/MyPrescriptions";
import Prescription from "./components/Prescription";
import ManagePrescriptonTemplate from "./components/templateManagement/ManagePrescriptonTemplate";
import TemplateEditor from "./components/templateManagement/TemplateEditor";

export const ComponentsRegistry = {
  ManagePrescriptonTemplate     : { comp: ManagePrescriptonTemplate },
  MyPrescriptions               : { comp: MyPrescriptions },
  Prescription                  : { comp: Prescription },
  TemplateEditor                : { comp: TemplateEditor },
  prescriptionClinicCard        : { comp: PrescriptionClinicCard },
  prescriptionDoctorProfileCard : { comp: PrescriptionDoctorProfileCard },
  prescriptionPatientProfileCard: { comp: PrescriptionPatientProfileCard }
};
