import { Component } from "react";

import {
  APP_PLATFORM,
  CoreBox,
  CoreClasses,
  CoreGrid,
  CoreH6,
  CoreMedicineSelector,
  CoreSpeechToText,
  CoreTypographyBody2
} from "@wrappid/core";
import { WrappidDataContext } from "@wrappid/styles";
import { connect } from "react-redux";

import AddedMedicine from "./AddedMedicine";
import { CustomInputContainer, CustomMedicineOption, customStyle } from "./style";
import { GET_MED_DATA_API } from "../config/constants";
import { getMedicineFormFromText, medStringOptions } from "../utils/helper";

class MedicineSelector extends Component {
  static contextType = WrappidDataContext;
  
  state = {
    advMedicines            : [],
    autoCompleteCompleteFlag: false,
    defaultOptions          : [],
    editMed                 : null,
    inputValue              : "",
    isGeneric               : true,
    mask                    : [
      "Formulation",
      "Medicine Name",
      "Quantity",
      "Frequency",
      "Meal",
      "Duration Count",
      "Duration Type",
    ],
    placeholder       : "Write medicines",
    platform          : this.context?.config?.platform,
    predictedOptions  : [],
    prescriptionString: [],
  };

  MedApiCall = async inputVal => {
    let ops = await medStringOptions(
      inputVal,
      this.state.prescriptionString,
      this.props.auth.accessToken
    );

    // eslint-disable-next-line no-console
    console.log("OPTIONS, ", ops);

    if (ops?.length > 0) {
      this.setState(
        { predictedOptions: [ops[0]] },
        this.HandleInputValueChange(this.state.inputValue)
      );
      return ops;
    } else {
      return [{ label: inputVal, value: inputVal }];
    }
  };

  MedicineChangeHandler = selectedOptions => {
    // eslint-disable-next-line no-console
    console.log("firing change", selectedOptions);

    let oldVal = this.state.prescriptionString;

    if (selectedOptions.length <= oldVal.length) {
      if (this.state.prescriptionString.length === 0) {
        if (this.props.advMedicines.length > 0) {
          let meds = this.props.advMedicines;

          let popedMed = meds.pop();

          let psNew = [];

          // eslint-disable-next-line no-console
          console.log("HERE", popedMed);
          psNew.push(popedMed.formulation);
          psNew.push(popedMed.name);
          psNew.push(popedMed.quantity);
          psNew.push(popedMed.frequency);
          psNew.push(popedMed.meal);
          psNew.push(popedMed.durationCount);
          psNew.push(popedMed.durationType);
          // eslint-disable-next-line no-console
          console.log("PS NEW", psNew);
          this.setState({
            advMedicines      : meds,
            inputValue        : "",
            placeholder       : "",
            prescriptionString: psNew,
          });
        }
      } else
        this.setState({
          inputValue        : oldVal[oldVal.length - 1].label,
          placeholder       : this.state.mask[selectedOptions.length],
          prescriptionString: selectedOptions,
        });
    } else
      this.setState({
        inputValue        : "",
        placeholder       : this.state.mask[selectedOptions.length],
        prescriptionString: selectedOptions,
      });
  };

  HadndleKeyPress = event => {
    // eslint-disable-next-line no-console
    console.log("EVENT", event);

    let str = this.state.inputValue;

    if (event.key === "Backspace") {
      if (str && str.length > 0) {
        this.setState({ inputValue: str.slice(0, str.length) });
      }
    } else if (event.key === "Enter") {
      this.onFinalAddMed();
    } else if (
      event.key === "ArrowRight" &&
      this.state.placeholder &&
      this.state.placeholder.length > 0 &&
      this.state.autoCompleteCompleteFlag
    ) {
      this.setState({
        inputValue : this.state.inputValue + this.state.placeholder,
        placeholder: "",
      });
    }
  };

  HandleInputValueChange = inputValue => {
    let placeholderNew = "";

    let autoCompleteCompleteFlag = false;

    if (
      this.state.predictedOptions &&
      this.state.predictedOptions.length > 0 &&
      inputValue.length > 0
    ) {
      let firstPrediction = this.state.predictedOptions[0].label;

      // eslint-disable-next-line no-console
      console.log(
        "MATCHING",
        firstPrediction?.toLowerCase()?.slice(0, inputValue.length),
        inputValue?.toLowerCase()
      );
      if (
        firstPrediction?.toLowerCase()?.slice(0, inputValue.length) ===
        inputValue?.toLowerCase()
      ) {
        placeholderNew = firstPrediction
          ?.toLowerCase()
          ?.slice(inputValue.length, firstPrediction.length);
        autoCompleteCompleteFlag = true;
      }
    } else if (inputValue.length === 0) {
      placeholderNew = this.state.mask[this.state.prescriptionString.length];
    }
    this.setState({
      autoCompleteCompleteFlag,
      inputValue : inputValue,
      placeholder: placeholderNew,
    });
  };

  AddedMedEdit = id => {
    let med = this.props.advMedicines.find(advMed => advMed.id === id);

    this.setState({
      editMed           : id,
      prescriptionString: [
        med.formulation,
        med.name,
        med.quantity,
        med.frequency,
        med.meal,
        med.durationCount,
        med.durationType,
      ],
    });
  };

  onFinalAddMed = () => {
    if (this.state.prescriptionString.length === 7) {
      let medOb = {
        durationCount: this.state.prescriptionString[5]?.value?.includes(".")
          ? {
            ...this.state.prescriptionString[5],
            value: this.state.prescriptionString[5]?.value?.replace(".", ""),
          }
          : this.state.prescriptionString[5],
        durationType: this.state.prescriptionString[6],
        formulation : this.state.prescriptionString[0],
        frequency   : this.state.prescriptionString[3],
        id          : this.state.editMed
          ? this.state.editMed
          : "temp#" + Number(this.props.advMedicines.length + 1),
        isGeneric: this.state.isGeneric,
        meal     : this.state.prescriptionString[4],
        name     : this.state.prescriptionString[1],
        quantity : this.state.prescriptionString[2],
      };

      let meds = this.props.advMedicines;

      if (meds.find(med => med.id === medOb.id)) {
        let medIndex = meds.findIndex(med => med.id === medOb.id);

        // eslint-disable-next-line no-console
        console.log("HERE", medIndex);
        if (this.state.editMed) {
          // eslint-disable-next-line no-console
          console.log("HERE", this.state.editMed);
          if (medIndex >= 0) {
            meds[medIndex] = medOb;
            this.setState({
              editMed           : null,
              inputValue        : "",
              prescriptionString: [],
            });
            this.props.OnAddMed(meds);
          }
        } else {
          // eslint-disable-next-line no-console
          // swal("Error", "Same Medicine already added", "error");
        }
      } else {
        meds.push(medOb);
        this.setState({
          editMed           : null,
          inputValue        : "",
          prescriptionString: [],
        });
        this.props.OnAddMed(meds);
      }
    }
  };

  getMedicineMask = () => {
    return (
      <CoreTypographyBody2 styleClasses={[CoreClasses.MARGIN.MB1]}>
        {this.state.mask.map((eMask, index) => (
          <CoreTypographyBody2
            key={`mask-${index}`}
            styleClasses={
              index === this.state.prescriptionString.length
                ? [CoreClasses.COLOR.TEXT_PRIMARY_DARK, CoreClasses.DATA_DISPLAY.MEDICINE_TEXT]
                : [CoreClasses.DATA_DISPLAY.MEDICINE_TEXT]
            }
            component="span">
            {this.state.mask?.length && index < this.state.mask.length - 1
              ? eMask + " - "
              : eMask}
          </CoreTypographyBody2>
        ))}
      </CoreTypographyBody2>
    );
  };

  getMedString = async (element, values) => {
    const data = await this.getMedData(values[0]);

    const { formJson, initData } = getMedicineFormFromText(
      data,
      this.props.OnAddMed,
      this.props.advMedicines,
      this.state.editMed,
      this.state.isGeneric
    );

    return { formJson, initData };
  };

  getMedData = async text => {
    try {
      const response_fetch = await fetch(
        this.context?.config?.backendPythonUrl + GET_MED_DATA_API,
        {
          body   : JSON.stringify({ text }),
          headers: {
            Accept        : "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );
      const response = { data: await response_fetch.json() };

      // eslint-disable-next-line no-console
      console.log("Response", response);
      if (response_fetch.status === 200) {
        return response?.data?.data?.data;
      } else {
        alert("Error", response.data?.message);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Error in api", err);
      alert("error", err?.message);
    }
  };

  render() {
    const { advMedicines } = this.props;

    return (
      <CoreBox>
        <CoreBox>
          <AddedMedicine
            data={advMedicines}
            state={this.state}
            onClear={this.props.OnMedClear}
            HandleChange={this.props.onChangeMedGeneric}
            onEdit={this.AddedMedEdit}
            selectprops={{
              components: {
                Input : CustomInputContainer,
                Option: CustomMedicineOption,
              },
              defaultOptions:
                this.state.prescriptionString &&
                this.state.prescriptionString.length === 0,
              inputValue   : this.state.inputValue,
              isMulti      : true,
              loadOptions  : this.MedApiCall,
              onChange     : this.MedicineChangeHandler,
              onFinalAddMed: this.onFinalAddMed,
              onInputChange: this.HandleInputValueChange,
              onKeyDown    : this.HadndleKeyPress,
              placeholder  : false,
              styles       : customStyle,
              value        : this.state.prescriptionString,
            }}
            notEditable={this.props.notEditable}
            onEditMedicineNote={this.props.onEditMedicineNote}
            getMedicineMask={this.getMedicineMask}
          />
        </CoreBox>

        {!this.props.notEditable && this.getMedicineMask()}

        {!this.props.notEditable && (
          <CoreBox styleClasses={[CoreClasses.MARGIN.M1]}>
            {this.state.editMed ? (
              <CoreH6>Complete editing to start add</CoreH6>
            ) : (
              <CoreGrid>
                <CoreMedicineSelector
                  gridProps={{ gridSize: this.state.platform === APP_PLATFORM ? 11 : 12 }}
                  autoFocus={!this.props.notEditable}
                  components={{
                    Input : CustomInputContainer,
                    Option: CustomMedicineOption,
                  }}
                  styles={customStyle(this.props.theme)}
                  placeholder={false}
                  onChange={this.MedicineChangeHandler}
                  isMulti={true}
                  loadOptions={this.MedApiCall}
                  onKeyDown={this.HadndleKeyPress}
                  defaultOptions={
                    this.state.prescriptionString &&
                    this.state.prescriptionString.length === 0
                  }
                  value={this.state.prescriptionString}
                  inputValue={this.state.inputValue}
                  onInputChange={this.HandleInputValueChange}
                  onFinalAddMed={this.onFinalAddMed}
                  getMedicineMask={this.getMedicineMask}
                />

                {this.state.platform === APP_PLATFORM && (
                  <CoreSpeechToText
                    gridProps={{ gridSize: 1 }}
                    element={{
                      id          : "medcineSelector", 
                      speechToText: { textProcessor: this.getMedString } 
                    }}
                  />
                )}
              </CoreGrid>
            )}
          </CoreBox>
        )}
      </CoreBox>
    );
  }
}

const mapStateToProps = state => {
  return { auth: state.auth };
};
const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicineSelector);
