// eslint-disable-next-line no-unused-vars, unused-imports/no-unused-imports
import React, { useState } from "react";

import { CoreInput, CoreGrid, apiRequestAction, HTTP } from "@wrappid/core";
import { useDispatch, useSelector } from "react-redux";

import { GET_ADDRESS_ERROR, GET_ADDRESS_SUCCESS } from "../types/address.types";

export default function InputAddress(props) {
  const { readOnly, formik } = props;

  const dispatch = useDispatch();
  const [pincode, setPincode] = useState("");
  const [landmark, setLandmark] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const addressDetails = useSelector(state => state?.address?.addressDetails);
  const gridRest = { sm: 4, xs: 12 };
  const gridAddressFields = 6;
  
  React.useEffect(()=>{
    let addressSchema = {
      addressLine1,
      addressLine2,
      city    : addressDetails?.Block,
      country : addressDetails?.Country,
      district: addressDetails?.District,
      landmark,
      pincode,
      state   : addressDetails?.State,
    };

    formik.setFieldValue(props.id, JSON.stringify(addressSchema));

  }, [
    pincode,
    landmark,
    addressLine1,
    addressLine2,
    addressDetails
  ]);
  const handlePincodeChange = async (event) => {
    const { value } = event.target;

    setPincode(value);

    if (value.length === 6) {
      try {
        const endpoint = `https://api.postalpincode.in/pincode/${value}`;
        const authRequired = false;
        const apiData = undefined;

        dispatch(
          apiRequestAction(
            HTTP.GET,
            endpoint,
            authRequired,
            apiData,
            GET_ADDRESS_SUCCESS,
            GET_ADDRESS_ERROR
          )
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleLandmarkChange = async (event) => {
    const { value } = event.target;

    setLandmark(value);
  };

  const handleAddressLine1Change = async (event) => {
    const { value } = event.target;

    setAddressLine1(value);
  };

  const handleAddressLine2Change = async (event) => {
    const { value } = event.target;

    setAddressLine2(value);
  };
 
  const onChangeHandler = (event, onChangeFunc) => {
    // eslint-disable-next-line no-console
    console.log("On change handler called from: ", event.target);
    onChangeFunc(event);
  };

  const inputFields = [
    {
      gridSize: gridAddressFields, id: "addLine1", label: "Flat, House no., Building, Company Apartment", name: "addressLine1", onChange: handleAddressLine1Change, value: addressLine1 || ""
    },
    {
      gridSize: gridAddressFields, id: "addLine2", label: "Area, Street, Sector, Village", name: "addressLine2", onChange: handleAddressLine2Change, value: addressLine2 || ""
    },
    {
      disabled: true, gridSize: gridRest, id: "country", label: "Country", name: "country", value: addressDetails?.Country 
    },
    {
      disabled: true, gridSize: gridRest, id: "state", label: "State", name: "state", value: addressDetails?.State 
    },
    {
      disabled: true, gridSize: gridRest, id: "district", label: "District", name: "district", value: addressDetails?.District 
    },
    {
      disabled: true, gridSize: gridRest, id: "city", label: "City/Town", name: "city", value: addressDetails?.Block 
    },
    {
      gridSize: gridRest, id: "pin", label: "Pincode", name: "pincode", onChange: handlePincodeChange, value: pincode
    },
    {
      gridSize: gridRest, id: "landmark", label: "Nearest Landmark", name: "landmark", onChange: handleLandmarkChange, value: landmark || ""
    },
  ];

  return (
    <CoreGrid spacing={2}>
      {inputFields.map((field, index) => (
        <CoreGrid key={index} gridProps={{ gridSize: field.gridSize }}>
          <CoreInput
            id={field.id}
            name={field.name}
            label={field.label}
            variant="outlined"
            value={field.value || addressDetails?.[field.name] || ""}
            onChange={(event)=>{
              onChangeHandler(event, field["onChange"]);
            }}
            disabled={readOnly || field?.disabled || false}
          />
        </CoreGrid>
      ))}
    </CoreGrid>
  );
}