import {
  CoreTypographyBody1,
  CoreAvatar,
  CoreGrid,
  CoreBox,
  CoreClasses,
  __IconTypes,
  CoreTypographyCaption,
  getAge,
  CoreIconText,
  CoreStack,
  CorePhoneLink,
  CoreEmailLink
} from "@wrappid/core";

const AssistantCard = ({ data }) => {
  const {
    "RelatedPerson.User.email": email,
    "RelatedPerson.User.phone": phone,
    "RelatedPerson.firstName": firstName,
    "RelatedPerson.middleName": middleName,
    "RelatedPerson.lastName": lastName,
    "RelatedPerson.photoUrl": photoUrl,
    "RelatedPerson.gender": gender,
    "RelatedPerson.dob": dob,
  } = data || {};

  return (
    <CoreGrid styleClasses={[CoreClasses.PADDING.P0]}>
      <CoreBox
        gridProps={{ gridSize: 2 }}
        styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_CENTER, CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}
      >
        <CoreAvatar
          styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_LARGE]}
          src={photoUrl || "photo.jpg"}
        />
      </CoreBox>

      <CoreBox gridProps={{ gridSize: 10 }}>
        <CoreTypographyBody1
          styleClasses={[CoreClasses.TEXT.TEXT_BOLD, CoreClasses.COLOR.TEXT_SECONDARY_DARK]}
        >
          {`${firstName || ""} ${middleName || ""} ${lastName || ""}`}
        </CoreTypographyBody1>

        <CoreStack
          direction="row"
          spacing={1}
          styleClasses={[CoreClasses.ALIGNMENT.JUSTIFY_CONTENT_FLEX_START]}
        >
          <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon="cake" text={getAge(dob)} />

          <CoreTypographyCaption styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY]}>{" | "}</CoreTypographyCaption>

          <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon={(gender)?.toLowerCase()} text={(gender)} />
        </CoreStack>

        <CoreTypographyCaption styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
          <CoreEmailLink email={email}/>
        </CoreTypographyCaption>

        <CoreTypographyCaption styleClasses={[CoreClasses.ALIGNMENT.ALIGN_ITEMS_CENTER]}>
          <CorePhoneLink icon="phone" phone={phone} />
        </CoreTypographyCaption>
      </CoreBox>

      {/* APPOINTED CLINICS  */}

      {/* <CoreTypographyBody1 styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY_DARK]}>Appointed Clinics</CoreTypographyBody1>
            <CoreStack direction="row" spacing={2}>
                <CoreAvatar styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_SMALL]} />

                <CoreTypographyCaption styleClasses={[CoreClasses.COLOR.TEXT_SECONDARY_DARK]}>Clinic Name</CoreTypographyCaption>

                <CoreDivider variant="vertical" />

                <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon="location_on" text={email} />

                <CoreDivider variant="vertical" />
                <CoreIconText type={__IconTypes.MATERIAL_OUTLINED_ICON} icon="phone" text={phone} />
            </CoreStack> */}
    </CoreGrid>
  );
};

export default AssistantCard;
