import {
  CoreAvatar,
  CoreClasses,
  CoreIcon,
  __IconTypes,
  CoreIconText,
  CoreTypographyCaption,
  CoreBox,
  CoreIconButton,
  CoreGrid
} from "@wrappid/core";
import moment from "moment";

export default function PastAppointmentRow(props) {
  const { row = {} } = props;
  const { Clinic = {} } = row;

  const date = moment(row?.date).format("llll");

  const formattedDate = date.substring(0, date.length - 8);

  return (
    <CoreGrid
      styleClasses={[CoreClasses.FLEX.DIRECTION_ROW, CoreClasses.ALIGNMENT.AIGN_ITEMS_CENTER]}>
      <CoreBox gridProps={{ gridSize: { sm: 3, xs: 3 } }}>
        <CoreIconText
          type={__IconTypes.MATERIAL_OUTLINED_ICON}
          icon={"event_available"}
          text={formattedDate}
        />
      </CoreBox>

      <CoreBox gridProps={{ gridSize: { sm: 3, xs: 3 } }}>
        <CoreIconText
          type={__IconTypes.MATERIAL_OUTLINED_ICON}
          icon={"schedule"}
          text={`${row["startTime"]?.substring(0, 5)} - ${row[
            "endTime"
          ]?.substring(0, 5)}`}
        />
      </CoreBox>

      <CoreBox
        gridProps={{ gridSize: { sm: 4, xs: 4 } }}
        styleClasses={[CoreClasses.FLEX?.DIRECTION_ROW, CoreClasses.ALIGNMENT.ALIGN_CONTENT_CENTER]}>
        <CoreAvatar
          component="span"
          styleClasses={[CoreClasses.DATA_DISPLAY.AVATAR_SMALL]}
          src={Clinic?.photoUrl || "photo.jpg"}
        />

        <CoreTypographyCaption
          styleClasses={[CoreClasses.MARGIN.ML1, CoreClasses.COLOR.TEXT_SECONDARY_DARK, CoreClasses.TEXT.TEXT_BOLD]} /* limitChars={12} hideSeeMore={true} */
        >
          {Clinic?.name}
        </CoreTypographyCaption>
      </CoreBox>

      <CoreIconButton
        styleClasses={[CoreClasses.COLOR.TEXT_PRIMARY]}
        gridProps={{ gridSize: { sm: 2, xs: 2 } }}
        onClick={() => {}}>
        <CoreIcon type={__IconTypes.FONTAWESOME_V5_SOLID_ICON}>
          fa-file-prescription
        </CoreIcon>
      </CoreIconButton>
    </CoreGrid>
  );
}
