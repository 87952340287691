import XLargeappBuilderStyles from "../../modules/app-builder/styles/XLargeModuleStyles";
import XLargeappointmentStyles from "../../modules/appointment/styles/XLargeModuleStyles";
import XLargeassistantStyles from "../../modules/assistant/styles/XLargeModuleStyles";
import XLargeauthStyles from "../../modules/auth/styles/XLargeModuleStyles";
import XLargeclinicStyles from "../../modules/clinic/styles/XLargeModuleStyles";
import XLargedashboardStyles from "../../modules/dashboard/styles/XLargeModuleStyles";
import XLargedataManagementStyles from "../../modules/data-management/styles/XLargeModuleStyles";
import XLargeguideStyles from "../../modules/guide/styles/XLargeModuleStyles";
import XLargepatientStyles from "../../modules/patient/styles/XLargeModuleStyles";
import XLargepaymentStyles from "../../modules/payment/styles/XLargeModuleStyles";
import XLargeprescriptionStyles from "../../modules/prescription/styles/XLargeModuleStyles";
import XLargeprofileStyles from "../../modules/profile/styles/XLargeModuleStyles";
import XLargesettingsStyles from "../../modules/settings/styles/XLargeModuleStyles";
import XLargeuserManagementStyles from "../../modules/user-management/styles/XLargeModuleStyles";

const XLargeModulesStyles = {
...new XLargeappBuilderStyles().style,
...new XLargeappointmentStyles().style,
...new XLargeassistantStyles().style,
...new XLargeauthStyles().style,
...new XLargeclinicStyles().style,
...new XLargedashboardStyles().style,
...new XLargedataManagementStyles().style,
...new XLargeguideStyles().style,
...new XLargepatientStyles().style,
...new XLargepaymentStyles().style,
...new XLargeprescriptionStyles().style,
...new XLargeprofileStyles().style,
...new XLargesettingsStyles().style,
...new XLargeuserManagementStyles().style,
};
 export default XLargeModulesStyles