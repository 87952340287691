import { LOGOUT_SUCCESS } from "@wrappid/core";

import {
  GET_ADRESS_TYPE_ERROR, GET_ADRESS_TYPE_LOADING, GET_ADRESS_TYPE_SUCCESS, GET_DEPT_ERROR, GET_DEPT_LOADING, GET_DEPT_SUCCESS 
} from "../types/commonTypes";

const initState = {
  departments          : null,
  getAddressTypeError  : false,
  getAddressTypeLoading: false,
  getAddressTypeMsg    : null,
  getAddressTypeSuccess: false,
  
  getDeptError  : false,
  getDeptLoading: false,
  getDeptMsg    : null,
  getDeptSuccess: false,
};

const commonReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_DEPT_LOADING:
      return {
        ...state,
        departments   : null,
        getDeptError  : false,
        getDeptLoading: true,
        getDeptMsg    : null,
        getDeptSuccess: false,
      };

    case GET_DEPT_SUCCESS:
      // eslint-disable-next-line no-console
      console.log("---GET DEPT", action.payload);
      return {
        ...state,
        departments   : action?.payload?.data,
        getDeptError  : false,
        getDeptLoading: false,
        getDeptMsg    : action?.payload?.message,
        getDeptSuccess: true,
      };

    case GET_DEPT_ERROR:
      return {
        ...state,
        departments   : null,
        getDeptError  : true,
        getDeptLoading: false,
        getDeptMsg    : action.message,
        getDeptSuccess: false,
      };

    case GET_ADRESS_TYPE_LOADING:
      return {
        ...state,
        addressTypes         : null,
        getAddressTypeError  : false,
        getAddressTypeLoading: true,
        getAddressTypeMsg    : null,
        getAddressTypeSuccess: false,
      };

    case GET_ADRESS_TYPE_SUCCESS:
      return {
        ...state,
        addressTypes         : action?.payload?.data,
        getAddressTypeError  : false,
        getAddressTypeLoading: false,
        getAddressTypeMsg    : action?.payload?.message,
        getAddressTypeSuccess: true,
      };

    case GET_ADRESS_TYPE_ERROR:
      return {
        ...state,
        addressTypes         : null,
        getAddressTypeError  : true,
        getAddressTypeLoading: false,
        getAddressTypeMsg    : action.message,
        getAddressTypeSuccess: false,
      };

    case LOGOUT_SUCCESS:
      return initState;

    default:
      return state;
  }
};

export default commonReducer;