/* eslint-disable no-unused-vars */
export const SanPatientCreate = (formData, apiMeta, state, others) => {
  return {
    endpoint : apiMeta.endpoint,
    reduxData: apiMeta.reduxData,
    values   : formData
  };
};

export const SanReadPatient = (data) => {
  return {
    addLine1      : data["RelatedPerson.PersonAddresses.addLine1"],
    addLine2      : data["RelatedPerson.PersonAddresses.addLine2"],
    bloodGroup    : data["RelatedPerson.bloodGroup"],
    city          : data["RelatedPerson.PersonAddresses.city"],
    country       : data["RelatedPerson.PersonAddresses.country"],
    district      : data["RelatedPerson.PersonAddresses.district"],
    dob           : data["RelatedPerson.dob"],
    email         : data["RelatedPerson.User.email"],
    firstName     : data["RelatedPerson.firstName"],
    gender        : data["RelatedPerson.gender"],
    landmark      : data["RelatedPerson.PersonAddresses.landmark"],
    lastName      : data["RelatedPerson.lastName"],
    marritalStatus: data["RelatedPerson.marritalStatus"],
    middleName    : data["RelatedPerson.middleName"],
    phone         : data["RelatedPerson.User.phone"],
    photo         : data["RelatedPerson.photoUrl"],
    pin           : data["RelatedPerson.PersonAddresses.pin"],
    state         : data["RelatedPerson.PersonAddresses.state"],
    ...data
  };
};

export const SanDeletePatient = (formData, apiMeta, state, others) => {
  return {
    endpoint: apiMeta.endpoint.replace(":id", formData["RelatedPerson.id"]),
    values  : formData,
  };
};

export const SanEditPatient = (formData, apiMeta, state, others) => {
  const endpoint = apiMeta.endpoint.replace(":id", formData["RelatedPerson.id"]);

  return {
    endpoint : endpoint,
    reduxData: apiMeta.reduxData,
    values   : formData
  };
};
  
export const SanPatientRelativeCreate = (formData, apiMeta, state, others) => {
  return {
    endpoint : apiMeta.endpoint,
    reduxData: apiMeta.reduxData,
    values   : {
      addressLine1  : formData["RelatedPerson.PersonAddresses.addLine1"],
      addressLine2  : formData["RelatedPerson.PersonAddresses.addLine2"],
      city          : formData["RelatedPerson.PersonAddresses.city"],
      country       : formData["RelatedPerson.PersonAddresses.country"],
      district      : formData["RelatedPerson.PersonAddresses.district"],
      landmark      : formData["RelatedPerson.PersonAddresses.landmark"],
      parentPersonId: formData["RelatedPerson.PersonAddresses.personId"],
      pincode       : formData["RelatedPerson.PersonAddresses.pin"],
      relative      : true,
      state         : formData["RelatedPerson.PersonAddresses.state"],
      ...formData,
      // eslint-disable-next-line etc/no-commented-out-code
      // personId    : state?.patient?.currentPerson,
    },
  };
};