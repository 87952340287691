import { setClinic } from "../actions/appointmentAction";

export const searchClinic = {
  getOptionLabel: (data) => {
    return data && data.fullName ? data.fullName : "";
  },
  getOptionValue: (data) => {
    return data;
  },
  isOptionsEqualToValue: (option, value) => {
    return option.id === value.id;
  },
  onChangeDispatch: setClinic,
};
