import { MenusRegistry as AppBuilderMenusRegistry } from "./app-builder/menus.registry";
import { MenusRegistry as AppointmentMenusRegistry } from "./appointment/menus.registry";
import { MenusRegistry as AssistantMenusRegistry } from "./assistant/menus.registry";
import { MenusRegistry as AuthMenusRegistry } from "./auth/menus.registry";
import { MenusRegistry as ClinicMenusRegistry } from "./clinic/menus.registry";
import { MenusRegistry as DashboardMenusRegistry } from "./dashboard/menus.registry";
import { MenusRegistry as DataManagementMenusRegistry } from "./data-management/menus.registry";
import { MenusRegistry as GuideMenusRegistry } from "./guide/menus.registry";
import { MenusRegistry as PatientMenusRegistry } from "./patient/menus.registry";
import { MenusRegistry as PaymentMenusRegistry } from "./payment/menus.registry";
import { MenusRegistry as PrescriptionMenusRegistry } from "./prescription/menus.registry";
import { MenusRegistry as SettingsMenusRegistry } from "./settings/menus.registry";
import { MenusRegistry as UserManagementMenusRegistry } from "./user-management/menus.registry";

export default {
...AppBuilderMenusRegistry,
...AppointmentMenusRegistry,
...AssistantMenusRegistry,
...AuthMenusRegistry,
...ClinicMenusRegistry,
...DashboardMenusRegistry,
...DataManagementMenusRegistry,
...GuideMenusRegistry,
...PatientMenusRegistry,
...PaymentMenusRegistry,
...PrescriptionMenusRegistry,
...SettingsMenusRegistry,
...UserManagementMenusRegistry,
}